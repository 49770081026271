import { cn } from "../../helpers";

interface Props {
    className?: string;
}

const BfIcon = (props: Props) => {
    return (
        <svg
            role="img"
            className={cn("h-6 w-6", props.className)}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width="256"
            height="256"
            viewBox="0 0 256 256"
        >
            <g fill="none" strokeMiterlimit="10" strokeWidth="0">
                <path
                    fill="#35A100"
                    d="M127.857 254.307c-69.837 0-126.45-56.613-126.45-126.45h252.9c0 69.837-56.613 126.45-126.45 126.45"
                ></path>
                <path
                    fill="#DE0000"
                    d="M127.857 1.407c-69.837 0-126.45 56.613-126.45 126.45h252.9c0-69.837-56.613-126.45-126.45-126.45"
                ></path>
                <path
                    fill="#FFF300"
                    d="m103.75 141.201-39.118-26.714 48.329.045 14.896-43.269 14.895 43.269 48.33-.031-39.127 26.7 14.969 43.252-39.076-26.765-39.076 26.76"
                ></path>
            </g>
        </svg>
    );
};

export default BfIcon;
