import Button from "@ui/Button.tsx";
import { Dispatch, FormEvent, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { STORAGE } from "../../constants";
import PAGES from "../../constants/pages.ts";
import { storage } from "../../helpers";
import InputField from "../InputField.tsx";
import PhoneInput from "../PhoneInput.tsx";

interface Props {
    loading: boolean;
    hiddeForgotLink?: boolean;
    showPhoneInput?: boolean;
    invalidCredential?: boolean;
    showTitle?: boolean;
    errors: { [key: string]: string };
    onSubmit: (e: FormEvent) => void;
    indicative: {
        value: string;
        setValue: (value: string) => void;
    };
    otherIndicativeState?: {
        value: string;
        setValue: (value: string) => void;
    };
    pin: string;
    setPin: Dispatch<SetStateAction<string>>;
    phoneWithoutIndicative: string;
    setPhoneWithoutIndicative: Dispatch<SetStateAction<string>>;
}

const LoginForm = (props: Props) => {
    const {
        showTitle = true,
        showPhoneInput = true,
        invalidCredential,
        errors,
        pin,
        setPin,
        phoneWithoutIndicative,
        setPhoneWithoutIndicative,
        onSubmit,
        otherIndicativeState
    } = props;

    const { t } = useTranslation();

    useEffect(() => {
        const confirmTel = storage().getItem(STORAGE.CONFIRM_ACCOUNT_TEL);

        if (confirmTel?.length) {
            storage().removeItem(STORAGE.CONFIRM_ACCOUNT_TEL);
        }
    }, []);

    return (
        <form id="login-form" className="space-y-5" onSubmit={onSubmit}>
            <div>
                {showTitle && (
                    <h4 className="mt-12 text-center text-lg font-bold text-gray-700">
                        {t("Heureux de vous revoir 😊")}
                    </h4>
                )}

                <p className="mt-2 text-center text-xs">
                    {t("Connectez-vous pour continuer.")}
                </p>
            </div>

            <p className="text-center md:text-left">
                {t(
                    "Pour les comptes du Bénin, ajoutez 01 pour vous connecter."
                )}
            </p>

            {invalidCredential && (
                <p className="mb-5 rounded-md bg-red-100 px-4 py-3 text-center text-xs text-red-700">
                    {t("Les identifants ne sont pas correcte.")}
                </p>
            )}

            {showPhoneInput && (
                <PhoneInput
                    id="tel"
                    name="phone_with_indicative"
                    placeholder={t("Numéro")}
                    indicative={props.indicative.value}
                    onIndicativeChange={value =>
                        props.indicative.setValue(value)
                    }
                    value={phoneWithoutIndicative}
                    onChange={e => setPhoneWithoutIndicative(e.target.value)}
                    error={errors.phone_with_indicative}
                    otherCountries
                    otherIndicative={otherIndicativeState?.value}
                    onOtherIndicativeChange={value =>
                        otherIndicativeState?.setValue(value)
                    }
                />
            )}

            <div>
                <InputField
                    isPin
                    id="pin"
                    type="password"
                    inputMode="numeric"
                    value={pin}
                    onChange={e => setPin(e.target.value)}
                    placeholder={t("Code secret")}
                    error={errors.pin}
                />

                <div className="text-end">
                    <NavLink
                        to={PAGES.FORGOT_PIN}
                        className="mt-2 text-sm text-primary hover:underline"
                    >
                        {t("Code secret oublié?")}
                    </NavLink>
                </div>
            </div>

            <Button type="submit" data-cy="submit" loading={props.loading}>
                {t("Connexion")}
            </Button>

            {showPhoneInput && (
                <>
                    <p className="text-center text-sm">
                        {t("Vous n'avez pas de compte ?")}{" "}
                        <NavLink
                            to={PAGES.REGISTER}
                            className="font-bold text-primary underline hover:underline"
                        >
                            {t("Inscrivez-vous")}
                        </NavLink>
                    </p>
                </>
            )}
        </form>
    );
};

export default LoginForm;
