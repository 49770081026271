import { cn } from "../../helpers";

interface Props {
    className?: string;
}

const BjIcon = (props: Props) => {
    return (
        <svg
            className={cn("h-6 w-6", props.className)}
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="256"
            height="256"
            viewBox="0 0 256 256"
            xmlSpace="preserve"
        >
            <defs></defs>
            <g
                style={{
                    stroke: "none",
                    strokeWidth: "0",
                    strokeDasharray: "none",
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeMiterlimit: 10,
                    fill: "none",
                    fillRule: "nonzero",
                    opacity: 1
                }}
                transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
            >
                <path
                    d="M 30.827 2.291 C 12.921 8.231 0 25.101 0 45 c 0 19.899 12.921 36.769 30.827 42.709 V 2.291 z"
                    style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(49,148,0)",
                        fillRule: "nonzero",
                        opacity: 1
                    }}
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                />
                <path
                    d="M 90 45 H 30.827 v 42.709 C 35.285 89.188 40.046 90 45 90 C 69.853 90 90 69.853 90 45 z"
                    style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(222,33,16)",
                        fillRule: "nonzero",
                        opacity: 1
                    }}
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                />
                <path
                    d="M 45 0 c -4.954 0 -9.715 0.812 -14.173 2.291 V 45 H 90 C 90 20.147 69.853 0 45 0 z"
                    style={{
                        stroke: "none",
                        strokeWidth: 1,
                        strokeDasharray: "none",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeMiterlimit: 10,
                        fill: "rgb(255,214,0)",
                        fillRule: "nonzero",
                        opacity: 1
                    }}
                    transform=" matrix(1 0 0 1 0 0) "
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
    /*return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 480"
            className={props.className}
            role="img"
        >
            <defs>
                <clipPath id="a">
                    <path fill="gray" d="M67.6-154h666v666h-666z" />
                </clipPath>
            </defs>
            <g clipPath="url(#a)" transform="matrix(.961 0 0 .7207 -65 111)">
                <g fillRule="evenodd" strokeWidth="1pt">
                    <path fill="#319400" d="M0-154h333v666H0z" />
                    <path fill="#ffd600" d="M333-154h666v333H333z" />
                    <path fill="#de2110" d="M333 179h666v333H333z" />
                </g>
            </g>
        </svg>
    );*/
};

export default BjIcon;
