import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useTranslation } from "react-i18next";

import { ModalPropsType } from "../../types";

const AlertBjNumberChange = (props: ModalPropsType) => {
    const { t } = useTranslation();
    const { isOpen, closeModal } = props;

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            hiddeCloseIcon
            className="pt-10 text-center"
        >
            <h3 className="inline-block rounded-xl bg-primary/10 px-6 py-3 text-2xl font-extrabold text-primary">
                +229 <span className="text-red-500">01</span> XXXXXXXX
            </h3>

            <p className="my-8 text-sm text-[#212B36]">
                {t(
                    "Les numéros au Bénin passent de 8 à 10 chiffres. Pour tous vos transferts vers le Bénin"
                )}
                ,{" "}
                <span className="font-medium text-secondary">
                    {t("ajoutez 01 au début du numéro de votre destinataire")}
                </span>
            </p>

            <Button withAuto className="mx-auto px-24" onClick={closeModal}>
                {t("Compris")}
            </Button>
        </Modal>
    );
};

export default AlertBjNumberChange;
