import { cn } from "../../helpers";

interface Props {
    className?: string;
}

const TgIcon = (props: Props) => {
    return (
        <svg
            className={cn("h-6 w-6", props.className)}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width="256"
            height="256"
            viewBox="0 0 256 256"
            role="img"
        >
            <g
                fill="none"
                strokeMiterlimit="10"
                strokeWidth="0"
                transform="translate(1.407 1.407)scale(2.81)"
            >
                <circle cx="45" cy="45" r="45" fill="#FFE300"></circle>
                <path
                    fill="#118600"
                    d="M81.057 71.928H8.943C17.149 82.899 30.245 90 45 90s27.851-7.101 36.057-18.072M0 45c0 3.317.369 6.546 1.05 9.659h87.9A45 45 0 0 0 90 45c0-2.877-.281-5.686-.797-8.413H.797A45 45 0 0 0 0 45M8.911 18.116h72.177C73.205 7.551 60.79.574 46.724.044h-3.448C29.21.574 16.795 7.551 8.911 18.116"
                ></path>
                <path
                    fill="#D80000"
                    d="M53.878.88a45 45 0 0 0-7.154-.837h-3.448C19.223.951 0 20.725 0 45c0 3.318.37 6.547 1.051 9.661h52.828V.88z"
                ></path>
                <path
                    fill="#FFF"
                    d="M23.628 22.576c0-.15 3.311-9.331 3.311-9.331l3.01 9.181s10.084.301 10.084.15-7.977 6.02-7.977 6.02 3.762 10.535 3.612 10.234-8.729-6.321-8.729-6.321-8.729 6.02-8.578 6.02 3.311-9.933 3.311-9.933l-7.826-5.87z"
                ></path>
            </g>
        </svg>
    );
};

export default TgIcon;
