import { useMemo } from "react";

import { WALLET_ENABLE_DISABLE } from "../../constants";
import { cn, getWalletFilePath } from "../../helpers";
import { fromNow } from "../../libs/date.ts";
import DynamicFromNow from "../DynamicFromNow.tsx";
import Loading from "../Loading.tsx";

import { NotificationType } from "./types.ts";
import useNotificationAction from "./useNotificationAction.ts";
import useNotificationMessage from "./useNotificationMessage.ts";

interface Props {
    value: NotificationType;
}

const NotificationItem = (props: Props) => {
    const { value } = props;

    const message = useNotificationMessage(value.data);
    const { action, loading } = useNotificationAction(value);
    const walletPath = useMemo(() => {
        if (value.data.text === WALLET_ENABLE_DISABLE) {
            return getWalletFilePath(value.data.pay_channel?.slug || "");
        }
        return "";
    }, [value.data.pay_channel?.slug, value.data.text]);

    return (
        <>
            <li
                className={cn({
                    "cursor-pointer list-none text-gray-600": true,
                    "relative px-4 py-2 lg:px-8": true,
                    "flex items-center space-x-2": true,
                    "bg-gray-100": value.read_at === null
                })}
                onClick={action}
            >
                {loading && (
                    <div className="absolute right-0 top-0 flex h-full w-full items-center justify-center bg-black/20">
                        <Loading className="text-primary" />
                    </div>
                )}

                {value.data.text === WALLET_ENABLE_DISABLE && (
                    <div
                        className={cn(
                            "h-[30px] w-[30px] border p-0.5 lg:h-[45px] lg:w-[45px]",
                            "rounded-full border-opacity-20 bg-white shadow-sm",
                            "flex items-center justify-center"
                        )}
                    >
                        <img
                            className="rounded-full object-cover object-center"
                            width="35"
                            src={walletPath}
                            alt=""
                        />
                    </div>
                )}

                <div className="flex-1">
                    <p>{message}</p>
                    <p className="text-[10px]">
                        {value.created_at !== null && (
                            <DynamicFromNow
                                date={value.created_at}
                                formatFunc={fromNow}
                            />
                        )}
                    </p>
                </div>
            </li>
        </>
    );
};

export default NotificationItem;
