import Button from "@ui/Button.tsx";
import PinInput from "@ui/PinInput";
import { Dispatch, FormEvent, SetStateAction } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { hiddeNumber } from "../../helpers";
import InputField from "../InputField.tsx";
import PhoneInput from "../PhoneInput.tsx";

interface StringStateType {
    value: string;
    setValue: (value: string) => void;
}

interface Props {
    showForgotPinForm?: boolean;
    showVerifyPinForm?: boolean;
    showResetPinForm?: boolean;
    phone: string;
    indicative: StringStateType;
    code: StringStateType;
    handleSubmit: (e: FormEvent) => void;
    resendCode: () => void;
    register: UseFormRegister<FieldValues>;
    errors: { [key: string]: string | string[] };
    loading?: boolean;
    pin: string;
    setPin: Dispatch<SetStateAction<string>>;
    confirmPin: string;
    setConfirmPin: Dispatch<SetStateAction<string>>;
}

const ForgotFlowForm = (props: Props) => {
    const { t } = useTranslation();
    const { pin, setPin, confirmPin, setConfirmPin, resendCode } = props;

    return (
        <form className="w-full space-y-5" onSubmit={props.handleSubmit}>
            <div>
                <h4 className="mt-12 text-center text-lg font-bold text-gray-700">
                    {props.showForgotPinForm && t("Code secret oublié ? 🥲")}
                    {props.showVerifyPinForm && t("Vérification du numéro")}
                    {props.showResetPinForm &&
                        t("Créer un nouveau mot de passe")}
                </h4>

                <p className="mt-2 text-center text-xs">
                    {props.showForgotPinForm &&
                        t(
                            "Nous sommes là pour vous aider ! Veuillez entrer le numéro de téléphone associé à votre compte."
                        )}
                    {(props.showVerifyPinForm || props.showResetPinForm) && (
                        <>
                            {t(
                                "Veuillez saisir le code OTP que nous vous avons envoyé au"
                            )}{" "}
                            <span className="font-semibold text-primary">
                                {hiddeNumber(props.phone)}
                            </span>
                            .
                        </>
                    )}
                </p>
            </div>

            {/*// step 1*/}
            {props.showForgotPinForm && (
                <PhoneInput
                    name="phone_with_indicative"
                    placeholder={t("N° de téléphone")}
                    register={props.register("phone_with_indicative")}
                    indicative={props.indicative.value}
                    onIndicativeChange={value =>
                        props.indicative.setValue(value)
                    }
                    error={props.errors?.phone_with_indicative}
                />
            )}

            {/*// step 2*/}
            {props.showVerifyPinForm && (
                <div>
                    <PinInput
                        type="number"
                        length={4}
                        size="xl"
                        onChange={value => props.code.setValue(value)}
                        pinItemClassName="w-1/4 mb-2"
                        errorMessage={props.errors?.code}
                    />

                    <div className="text-right text-xs">
                        <p>
                            {t("Vous n’avez pas reçu le code ?")}{" "}
                            <button
                                type="button"
                                onClick={resendCode}
                                className="font-bold text-gray-700 underline hover:underline focus:outline-none"
                            >
                                {t("Renvoyer")}
                            </button>
                        </p>
                    </div>
                </div>
            )}

            {/*// step 3*/}
            {props.showResetPinForm && (
                <>
                    <InputField
                        isPin
                        id="pin"
                        name="pin"
                        type="password"
                        inputMode="numeric"
                        placeholder={t("Nouveau code secret")}
                        value={pin}
                        onChange={e => setPin(e.target.value)}
                        error={props.errors?.pin}
                    />

                    <InputField
                        isPin
                        id="confirm_pin"
                        name="confirm_pin"
                        type="password"
                        inputMode="numeric"
                        placeholder={t("Confirmer code secret")}
                        value={confirmPin}
                        onChange={e => setConfirmPin(e.target.value)}
                        error={props.errors?.confirm_pin}
                    />
                </>
            )}

            <Button type="submit" loading={props.loading}>
                {props.showForgotPinForm ? t("Suivant") : t("Vérifier")}
            </Button>

            <p className="text-center text-sm">
                {t("Vous vous en souvenez ?")}{" "}
                <NavLink
                    to={PAGES.LOGIN}
                    className="font-bold text-primary underline hover:underline"
                >
                    {t("Connectez-vous")}
                </NavLink>
            </p>
        </form>
    );
};

export default ForgotFlowForm;
