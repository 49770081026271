import React from "react";

interface Props {
    children: React.ReactNode;
}

const AppLayoutTitle = (props: Props) => {
    return <h3 className="mb-8 text-2xl font-semibold">{props.children}</h3>;
};

export default AppLayoutTitle;
