import Button from "@ui/Button.tsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CGUDescription from "../components/CGUDescription.tsx";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="flex min-h-screen flex-col items-center justify-between px-8 py-5">
            <div>
                <CGUDescription paragraphClassName="h-[calc(100vh-140px)]" />
            </div>

            <Button onClick={() => navigate(-1)} className="w-full md:w-2/12">
                {t("Retour")}
            </Button>
        </div>
    );
};

export default PrivacyPolicy;
