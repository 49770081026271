import { useMemo } from "react";

import { cn } from "../../helpers";
import { InputProps } from "../../types";

const Input = (props: InputProps) => {
    const { type, className, value, error, register, ...rest } = props;
    const fileTestId = useMemo(() => {
        return type === "file" ? { "data-testid": "file-input" } : {};
    }, [type]);

    return (
        <input
            type={type}
            value={value}
            autoComplete="on"
            className={cn({
                "w-full rounded-md border border-gray-300 placeholder-gray-400":
                    true,
                "px-4 py-4 text-sm text-gray-700": true,
                "focus:border-primary focus:outline-none focus:ring focus:ring-primary/20":
                    true,
                "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400":
                    true,
                "py-3.5": type === "file",
                "border-red-500": !!error,
                [className || ""]: true
            })}
            {...register}
            {...rest}
            {...fileTestId}
        />
    );
};

export default Input;
