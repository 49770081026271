import React from "react";

import { cn } from "../../helpers";

interface Props {
    children: React.ReactNode;
    className?: string;
}

const AppLayoutSection = (props: Props) => {
    return (
        <section
            className={cn(
                "col-span-3 rounded-lg bg-white pb-4 pt-6 lg:col-span-2",
                props.className
            )}
        >
            {props.children}
        </section>
    );
};

export default AppLayoutSection;
