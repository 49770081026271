import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import PiTrashIcon from "../../../components/icons/PiTrashIcon.tsx";
import XMarkIcon from "../../../components/icons/XMarkIcon.tsx";
import ENDPOINTS from "../../../constants/endpoint.ts";
import { cn, loadAuthServiceHost } from "../../../helpers";
import useAuth from "../../../hooks/useAuth.ts";
import useManageCard from "../../../hooks/useManageCard.ts";
import useModal from "../../../hooks/useModal.ts";
import useToast from "../../../hooks/useToast.ts";
import { updateAuthUser } from "../../../store/authSlice.ts";
import { resetSenderData } from "../../../store/transferSlice.ts";
import { ModalPropsType, SecondaryPhoneType } from "../../../types";

import CardAddModal from "./CardAddModal.tsx";
import NumberAddModal from "./NumberAddModal.tsx";

interface Props {
    accountType: "cards" | "numbers";
}

interface ConfirmRemoveProps extends ModalPropsType {
    value: string;
    onConfirm: () => void;
    loading: boolean;
    removeType: "cards" | "numbers";
}

interface ItemProps {
    accountType: "cards" | "numbers";
    value: string;
    validated: boolean;
    status: string;
    onClick?: () => void;
}

const ConfirmRemove = (props: ConfirmRemoveProps) => {
    const { t } = useTranslation();
    const { isOpen, closeModal, value, loading, onConfirm, removeType } = props;

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            className="relative rounded-md px-2 py-5"
        >
            <h5 className="mb-3 text-center font-semibold">
                {removeType === "cards" && t("Supprimer la carte")}

                {removeType === "numbers" && t("Supprimer le numéro d'envoi")}
            </h5>

            <p className="text-center text-sm">
                {removeType === "cards" &&
                    t("Etes-vous sûr de vouloir supprimer cette carte?")}

                {removeType === "numbers" &&
                    t("Vous être sur le point de supprimer ce numéro.")}
            </p>

            {removeType === "numbers" && (
                <p className="mb-3 text-center text-sm">
                    {t("Etes-vous sûr?")}
                </p>
            )}

            <p className="my-5 text-center text-sm font-medium">{value}</p>

            <div className="mt-5 flex items-center justify-center space-x-5">
                <Button withAuto loading={loading} onClick={onConfirm}>
                    {t("Confirmer")}
                </Button>

                <Button withAuto variant="outline" onClick={closeModal}>
                    {t("Annuler")}
                </Button>
            </div>
        </Modal>
    );
};

const AccountItem = (props: ItemProps) => {
    const { t } = useTranslation();
    const { value, validated, status, onClick, accountType } = props;

    return (
        <div className="mt-5 flex items-center justify-between border-b pb-4">
            <div className="text-xs">
                <p className="mb-1">{value}</p>

                <p
                    className={cn({
                        "text-[10px] font-bold": true,
                        "text-green-600": validated,
                        "text-red-600": !validated,
                        "text-[#374151]":
                            accountType === "numbers" && !validated
                    })}
                >
                    {status}
                </p>
            </div>

            {onClick && (
                <button
                    className={cn({
                        "text-red-600": validated || accountType === "cards",
                        "text-xs font-bold text-primary underline":
                            !validated && accountType === "numbers"
                    })}
                    onClick={onClick}
                >
                    {validated || accountType === "cards" ? (
                        <PiTrashIcon />
                    ) : (
                        t("Finaliser")
                    )}
                </button>
            )}
        </div>
    );
};

const OtherAccount = (props: Props) => {
    const [selectNumber, setSelectNumber] = useState<SecondaryPhoneType | null>(
        null
    );
    const [numberRemoving, setNumberRemoving] = useState(false);
    const [confirmPhoneData, setConfirmPhoneData] = useState<
        { phone: string; indicative: string } | undefined
    >(undefined);

    const { accountType } = props;
    const { t } = useTranslation();
    const { user } = useAuth();
    const { selectCard, setSelectCard, removing, handleRemove } =
        useManageCard();
    const dispatch = useDispatch();
    const { successToast } = useToast();
    const {
        isOpen: isNumberFormOpen,
        closeModal: closeNumberForm,
        openModal: openNumberForm
    } = useModal();

    const {
        isOpen: isCardFormOpen,
        closeModal: closeCardForm,
        openModal: openCardForm
    } = useModal();

    const refreshUserData = useCallback(() => {
        loadAuthServiceHost();
        window.axios
            .get(ENDPOINTS.ME)
            .then(response => {
                dispatch(updateAuthUser({ user: response.data.data }));
                setNumberRemoving(false);
                dispatch(resetSenderData());
                setSelectNumber(null);
                successToast(t("Number delete successfully"));
            })
            .finally(() => setNumberRemoving(false));
    }, [dispatch, successToast, t]);

    const handleClickConfirmRemoveNumber = useCallback(() => {
        setNumberRemoving(true);
        loadAuthServiceHost();
        window.axios
            .delete(
                ENDPOINTS.REMOVE_SECONDARY_NUMBER.replace(
                    ":id",
                    selectNumber?.id.toString() || ""
                )
            )
            .then(() => {
                setSelectNumber(null);
                refreshUserData();
            })
            .finally(() => setNumberRemoving(false));
    }, [refreshUserData, selectNumber?.id]);

    const canAddNumbers = useMemo(() => {
        return (
            !!user?.account?.transfer_phone_numbers &&
            user?.account?.transfer_phone_numbers?.length < 2
        );
    }, [user?.account?.transfer_phone_numbers]);

    const canAddCards = useMemo(() => {
        return (
            user?.account?.transfer_cards &&
            user?.account?.transfer_cards?.length < 3
        );
    }, [user?.account?.transfer_cards]);

    const value = useMemo(() => {
        if (accountType === "cards") return selectCard?.card_number || "";

        if (accountType === "numbers")
            return selectNumber?.phone_with_indicative || "";

        return "";
    }, [
        accountType,
        selectCard?.card_number,
        selectNumber?.phone_with_indicative
    ]);

    const loading = useMemo(() => {
        if (accountType === "numbers") return numberRemoving;

        if (accountType === "cards") return removing;

        return false;
    }, [accountType, numberRemoving, removing]);

    const isOpen = useMemo(() => {
        if (accountType === "cards") return !!selectCard;

        if (accountType === "numbers") return !!selectNumber;

        return false;
    }, [accountType, selectCard, selectNumber]);

    return (
        <>
            <h6 className="mb-4 font-bold text-[#374151]">
                {accountType === "numbers" && t("Gérer mes numéros")}
                {accountType === "cards" && t("Gérer mes cartes bancaires")}
            </h6>

            <p className="text-sm text-[#374151]">
                {accountType === "numbers" &&
                    t(
                        "Ajouter et gérer les numéros d'envoi. 3 numéros maximum autorisés."
                    )}

                {accountType === "cards" &&
                    t(
                        "Ajouter et gérer les cartes d'envoi. 3 cartes maximum autorisés."
                    )}
            </p>

            {accountType === "numbers" && (
                <>
                    <AccountItem
                        accountType="numbers"
                        value={user.phone_with_indicative || "..."}
                        status={t("Validé")}
                        validated={true}
                    />

                    {user?.account?.transfer_phone_numbers?.map(
                        (item, index) => (
                            <AccountItem
                                key={index}
                                accountType={"numbers"}
                                value={item.phone_with_indicative}
                                validated={!!item.validated_at}
                                status={
                                    item.validated_at
                                        ? t("Validé")
                                        : t("En attente")
                                }
                                onClick={() => {
                                    if (item.validated_at) {
                                        setSelectNumber(item);
                                    } else {
                                        const [indicative, phone] =
                                            item.phone_with_indicative.split(
                                                " "
                                            );

                                        setConfirmPhoneData({
                                            phone,
                                            indicative
                                        });
                                        openNumberForm();
                                    }
                                }}
                            />
                        )
                    )}
                </>
            )}

            {accountType === "cards" && (
                <>
                    {(user?.account?.transfer_cards || []).map(
                        (item, index) => (
                            <AccountItem
                                key={index}
                                accountType={"cards"}
                                value={item.card_number}
                                status={
                                    !item.activated_at
                                        ? t("Bloquée")
                                        : t("Activée")
                                }
                                validated={!!item.activated_at}
                                onClick={() => setSelectCard(item)}
                            />
                        )
                    )}
                </>
            )}

            {((canAddNumbers &&
                user?.account.transfer_kyc_status === "validated" &&
                accountType === "numbers") ||
                (canAddCards && accountType === "cards")) && (
                <button
                    className="mt-4 flex items-center space-x-3 text-sm font-semibold text-primary"
                    onClick={
                        accountType === "numbers"
                            ? openNumberForm
                            : openCardForm
                    }
                >
                    <XMarkIcon className="rotate-45" />
                    <span>
                        {accountType === "numbers" &&
                            t("Ajouter un autre numéro")}
                        {accountType === "cards" &&
                            t("Ajouter une carte bancaire")}
                    </span>
                </button>
            )}

            <ConfirmRemove
                removeType={accountType}
                loading={loading}
                isOpen={isOpen}
                closeModal={() => {
                    if (accountType === "cards") {
                        !removing && setSelectCard(null);
                    }

                    if (accountType === "numbers") {
                        !numberRemoving && setSelectNumber(null);
                    }
                }}
                value={value}
                onConfirm={() => {
                    if (accountType === "cards") {
                        handleRemove();
                    }

                    if (accountType === "numbers") {
                        handleClickConfirmRemoveNumber();
                    }
                }}
            />

            <NumberAddModal
                isOpen={isNumberFormOpen}
                closeModal={() => {
                    setConfirmPhoneData(undefined);
                    closeNumberForm();
                }}
                phoneData={confirmPhoneData}
            />

            <CardAddModal isOpen={isCardFormOpen} closeModal={closeCardForm} />
        </>
    );
};

export default OtherAccount;
