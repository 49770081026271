import Button from "@ui/Button.tsx";
import PinInput from "@ui/PinInput";
import { FormEvent, MouseEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import HaveAccount from "../../../components/HaveAccount.tsx";
import { RESPONSE_STATUS } from "../../../constants";
import ENDPOINTS from "../../../constants/endpoint.ts";
import { hiddeNumber, loadAuthServiceHost } from "../../../helpers";
import useRedirectConfirmAccount from "../../../hooks/useRedirectConfirmAccount.ts";
import useToast from "../../../hooks/useToast.ts";
import rules from "../../../schemas";

interface Props {
    phone?: string;
    email?: string;
    onSuccess: () => void;
}

const CodeInputSchema = yup.object().shape({
    code: rules.pin
});

const ConfirmOtp = (props: Props) => {
    const { phone, email, onSuccess } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { successToast } = useToast();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [code, setCode] = useState("");
    const { removePhoneNumber } = useRedirectConfirmAccount();

    const resendCode = useCallback(
        (e: MouseEvent<HTMLAnchorElement>) => {
            e.stopPropagation();
            loadAuthServiceHost();
            window.axios
                .post(ENDPOINTS.RESEND_CODE, {
                    phone_with_indicative: phone
                })
                .then(() => {
                    removePhoneNumber();
                    successToast(
                        t("Code envoyer avec succès"),
                        t("Le code de confirmartion vous à été envoyer par sms")
                    );
                });
        },
        [phone, removePhoneNumber, successToast, t]
    );

    const onSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            CodeInputSchema.validate({ code }, { abortEarly: false })
                .then(() => {
                    setLoading(true);
                    loadAuthServiceHost();
                    window.axios
                        .post(ENDPOINTS.VALIDATE_ACCOUNT, {
                            phone_with_indicative: phone || "",
                            email: email || "",
                            code: code
                        })
                        .then(() => {
                            successToast(
                                t("Succès de la confirmation de compte"),
                                t("Vous pouvez desormain vous conncter")
                            );

                            onSuccess();
                        })
                        .catch(errors => {
                            if (
                                errors.response.status ===
                                RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                            ) {
                                setErrors(errors.response.data);
                            }
                        })
                        .finally(() => setLoading(false));
                })
                .catch((validationErrors: yup.ValidationError) => {
                    const validationErrorsMap: { [key: string]: string } = {};
                    validationErrors.inner.forEach(error => {
                        if (error.path) {
                            validationErrorsMap[error.path] = error.message;
                        }
                    });
                    setErrors(validationErrorsMap);
                });
        },
        [code, email, onSuccess, phone, successToast, t]
    );

    const phoneWithoutIndicative = useMemo(() => {
        const [, value] = (phone || "").split(" ");

        return value || "";
    }, [phone]);

    return (
        <form className="mt-16 space-y-5" onSubmit={onSubmit}>
            <h4 className="mt-12 text-center text-xl font-bold text-gray-700">
                {email && t("Confirmez votre identité")}

                {phone && !email && t("Vérifions votre numéro")}
            </h4>

            <p className="text-center text-xs">
                {phone && !email && (
                    <>
                        {t(
                            "Veuillez saisir le code OTP que nous vous avons envoyé au"
                        )}{" "}
                        <span className="font-medium text-primary">
                            {hiddeNumber(phoneWithoutIndicative)}
                        </span>
                        .
                    </>
                )}

                {email && (
                    <>
                        {t(
                            "Veuillez entrer le code OTP qui a été envoyé à votre email pour valider votre compte."
                        )}
                    </>
                )}
            </p>

            <div className="space-y-5 text-right">
                <PinInput
                    pinItemClassName="w-1/4"
                    type="number"
                    length={4}
                    onChange={value => setCode(value)}
                    errorMessage={errors.code}
                />

                <p className="text-center text-xs text-[#6B7280]">
                    {t("Vous avez déjà un compte ?")}{" "}
                    <a
                        href="#resend"
                        className="font-bold text-[#374151] underline"
                        onClick={resendCode}
                    >
                        {t("Renvoyer")}
                    </a>
                </p>
            </div>

            <Button type="submit" loading={loading}>
                {t("Vérifier")}
            </Button>

            <HaveAccount />
        </form>
    );
};

export default ConfirmOtp;
