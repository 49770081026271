import { useCallback, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import ForgotFlowForm from "../components/forms/ForgotFlowForm.tsx";
import AuthLayout from "../components/layouts/AuthLayout.tsx";
import config from "../config";
import { DEFAULT_COUNTRY_INDICATIVE, RESPONSE_STATUS } from "../constants";
import ENDPOINTS from "../constants/endpoint.ts";
import { loadAuthServiceHost, phoneIsValid } from "../helpers";
import useInitPhoneIndicative from "../hooks/useInitPhoneIndicative.ts";
import usePageTitle from "../hooks/usePageTitle.ts";
import useShowInvalidAccountMessage from "../hooks/useShowInvalidAccountMessage.tsx";
import useToast from "../hooks/useToast.ts";
import rules from "../schemas";

const ForgotPinSchema = yup.object().shape({
    phone_with_indicative: rules.phone
});

const TOAST_DURATION = 10000;

const ResetPinSchema = yup.object().shape({
    pin: rules.pin,
    confirm_pin: rules.confirmPin("pin")
});

const TEMPORARY_CODE_INCORRECT = "temporary_code_not_correct";

const ForgotPin = () => {
    const { t } = useTranslation();
    usePageTitle(t("Pin oublié"));

    const [loading, setLoading] = useState(false);
    const [indicative, setIndicative] = useState(DEFAULT_COUNTRY_INDICATIVE);
    const [code, setCode] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string | string[] }>(
        {}
    );
    const [phone, setPhone] = useState("");
    const [pin, setPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");

    const [showForgotPinForm, setShowForgotPinForm] = useState(true);
    const [showVerifyPinForm, setShowVerifyPinForm] = useState(false);
    const [showResetPinForm, setShowResetPinForm] = useState(false);

    const { successToast } = useToast();
    useInitPhoneIndicative(setIndicative);
    const navigate = useNavigate();
    const printErrorMessage = useShowInvalidAccountMessage();
    const { errorToast } = useToast();

    const getSubmitEndpoint = useCallback(() => {
        if (showForgotPinForm) return ENDPOINTS.FORGOT_PIN;
        if (showVerifyPinForm) return ENDPOINTS.VERIFY_OTP;
        if (showResetPinForm) return ENDPOINTS.RESET_PIN;
        return;
    }, [showForgotPinForm, showResetPinForm, showVerifyPinForm]);

    const title = useMemo(() => {
        if (showForgotPinForm) {
            return t("Saisir le numéro");
        }

        if (showResetPinForm) {
            return t("Nouveau code secret");
        }

        return "";
    }, [showForgotPinForm, showResetPinForm, t]);

    const { register, handleSubmit, reset } = useForm();

    const handleResendCode = useCallback(() => {
        setLoading(true);
        loadAuthServiceHost();
        window.axios
            .post(ENDPOINTS.FORGOT_PIN, {
                phone_with_indicative: `${indicative} ${phone}`
            })
            .then(() => {
                successToast(
                    t("Le code OTP vous a été envoyé par sms"),
                    t("Veuillez soumettre le code opt envoyé par sms."),
                    TOAST_DURATION
                );
            })
            .finally(() => setLoading(false));
    }, [indicative, phone, successToast, t]);

    const onSubmit = useCallback(
        (data: FieldValues) => {
            function submitData() {
                setErrors({});
                const endpoint = getSubmitEndpoint();
                if (endpoint) {
                    if (data.phone_with_indicative) {
                        const phoneNumber = `${indicative}${data.phone_with_indicative}`;

                        if (!phoneIsValid(phoneNumber)) {
                            setErrors({
                                ...errors,
                                phone_with_indicative: t("Invalid phone number")
                            });
                            return;
                        }
                    }

                    const sendPhone = showForgotPinForm
                        ? data.phone_with_indicative
                        : phone;
                    const sendData = {
                        phone_with_indicative: `${indicative} ${sendPhone}`,
                        code: code,
                        pin: pin
                    };
                    setLoading(true);
                    loadAuthServiceHost();
                    const method =
                        showForgotPinForm || showResetPinForm ? "post" : "get";
                    const requestData =
                        showForgotPinForm || showResetPinForm
                            ? sendData
                            : { params: sendData };
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.axios[method](endpoint, requestData)
                        .then(response => {
                            if (
                                showVerifyPinForm &&
                                response.data.output.key ===
                                    TEMPORARY_CODE_INCORRECT
                            ) {
                                setErrors({ code: response.data.message });
                                return;
                            }
                            reset();
                            if (showForgotPinForm) {
                                setPhone(data.phone_with_indicative);
                                successToast(
                                    t("Le code OTP vous a été envoyé par sms"),
                                    t(
                                        "Veuillez soumettre le code opt envoyé par sms."
                                    ),
                                    TOAST_DURATION
                                );
                            }

                            if (showVerifyPinForm) {
                                successToast(
                                    t("OTP vérifie avec succès"),
                                    t(""),
                                    TOAST_DURATION
                                );
                            }

                            if (showResetPinForm) {
                                successToast(
                                    t(
                                        "Le code secret a été modifié avec succès"
                                    ),
                                    t(
                                        "Veuillez vous connecter avec le nouveau code secret pour accéder à votre compte."
                                    )
                                );
                                navigate(config.auth.redirectLogoutUrl);
                            }

                            if (showForgotPinForm) {
                                setShowForgotPinForm(false);
                                setShowVerifyPinForm(true);
                            }

                            if (showVerifyPinForm) {
                                setShowVerifyPinForm(false);
                                setShowResetPinForm(true);
                            }
                        })
                        .catch(error => {
                            const output = error.response.data.output;
                            if (
                                error.response.status ===
                                RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                            ) {
                                setErrors(error.response.data.errors);
                            } else {
                                reset();
                                setErrors({});
                            }

                            let toastMessage = "";
                            if (showForgotPinForm)
                                toastMessage = t(
                                    "Numéro de téléphone invalide"
                                );

                            if (showResetPinForm)
                                toastMessage = t(
                                    "Échec de la modification de la broche"
                                );

                            if (output.key) {
                                printErrorMessage(
                                    sendData.phone_with_indicative || "",
                                    output.key || ""
                                );
                            } else {
                                errorToast(toastMessage);
                            }
                        })
                        .finally(() => setLoading(false));
                }
            }

            if (showForgotPinForm) {
                ForgotPinSchema.validate(data, { abortEarly: false })
                    .then(() => {
                        submitData();
                    })
                    .catch((validationErrors: yup.ValidationError) => {
                        const validationErrorsMap: { [key: string]: string } =
                            {};
                        validationErrors.inner.forEach(error => {
                            if (error.path)
                                validationErrorsMap[error.path] = error.message;
                        });
                        setErrors({ ...errors, ...validationErrorsMap });
                    });
            }

            if (showVerifyPinForm) {
                if (code.length === 4) {
                    submitData();
                } else {
                    setErrors({
                        code: t(
                            "Le code est obligatoire et doit se composer de 4 chiffres."
                        )
                    });
                }
            }

            if (showResetPinForm) {
                ResetPinSchema.validate(
                    { pin, confirm_pin: confirmPin },
                    { abortEarly: false }
                )
                    .then(() => {
                        submitData();
                    })
                    .catch((validationErrors: yup.ValidationError) => {
                        const validationErrorsMap: { [key: string]: string } =
                            {};
                        validationErrors.inner.forEach(error => {
                            if (error.path)
                                validationErrorsMap[error.path] = error.message;
                        });
                        setErrors({ ...errors, ...validationErrorsMap });
                    });
            }
        },
        [
            showForgotPinForm,
            showVerifyPinForm,
            showResetPinForm,
            getSubmitEndpoint,
            phone,
            indicative,
            code,
            pin,
            errors,
            t,
            reset,
            successToast,
            navigate,
            printErrorMessage,
            errorToast,
            confirmPin
        ]
    );

    return (
        <AuthLayout title={title}>
            <ForgotFlowForm
                showForgotPinForm={showForgotPinForm}
                showVerifyPinForm={showVerifyPinForm}
                showResetPinForm={showResetPinForm}
                phone={phone}
                indicative={{ value: indicative, setValue: setIndicative }}
                code={{ value: code, setValue: setCode }}
                register={register}
                errors={errors}
                pin={pin}
                setPin={setPin}
                confirmPin={confirmPin}
                setConfirmPin={setConfirmPin}
                handleSubmit={handleSubmit(onSubmit)}
                resendCode={handleResendCode}
                loading={loading}
            />
        </AuthLayout>
    );
};

export default ForgotPin;
