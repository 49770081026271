import {
    InputHTMLAttributes,
    SVGAttributes,
    TextareaHTMLAttributes
} from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";

export type SessionType = "localStorage" | "sessionStorage";

export type SizeType = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";

export interface IconProps extends SVGAttributes<SVGElement> {
    color?: "primary" | "secondary";
    size?: SizeType;
}

export interface Validator {
    rule: string;
    message?: string;
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: FieldError | string | string[];
    register?: UseFormRegisterReturn;
}

export interface TextareaProps
    extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    error?: FieldError | string | string[];
    register?: UseFormRegisterReturn;
}

export enum COLOR {
    PRIMARY = "#009FBC",
    WHITE = "#FFFFFF"
}

export interface ModalPropsType {
    isOpen: boolean;
    closeModal: () => void;
    openModal?: () => void;
}

export enum ToastPositionEnum {
    "topRight" = "top-right",
    "topLeft" = "top-left",
    "topCenter" = "top-center",
    "bottomRight" = "bottom-right",
    "bottomLeft" = "bottom-left",
    "bottomCenter" = "bottom-center"
}

export enum ToastTypeEnum {
    success = "success",
    warning = "warning",
    danger = "danger"
}

export enum StatusType {
    toValidate = "to_validate",
    failed = "failed",
    success = "success",
    pending = "pending",
    refund = "refund"
}

export interface PhoneType {
    phoneNumber: string;
    indicative: string;
}

export interface ChannelType {
    id: number;
    name: string;
    slug: string;
    type: string;
    country_id: number;
    available_at: string;
    has_check_status: number;
    has_otp: number;
    has_default: number;
    deleted_at: null | string;
    created_at: null | string;
    updated_at: null | string;
}

export interface TransferType {
    id: number;
    identifier: string | null;
    user_id: number;
    amount: number;
    fees: string;
    receiver_name: string;
    sender_phone: string;
    receiver_phone: string;
    pay_in_channel_id: number;
    pay_out_channel_id: number;
    status: StatusType;
    status_pay_in: StatusType;
    status_pay_out: StatusType;
    ref_pay_in_partner: string | null;
    ref_pay_out_partner: string | null;
    created_at: string;
    updated_at: string;
    support_fees: number;
    pay_out_channel: ChannelType | null;
    pay_in_channel: ChannelType | null;
    receipt_url: string | null;
    french_receipt_url: string | null;
    english_receipt_url: string | null;
    sender_transfer_card?: CardType | null;
    sender_transfer_card_id?: string | null;
    number_retry_authorizations: number;
}

export enum TransferFlowEnum {
    userHaveAccount = "have_account",
    secondTransfer = "not_account",
    transferNotInit = "transfert_not_init",
    incorrectReceiveAmount = "incorrect_receive_amount",
    kycInvalid = "kyc_unvalidated",
    userConnectedNotOk = "user_connected_not_ok",
    phoneNumberUnauthorized = "pay_in_phone_number_unauthorized",
    payInChanelNotAvailable = "pay_in_chanel_not_available",
    payOutChanelNotAvailable = "pay_out_chanel_not_available"
}

export interface AccountType {
    id: string;
    user_id: number;
    account_type: string;
    account_number: string;
    can_retry_transfer: boolean;
    status: string;
    transfer_kyc_status: string | null;
    card_kyc_status: string | null;
    suspended_at: string | null;
    suspension_reason: string | null;
    comments: string;
    transfer_kyc_rejected_at: string | null;
    transfer_kyc_to_review_at: string | null;
    transfer_kyc_validated_at: string | null;
    card_kyc_rejected_at: string | null;
    card_kyc_to_review_at: string | null;
    card_kyc_validated_at: string | null;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    transfer_phone_numbers: SecondaryPhoneType[];
    transfer_cards: CardType[];
    show_card_modal?: boolean;
    added_card_at?: string;
    show_switch_to_10_digits_in_benin_for_phone_number_modal?: boolean;
}

interface CeilingType {
    "per-day": {
        limitation: number;
        used: number;
        remains: number;
    };
    "per-week": {
        limitation: number;
        used: number;
        remains: number;
    };
    "per-month": {
        limitation: number;
        used: number;
        remains: number;
    };
}

export interface UserType {
    id: number;
    full_name: string;
    phone_with_indicative: string;
    email: string | null;
    photo: string | null;
    country_id: string | null;
    cgu_validated_at: string | null;
    selfie_photo: string | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    photo_base64: string | null;
    account: AccountType;
    user_limit_transactionnel: boolean;
    user_transfer_amount: number;
    transactional_ceiling: {
        wallet: CeilingType;
        card: CeilingType;
    };
}

export interface AccountNotificationType extends AccountType {
    user?: UserType;
}

export interface KycActionDataType {
    key: string;
    account: AccountNotificationType;
}

export enum FileExtensionEnum {
    jpeg = "jpeg",
    png = "png",
    pdf = "pdf",
    xlsx = "xlsx",
    csv = "csv"
}

export enum TypeMimEnum {
    jpeg = "image/jpeg"
}

export enum PlatformEnum {
    "macOs" = "macos",
    "windows" = "windows",
    "iPhone" = "iphone",
    "android" = "android"
}

export enum KycStatusType {
    rejected = "rejected",
    validated = "validated",
    to_validate = "to_validate",
    to_review = "to_review",
    submit_review = "submit_review"
}

export interface SecondaryPhoneType {
    id: string;
    phone_with_indicative: string;
    type: string;
    temporary_code: string;
    code_expire_at: string;
    account_id: number;
    country_id: number;
    validated_at: string | null;
    activated_at: string;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    account: AccountType;
    country: Country;
}

export interface Country {
    id: number;
    name: string;
    slug: string;
    code: string;
    phone_code: string;
    currency: string;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
}

export interface CardType {
    id: string;
    name: string;
    type: string;
    account_id: number;
    country_id: number | null;
    updated_at: string | null;
    created_at: string | null;
    deleted_at: string | null;
    validated_at: string | null;
    activated_at: string | null;
    account: AccountType;
    country: Country;
    card_number: string;
}
