import { Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import { createPortal } from "react-dom";

import { cn } from "../../helpers";
import { ToastPositionEnum, ToastTypeEnum } from "../../types";
import CheckCircleIcon from "../icons/CheckCircleIcon.tsx";
import ExclamationCircleIcon from "../icons/ExclamationCircleIcon.tsx";
import XMarkCircleIcon from "../icons/XMarkCircleIcon.tsx";
import XMarkIcon from "../icons/XMarkIcon.tsx";

export interface ToastPropsType {
    duration?: number;
    position?: ToastPositionEnum;
    type?: ToastTypeEnum;
    description?: string;
    title: string;
    show: boolean;
}

interface Props extends ToastPropsType {
    closeToast: () => void;
}

const MobileToast = (props: Props) => {
    return (
        <Transition
            as={Fragment}
            show={props.show}
            enter="transform duration-200 transition"
            enterFrom="opacity-0"
            enterTo="opacity-1"
            leave="transform duration-200 transition ease-in-out"
            leaveFrom="opacity-1"
            leaveTo="opacity-0"
        >
            <div
                className={cn(
                    "rounded-full bg-gray-700 text-center text-sm text-white shadow-sm lg:hidden",
                    "fixed bottom-16 left-[50%] z-50 px-6 py-3",
                    "translate-x-[-50%]",
                    "max-w-[400px] truncate"
                )}
            >
                {props.title}
            </div>
        </Transition>
    );
};

const Toast = (props: Props) => {
    useEffect(() => {
        if (props.show && !window.timer) {
            window.timer = setTimeout(() => {
                props.closeToast();
                delete window.timer;
            }, props.duration);
        }

        return () => {
            if (window.timer) {
                delete window.timer;
                clearTimeout(window.timer);
            }
        };
    }, [props, props.show]);

    return createPortal(
        <>
            <Transition
                as={Fragment}
                show={props.show}
                enter="transform duration-200 transition ease-[cubic-bezier(.47,1.64,.41,.8)]"
                enterFrom="opacity-0 translate-x-[5rem]"
                enterTo="opacity-1 translate-x-0"
                leave="transform duration-200 transition ease-in-out"
                leaveFrom="opacity-1 translate-x-0"
                leaveTo="opacity-0 translate-x-[5rem]"
            >
                <div
                    className={cn({
                        "rounded-md border bg-white p-4 text-sm shadow-lg":
                            true,
                        "fixed right-6 top-6 z-50 hidden lg:block": true
                    })}
                    data-cy="toast"
                >
                    <div className="flex items-start">
                        <div className="flex-shrink">
                            {props.type === ToastTypeEnum.success && (
                                <CheckCircleIcon className="h-6 w-6 text-green-500" />
                            )}

                            {props.type === ToastTypeEnum.warning && (
                                <ExclamationCircleIcon className="h-6 w-6 text-orange-600" />
                            )}

                            {props.type === ToastTypeEnum.danger && (
                                <XMarkCircleIcon className="h-6 w-6 text-red-600" />
                            )}
                        </div>

                        <div className="ml-3 max-w-[270px] flex-1 pt-0.5 lg:max-w-[300px]">
                            <p className="font-medium text-gray-700">
                                {props.title}
                            </p>

                            {!!props.description && (
                                <p className="text-gray-500">
                                    {props.description}
                                </p>
                            )}
                        </div>

                        <div className="ml-4 flex flex-shrink">
                            <button
                                onClick={() => {
                                    if (window.timer) {
                                        clearTimeout(window.timer);
                                        delete window.timer;
                                        props.closeToast();
                                    }
                                }}
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="text-gray-500" />
                            </button>
                        </div>
                    </div>
                </div>
            </Transition>

            <MobileToast {...props} />
        </>,
        document.body
    );
};

export default Toast;
