import Button from "@ui/Button.tsx";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { cn, scrollToTop } from "../helpers";
import useManageWalletChannels from "../hooks/useManageWalletChannels.ts";
import useTransferModal from "../hooks/useTransferModal.ts";
import { formatDate } from "../libs/date.ts";
import { RootState } from "../store";
import {
    setRetryTransfer,
    setTransferFees,
    updateTransferFormData
} from "../store/transferSlice.ts";
import { StatusType, TransferType } from "../types";

interface Props {
    item: TransferType;
}

const TransferItem = (props: Props) => {
    const { item: transfer } = props;

    const { t } = useTranslation();
    const { openTransferModal } = useTransferModal();
    const transferFormData = useSelector(
        (state: RootState) => state.transfer.formData
    );
    const manageWalletChannel = useManageWalletChannels();
    const dispatch = useDispatch();

    const handleRetryTransfer = useCallback(() => {
        const receiverPhone = transfer.receiver_phone.split(" ");
        const senderPhone = transfer.sender_phone.split(" ");
        let cardData: {
            [key: string]: string;
            value: string;
            label: string;
        } | null = null;

        if (transfer?.sender_transfer_card) {
            cardData = {
                value: transfer.sender_transfer_card.id,
                label: transfer.sender_transfer_card.card_number
            };
        }

        const amount =
            typeof transfer.amount === "string"
                ? parseInt(transfer.amount)
                : transfer.amount;
        const fees =
            typeof transfer.fees === "string"
                ? parseInt(transfer.fees)
                : transfer.fees;

        dispatch(
            updateTransferFormData({
                senderAmount: amount.toString(),
                senderWallet: manageWalletChannel.getChannelById(
                    true,
                    transfer.pay_in_channel_id
                ),
                senderPhone: {
                    phoneNumber: transfer.sender_phone
                        ? senderPhone[1]
                        : transferFormData.senderPhone.phoneNumber,
                    indicative: transfer.sender_phone
                        ? senderPhone[0]
                        : transferFormData.senderPhone.indicative
                },
                recipientAmount: (amount + fees).toString(),
                recipientWallet: manageWalletChannel.getChannelById(
                    false,
                    transfer.pay_out_channel_id
                ),
                recipientPhone: {
                    phoneNumber: receiverPhone[1],
                    indicative: receiverPhone[0]
                },
                recipientName: transfer.receiver_name || undefined,
                supportFees: !!transfer.support_fees,
                card: cardData,
                cvv: "",
                expirationDate: ""
            })
        );
        dispatch(setRetryTransfer(transfer));
        dispatch(setTransferFees(fees.toString()));
        scrollToTop();
    }, [
        dispatch,
        manageWalletChannel,
        transfer,
        transferFormData.senderPhone.indicative,
        transferFormData.senderPhone.phoneNumber
    ]);

    const canRetry = useMemo(() => {
        return (
            transfer?.status_pay_in === StatusType.success &&
            transfer?.status_pay_out === StatusType.failed &&
            transfer?.status !== StatusType.refund
        );
    }, [transfer?.status, transfer?.status_pay_in, transfer?.status_pay_out]);

    return (
        <>
            <button
                onClick={() => openTransferModal(transfer)}
                className="flex w-full cursor-pointer items-end justify-between text-sm text-gray-700 focus:outline-none"
            >
                <div className="text-left">
                    <p className="mb-2.5 w-[175px] truncate text-gray-600">
                        {t("Envoyer à")}{" "}
                        {transfer?.receiver_name || transfer.receiver_phone}
                    </p>
                    <p className="font-semibold">{transfer.amount} Fcfa</p>
                </div>

                <div className="text-end">
                    <p
                        className={cn({
                            "mb-2.5": true,
                            "text-[#0B7226]":
                                transfer.status === StatusType.success,
                            "text-[#FDB55E]":
                                transfer.status === StatusType.pending,
                            "text-[#EF4444]":
                                transfer.status === StatusType.failed,
                            "text-[#0091E6]":
                                transfer.status === StatusType.refund
                        })}
                    >
                        {transfer.status === StatusType.success && t("Succès")}
                        {transfer.status === StatusType.pending &&
                            t("En attente")}
                        {transfer.status === StatusType.failed && t("Echec")}
                        {transfer.status === StatusType.refund &&
                            t("Remboursée")}
                    </p>
                    <p className="text-gray-400">
                        {formatDate(transfer.created_at, "lll")}
                    </p>
                </div>
            </button>

            {canRetry && transfer?.number_retry_authorizations > 0 && (
                <Button
                    variant="outline"
                    withAuto
                    className="mt-4 px-3"
                    size="sm"
                    onClick={handleRetryTransfer}
                >
                    {t("Réessayer le transfert")}
                </Button>
            )}

            <hr className="my-4" />
        </>
    );
};

export default TransferItem;
