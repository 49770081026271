import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { cn } from "../../helpers";
import useAuth from "../../hooks/useAuth.ts";
import { RootState } from "../../store";
import { KycStatusType } from "../../types";
import InfoMessage from "../InfoMessage.tsx";

interface Props {
    defaultValue?: string;
}

const ToReviewBanner = () => {
    const { t } = useTranslation();
    const { user } = useAuth();

    return (
        <InfoMessage className="bg-[#e590271a] text-[#E59027]">
            {!user.user_limit_transactionnel ? (
                <>
                    {t(
                        "Certaines informations ou documents fournis nécessitent des corrections"
                    )}
                    .{" "}
                </>
            ) : (
                <>
                    {t(
                        "Plafond atteint! Certaines informations ou documents fournis nécessitent des corrections. Pour continuer à utiliser nos services"
                    )}
                    ,{" "}
                </>
            )}
            <NavLink to={PAGES.CORRECT_KYC} className="text-red-500 underline">
                {t("Cliquez ici pour mettre à jour")}
            </NavLink>
        </InfoMessage>
    );
};

const TopAlert = (props: Props) => {
    const { defaultValue } = props;
    const { kycActionData, isLogin, user } = useAuth();
    const { t } = useTranslation();
    const {
        showTransferKycValidateMessage,
        showTransferCardKycValidateMessage
    } = useSelector((state: RootState) => state.ui);

    const formatDefaultValue = useMemo(() => {
        if (
            (kycActionData.transferToValidate &&
                kycActionData.cardToValidate) ||
            (kycActionData.transferToValidate && kycActionData.cardValidate)
        )
            return t(
                "Vos informations ont été reçues. Votre compte est en cours de validation, nous vous ferons un retour"
            );
        return defaultValue;
    }, [
        defaultValue,
        kycActionData.cardToValidate,
        kycActionData.cardValidate,
        kycActionData.transferToValidate,
        t
    ]);

    return (
        <>
            {defaultValue &&
                (!kycActionData.oneActionIsVerify ||
                    kycActionData.validate) && (
                    <>
                        {kycActionData.validate ? (
                            <>
                                {showTransferCardKycValidateMessage && (
                                    <InfoMessage className="bg-[#22c55e1a] text-[#077A31]">
                                        {t(
                                            "Compte validé ! Vous pouvez transférer de l'argent avec votre carte bancaire ou votre mobile money"
                                        )}
                                    </InfoMessage>
                                )}
                            </>
                        ) : (
                            <>
                                {kycActionData.transferValidate && (
                                    <>
                                        {showTransferKycValidateMessage &&
                                            user?.account?.card_kyc_status ===
                                                KycStatusType.to_validate && (
                                                <InfoMessage className="bg-[#22c55e1a] text-[#077A31]">
                                                    {t(
                                                        "Compte validé ! Transférez de l'argent depuis votre mobile money uniquement. Le service de transfert par carte bancaire est en cours de validation"
                                                    )}
                                                </InfoMessage>
                                            )}

                                        {kycActionData.cardReview && (
                                            <ToReviewBanner />
                                        )}
                                    </>
                                )}

                                <>
                                    {kycActionData.userHaveSelfie ? (
                                        <>
                                            {!kycActionData.transferValidate && (
                                                <InfoMessage
                                                    className={cn({
                                                        "bg-[#e736361a] text-[#E73636]":
                                                            user.user_limit_transactionnel,
                                                        "bg-[#e590271a] text-[#E59027]":
                                                            kycActionData.cardSubmitReview ||
                                                            kycActionData.transferSubmitReview ||
                                                            (kycActionData.transferToValidate &&
                                                                kycActionData.cardToValidate) ||
                                                            (kycActionData.transferToValidate &&
                                                                kycActionData.cardValidate)
                                                    })}
                                                >
                                                    {!user?.user_limit_transactionnel ? (
                                                        <>
                                                            {kycActionData.cardSubmitReview ||
                                                            kycActionData.transferSubmitReview
                                                                ? t(
                                                                      "Vos informations ont été reçues. Votre compte est en cours de validation, nous vous ferons un retour."
                                                                  )
                                                                : formatDefaultValue}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {(kycActionData.cardSubmitReview &&
                                                                kycActionData.transferSubmitReview) ||
                                                            (kycActionData.transferSubmitReview &&
                                                                kycActionData.cardValidate)
                                                                ? t(
                                                                      "Vos informations ont été reçues ! Vous avez atteint la limite de transfert autorisée. Votre compte doit être validé avant de pouvoir continuer"
                                                                  )
                                                                : t(
                                                                      "Vous avez atteint la limite de transfert autorisée, vous ne pouvez plus faire de transfert"
                                                                  )}
                                                        </>
                                                    )}
                                                </InfoMessage>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {isLogin ? (
                                                <>
                                                    {kycActionData.transferSubmitReview && (
                                                        <InfoMessage
                                                            className={cn({
                                                                "bg-[#e590271a] text-[#E59027]":
                                                                    !user.user_limit_transactionnel,
                                                                "bg-[#e736361a] text-[#E73636]":
                                                                    user.user_limit_transactionnel
                                                            })}
                                                        >
                                                            {!user.user_limit_transactionnel
                                                                ? t(
                                                                      "Vos informations ont été reçues. Votre compte est en cours de validation, nous vous ferons un retour."
                                                                  )
                                                                : t(
                                                                      "Vos informations ont été reçues ! Vous avez atteint la limite de transfert autorisée. Votre compte doit être validé avant de pouvoir continuer."
                                                                  )}
                                                        </InfoMessage>
                                                    )}

                                                    {!kycActionData.transferSubmitReview &&
                                                        !kycActionData.cardSubmitReview &&
                                                        !kycActionData.transferValidate &&
                                                        !kycActionData.cardValidate && (
                                                            <InfoMessage
                                                                persist={true}
                                                                showCloseIcon={
                                                                    true
                                                                }
                                                            >
                                                                {t(
                                                                    "Nous vous invitons à"
                                                                )}{" "}
                                                                <NavLink
                                                                    to={
                                                                        PAGES.CORRECT_KYC
                                                                    }
                                                                    className="text-red-500 hover:underline"
                                                                >
                                                                    {t(
                                                                        "compléter vos informations de profil"
                                                                    )}{" "}
                                                                </NavLink>{" "}
                                                                {t(
                                                                    "pour profiter de toutes les options de MyDunya"
                                                                )}
                                                            </InfoMessage>
                                                        )}
                                                </>
                                            ) : (
                                                <InfoMessage>
                                                    {formatDefaultValue}
                                                </InfoMessage>
                                            )}
                                        </>
                                    )}
                                </>
                            </>
                        )}
                    </>
                )}

            {kycActionData.rejected ? (
                <>
                    {kycActionData.transferRejected ? (
                        <InfoMessage className="bg-red-100 text-red-700">
                            {t(
                                "Pour des raisons de sécurité, votre compte a été bloqué"
                            )}
                        </InfoMessage>
                    ) : (
                        <InfoMessage
                            className={
                                kycActionData.transferValidate
                                    ? "bg-red-100 text-red-700"
                                    : ""
                            }
                        >
                            {kycActionData.transferValidate
                                ? t(
                                      "Pour des raisons de sécurité, votre demande d'utilisation de carte n'a pas été validée. Vous pouvez toujours effectuer des transferts via Mobile Money"
                                  )
                                : formatDefaultValue}
                        </InfoMessage>
                    )}
                </>
            ) : (
                <>{kycActionData.toReview && <ToReviewBanner />}</>
            )}

            {isLogin &&
                ((kycActionData.transferValidate &&
                    kycActionData.cardSubmitReview) ||
                    (kycActionData.transferToValidate &&
                        kycActionData.cardSubmitReview)) && (
                    <InfoMessage className="bg-[#e590271a] text-[#E59027]">
                        {t(
                            "Vos informations ont été reçues. Votre compte est en cours de validation, nous vous ferons un retour"
                        )}
                    </InfoMessage>
                )}
        </>
    );
};

export default TopAlert;
