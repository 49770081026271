import { ImgHTMLAttributes } from "react";

import { cn } from "../helpers";

type Props = ImgHTMLAttributes<HTMLImageElement>;

const MyDunyaLogo = (props: Props) => {
    const { src = "/icon-512x512.png", className, alt, ...rest } = props;
    return (
        <img
            className={cn(
                "mb-10 w-16 rounded-2xl bg-white object-cover object-center",
                className
            )}
            src={src}
            {...rest}
            alt={alt}
        />
    );
};

export default MyDunyaLogo;
