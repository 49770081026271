import { cn } from "../../helpers";
import { IconProps } from "../../types";

const LuEye = ({ size = "md", color, className = "" }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            className={cn({
                "text-primary": color === "primary",
                "text-secondary": color === "secondary",
                "h-3 w-3": size === "xs",
                "h-4 w-4": size === "sm",
                "h-5 w-5": size === "md",
                "h-7 w-7": size === "lg",
                "h-8 w-8": size === "xl",
                [className]: true
            })}
            role="img"
        >
            <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7"></path>
            <circle cx="12" cy="12" r="3"></circle>
        </svg>
    );
};

export default LuEye;
