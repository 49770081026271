import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import BiSolidDownload from "../components/icons/BiSolidDownload.tsx";
import TransferDetail from "../components/TransferDetail.tsx";
import ENDPOINTS from "../constants/endpoint.ts";
import { downloadFile, loadTransferServiceHost } from "../helpers";
import { TransferType } from "../types";

const TransferDetailPage = () => {
    const { t } = useTranslation();
    const [transfer, setTransfer] = useState<TransferType>();
    const { id } = useParams();

    useEffect(() => {
        loadTransferServiceHost();
        window.axios
            .get(`${ENDPOINTS.TRANSFER_DETAIL.replace(":id", id || "")}`)
            .then(response => {
                response.data.data && setTransfer(response.data.data);
            });
    }, [id]);

    return (
        <div className="flex h-screen flex-col items-center justify-center space-y-8 bg-white px-10 lg:px-0">
            <h4 className="text-center text-lg font-medium text-gray-700">
                {t("Reçu")}
            </h4>

            <div className="w-full lg:w-2/5">
                {transfer && (
                    <TransferDetail
                        transfer={transfer}
                        service={t("Transfer")}
                    />
                )}
            </div>

            <button
                onClick={() => {
                    transfer?.receipt_url &&
                        downloadFile(transfer?.receipt_url, true);
                }}
                className="mx-auto flex items-center justify-center space-x-3 font-medium text-primary"
            >
                <BiSolidDownload />
                <span>{t("Télécharger le reçu")}</span>
            </button>
        </div>
    );
};

export default TransferDetailPage;
