import React from "react";

import { cn } from "../../helpers";
import Container from "../Container.tsx";

interface Props {
    children?: React.ReactNode;
    className?: string;
    containerClassName?: string;
}

const Aside = (props: Props) => {
    return (
        <aside
            className={cn(
                "col-span-3 rounded-lg bg-white pb-4 pt-6 lg:col-span-1",
                props.className
            )}
        >
            <Container
                className={cn(
                    "py-4 lg:mx-6 lg:w-auto",
                    props.containerClassName
                )}
            >
                {props.children}
            </Container>
        </aside>
    );
};

export default Aside;
