import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ENDPOINTS from "../../constants/endpoint.ts";
import { loadTransferServiceHost, thousandFormater } from "../../helpers";
import useManageWalletChannels from "../../hooks/useManageWalletChannels.ts";
import { ModalPropsType } from "../../types";
import Loading from "../Loading.tsx";

interface Props extends ModalPropsType {
    onConfirm: () => void;
    number: string;
    amount: string | number;
    receiverWallet: { [key: string]: string } | null;
    senderWallet: { [key: string]: string } | null;
    supportFees?: boolean;
}

const ConfirmTransferModal = (props: Props) => {
    const { t } = useTranslation();
    const {
        isOpen,
        closeModal,
        amount,
        number,
        onConfirm,
        receiverWallet,
        senderWallet,
        supportFees
    } = props;

    const [fees, setFees] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    const { getCountryById } = useManageWalletChannels();

    useEffect(() => {
        if (senderWallet?.id && receiverWallet?.id && amount) {
            const sendData = {
                pay_in_channel_id: senderWallet?.id,
                pay_out_channel_id: receiverWallet?.id,
                amount
            };

            loadTransferServiceHost();
            setLoading(true);
            window.axios
                .post(ENDPOINTS.GET_TRANSFER_FEES, sendData)
                .then(response => {
                    setFees(response.data.data?.fees || 0);
                })
                .finally(() => setLoading(false));
        }
    }, [amount, receiverWallet?.id, senderWallet?.id]);

    const receiverAmount = useMemo(() => {
        if (!supportFees) {
            return thousandFormater(
                parseFloat(amount?.toString() || "0") -
                    parseInt(fees?.toString() || "0")
            );
        }

        return thousandFormater(parseFloat(amount?.toString() || "0"));
    }, [amount, fees, supportFees]);

    return (
        <Modal isOpen={isOpen} closeModal={closeModal}>
            <h5 className="mb-3 text-center font-semibold">
                {t("Confirmer le transfert")}
            </h5>

            <p className="mb-3 text-center text-sm">
                {t(
                    "Vous allez envoyer :amount FCFA à :number sur :wallet :country. Frais: :fees FCFA",
                    {
                        amount: receiverAmount,
                        number,
                        wallet: receiverWallet?.label || "---",
                        country: receiverWallet?.country_id
                            ? getCountryById(
                                  false,
                                  parseInt(receiverWallet?.country_id)
                              )?.label || "---"
                            : "---",
                        fees: fees || 0
                    }
                )}
            </p>

            <p className="mb-3 text-center text-sm text-red-600">
                {t(
                    "Assurez-vous de mettre le bon numéro et de choisir le bon wallet."
                )}{" "}
                {t(
                    "En cas d'erreur, le remboursement pourrait prendre du temps."
                )}
            </p>

            {loading && (
                <div className="mb-2 flex items-center justify-center space-x-2">
                    <p className="text-center text-xs">
                        {t("Calcule des frais en cours")}
                    </p>

                    <Loading className="h-3 w-3 text-primary" />
                </div>
            )}

            {fees === null && (
                <p className="text-center text-xs text-red-500">
                    {t("Vous ne pouvez pas envoyer moins de 200 FCFA")}
                </p>
            )}

            <div className="mt-5 flex items-center justify-center space-x-5">
                <Button
                    onClick={onConfirm}
                    size="xs"
                    className="px-8 py-2"
                    withAuto
                    type="button"
                    disabled={fees === null}
                >
                    {t("Confirmer")}
                </Button>

                <Button
                    onClick={closeModal}
                    size="xs"
                    className="px-8 py-2"
                    withAuto
                    variant="outline"
                    type="button"
                >
                    {t("Modifier")}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmTransferModal;
