import { useState } from "react";
import { useTranslation } from "react-i18next";

import ToggleTransition from "../../../components/ToggleTransition.tsx";
import { cn } from "../../../helpers";
import useAuth from "../../../hooks/useAuth.ts";

import GeneralButton from "./GeneralButton.tsx";
import UpdatePinForm from "./UpdatePinForm.tsx";

const UpdateSecretCodeButton = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { isLogin, kycActionData } = useAuth();
    const { t } = useTranslation();

    if (!isLogin || kycActionData.transferRejected) return null;

    return (
        <>
            <GeneralButton
                title={t("Changer le code secret")}
                className="mb-0 border-transparent"
                onClick={() => setIsOpen(!isOpen)}
                isActive={isOpen}
            >
                {t("Modifier le code secret du compte.")}
            </GeneralButton>

            <ToggleTransition show={isOpen}>
                <UpdatePinForm />
            </ToggleTransition>

            <hr
                className={cn({
                    "mb-4": true,
                    "my-4": isOpen
                })}
            />
        </>
    );
};

export default UpdateSecretCodeButton;
