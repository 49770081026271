import { ReactNode } from "react";

import useThemeColor from "../../hooks/useThemeColor.ts";
import { COLOR } from "../../types";

import Nav from "./Nav.tsx";
import TopAlert from "./TopAlert.tsx";

interface Props {
    children: ReactNode;
    info?: string;
    showAlert?: boolean;
}

const Main = (props: Props) => {
    const { showAlert = true } = props;
    useThemeColor(COLOR.PRIMARY);

    return (
        <>
            <section role="topbar" className="fixed left-0 right-0 z-10">
                <Nav />
            </section>

            <main className="px-4 pb-4 pt-[85px] md:min-h-screen lg:px-12 lg:pb-12">
                {showAlert && <TopAlert defaultValue={props.info} />}

                {props.children}
            </main>
        </>
    );
};

export default Main;
