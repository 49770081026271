import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { STORAGE } from "../constants";
import PAGES from "../constants/pages.ts";
import { storage } from "../helpers";

const useRedirectConfirmAccount = () => {
    const navigate = useNavigate();

    const redirect = useCallback(
        (confirmAccountNumber: string) => {
            storage().setItem(
                STORAGE.CONFIRM_ACCOUNT_TEL,
                confirmAccountNumber
            );
            navigate(PAGES.REGISTER);
        },
        [navigate]
    );

    const removePhoneNumber = useCallback(() => {
        setTimeout(() => {
            storage().removeItem(STORAGE.CONFIRM_ACCOUNT_TEL);
        }, 200);
    }, []);

    return { redirect, removePhoneNumber };
};

export default useRedirectConfirmAccount;
