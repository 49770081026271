import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "../helpers";

import SearchIcon from "./icons/SearchIcon.tsx";

interface Props {
    value?: string | number | readonly string[];
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const SecondSearchInput = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className="relative mb-3">
            <input
                data-testid="search-input"
                type="text"
                className={cn(
                    "w-full rounded border-none py-2.5 pl-12 text-sm",
                    "bg-[#f2f5f9] text-gray-500 placeholder-gray-400",
                    "focus:border-none focus:outline-none focus:ring-0"
                )}
                placeholder={`${t("Rechercher")}...`}
                value={props.value}
                onKeyDown={e => e.stopPropagation()}
                onChange={props.onChange}
            />
            <SearchIcon className="absolute left-3 top-[0.65rem] h-5 w-5 text-gray-500" />
        </div>
    );
};

export default SecondSearchInput;
