import Button from "@ui/Button.tsx";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LangSwitcher from "../components/LangSwitcher.tsx";
import { RESPONSE_STATUS } from "../constants";
import { capitalizeFirstCharacter } from "../helpers";

interface Props {
    code?: number;
}

const Error = ({ code = RESPONSE_STATUS.NOT_FOUND }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getCodeMessage = () => {
        const foundKey = Object.keys(RESPONSE_STATUS).find(key => {
            return (
                RESPONSE_STATUS[key as keyof typeof RESPONSE_STATUS] === code
            );
        });

        return foundKey
            ? capitalizeFirstCharacter(
                  foundKey.replaceAll("_", " ").toLowerCase()
              )
            : "";
    };

    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <div className="relative flex h-screen items-center justify-center text-4xl text-gray-500">
            <div className="space-y-3">
                <p>{t(`${code} ${getCodeMessage()}`)}</p>

                <Button withAuto className="mx-auto" onClick={goBack}>
                    {t("Retour")}
                </Button>
            </div>

            <LangSwitcher className="absolute right-16 top-8" />
        </div>
    );
};

export default Error;
