import { useTranslation } from "react-i18next";

import useModal from "../../../hooks/useModal.ts";

import RemoveAccountModal from "./remove-account-modal";

const RemoveAccountButton = () => {
    const { t } = useTranslation();
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <>
            <button
                className="pt-3 text-[10px] font-light text-[#9CA3AF] underline"
                onClick={openModal}
            >
                {t("Fermer votre compte MyDunya")}
            </button>

            <RemoveAccountModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};

export default RemoveAccountButton;
