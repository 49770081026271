import Input from "@ui/Input.tsx";
import { ChangeEvent, useCallback } from "react";

import { COUNTRY, DEFAULT_COUNTRY_INDICATIVE, REGEX } from "../constants";
import { cn } from "../helpers";
import { InputProps } from "../types";

import InputIndicativeDropdown from "./InputIndicativeDropdown.tsx";
import MatchCountryIcon from "./MatchCountryIcon.tsx";

interface Props extends InputProps {
    label?: string;
    indicative?: string;
    otherIndicative?: string;
    onOtherIndicativeChange?: (value: string) => void;
    onIndicativeChange?: (value: string) => void;
    disabledIndicative?: boolean;
    otherCountries?: boolean;
}

const PhoneInput = (props: Props) => {
    const {
        required = true,
        disabledIndicative = false,
        error,
        onIndicativeChange,
        indicative,
        className,
        inputMode = "numeric",
        otherCountries = false,
        otherIndicative = "",
        onOtherIndicativeChange,
        ...rest
    } = props;

    const handleOtherIndicativeChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (!onOtherIndicativeChange) return;

            const { value } = e.target;
            if (!value.startsWith("+")) return;

            if (value.length > 4) return;

            const [, numbers] = value.split("+");

            if (numbers.match(REGEX.CHARACTER)) return;
            const indicatives = COUNTRY.map(item => item.indicative);

            if (value.length === 4 && indicatives.includes(value)) return;

            onOtherIndicativeChange(value);
        },
        [onOtherIndicativeChange]
    );

    return (
        <div className={cn("w-full", className)}>
            <div className="relative">
                {indicative !== "other" ? (
                    <Input
                        required={required}
                        type="number"
                        className={cn({
                            "pl-24": !disabledIndicative,
                            "pl-14": disabledIndicative,
                            "pr-12": disabledIndicative
                        })}
                        error={error}
                        min={0}
                        inputMode={inputMode}
                        {...rest}
                    />
                ) : (
                    <div
                        className={cn({
                            "flex w-full items-center space-x-2": true,
                            "rounded-md border border-gray-300 px-4 py-4": true,
                            "pl-24 text-sm focus:border-primary": true
                        })}
                    >
                        <input
                            className={cn(
                                "max-w-[40px] border-0 p-0 text-gray-700",
                                "text-sm placeholder-gray-400 ring-0",
                                "focus:border-0 focus:outline-none focus:ring-0"
                            )}
                            type="tel"
                            inputMode={inputMode}
                            placeholder="+XXX"
                            autoFocus
                            value={otherIndicative}
                            onChange={handleOtherIndicativeChange}
                        />

                        <input
                            className={cn(
                                "border-0 p-0 text-gray-700 ring-0",
                                "text-sm placeholder-gray-400",
                                "focus:border-0 focus:outline-none focus:ring-0"
                            )}
                            type="number"
                            placeholder="XXXXXXXX"
                            min={0}
                            inputMode={inputMode}
                            value={rest.value}
                            onChange={rest.onChange}
                        />
                    </div>
                )}

                <InputIndicativeDropdown
                    value={indicative || DEFAULT_COUNTRY_INDICATIVE}
                    onChange={value => {
                        if (onIndicativeChange) {
                            onIndicativeChange(value);
                        }
                    }}
                    disabled={disabledIndicative}
                    otherCountries={otherCountries}
                />

                {disabledIndicative && (
                    <MatchCountryIcon
                        indicative={indicative || DEFAULT_COUNTRY_INDICATIVE}
                        className={cn({
                            "absolute right-4 top-4 h-5 w-5": true
                        })}
                    />
                )}
            </div>

            {error && (
                <p
                    className="mt-0.5 text-xs text-red-500"
                    data-cy={`error-${
                        rest.register ? rest.register.name : rest.name
                    }`}
                >
                    {Array.isArray(error) ? (
                        <>{error[0]}</>
                    ) : typeof error === "string" ? (
                        error
                    ) : (
                        error?.message
                    )}
                </p>
            )}
        </div>
    );
};

export default PhoneInput;
