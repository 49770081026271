import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { ModalPropsType } from "../../types";

interface Props extends ModalPropsType {
    description: string;
}

const RegisterAlertModal = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Modal
            isOpen={props.isOpen}
            className="rounded-md"
            closeModal={props.closeModal}
        >
            <h5 className="my-8 text-center text-sm font-medium text-gray-600">
                {props.description}
            </h5>

            <div className="flex items-center space-x-6">
                <Button
                    className="w-1/2"
                    onClick={() => {
                        navigate(PAGES.REGISTER);
                    }}
                    data-cy="register-navigate-button"
                >
                    {t("Créer un compte")}
                </Button>

                <Button
                    className="w-1/2"
                    onClick={props.closeModal}
                    variant="outline"
                    data-cy="close-alert-modal-button"
                >
                    {t("Fermer")}
                </Button>
            </div>
        </Modal>
    );
};

export default RegisterAlertModal;
