import { Modal } from "@ui/Modal.tsx";
import { useTranslation } from "react-i18next";

import { ModalPropsType } from "../../types";
import { BfIcon, BjIcon, CiIcon, GnIcon, SnIcon, TgIcon } from "../flags";
import MyDunyaLogo from "../MyDunyaLogo.tsx";

const InfoDescriptionModal = (props: ModalPropsType) => {
    const { isOpen, closeModal } = props;
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            hiddeCloseIcon
            className="min-w-full lg:min-w-[650px]"
        >
            <div className="flex min-h-[264px] w-full flex-col items-center justify-end rounded-lg bg-white bg-[url('/images/info-mobile.jpg')] bg-cover bg-center p-3 md:items-start md:justify-start md:bg-[url('/images/info.jpg')] md:bg-left-bottom">
                <MyDunyaLogo
                    src="/images/logo-without-bg.png"
                    className="mb-6 hidden w-24 bg-opacity-0 bg-none shadow-none md:block"
                />

                <h3 className="mb-4 mt-48 text-base font-medium text-secondary md:mt-0 md:text-xl">
                    {t("Envoyer de l'argent facilement")}
                    <br />
                    {t("à partir de 1,5% depuis et vers")}
                </h3>

                <div className="mb-4 flex items-center space-x-4">
                    <SnIcon className="h-8 w-8 rounded-full border bg-white shadow-sm" />
                    <CiIcon className="h-8 w-8 rounded-full border bg-white shadow-sm" />
                    <BjIcon className="h-8 w-8 rounded-full border bg-white shadow-sm" />
                    <BfIcon className="h-8 w-8 rounded-full border bg-white shadow-sm" />
                    <TgIcon className="h-8 w-8 rounded-full border bg-white shadow-sm" />
                    <GnIcon className="h-8 w-8 rounded-full border bg-white shadow-sm" />
                </div>
            </div>

            <p className="my-10 text-center text-xs text-gray-700 md:text-sm">
                {t(
                    "Découvrez MyDunya, votre nouvelle solution simple, rapide et sécurisée pour envoyer de l'argent à vos proches depuis votre Mobile Money vers n’importe quel autre Mobile Money en Côte d’Ivoire, Burkina, Mali, Sénégal, Bénin et Togo."
                )}
            </p>

            <div className="text-center">
                <button
                    onClick={closeModal}
                    className="mx-auto text-sm font-medium text-gray-500 underline"
                >
                    {t("Fermer")}
                </button>
            </div>
        </Modal>
    );
};

export default InfoDescriptionModal;
