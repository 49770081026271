import Input from "@ui/Input.tsx";
import { useTranslation } from "react-i18next";

import { cn } from "../helpers";
import { InputProps } from "../types";

import SearchIcon from "./icons/SearchIcon.tsx";
import Loading from "./Loading.tsx";

interface Props extends InputProps {
    divClassName?: string;
    loading?: boolean;
}

const SearchInput = (props: Props) => {
    const { t } = useTranslation();
    const { loading, divClassName, ...rest } = props;
    return (
        <div className={cn("relative", divClassName)}>
            <Input
                type="text"
                className="rounded-lg border-none bg-[#F1F5F9] px-10 text-[#6B7280] placeholder-[#6B7280] focus:border-none focus:outline-none focus:ring-0"
                placeholder={t("Rechercher montant, nom ou numéro")}
                {...rest}
            />

            <SearchIcon className="absolute left-3 top-4 text-gray-700" />

            {loading && (
                <Loading className="absolute right-4 top-[1.1rem] text-primary" />
            )}
        </div>
    );
};

export default SearchInput;
