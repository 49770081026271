import React from "react";

import Main from "./Main.tsx";

interface Props {
    children: React.ReactNode;
    info?: string;
}

const AppLayout = (props: Props) => {
    return (
        <Main info={props.info}>
            <div className="grid grid-cols-3 gap-7 md:min-h-[calc(100vh-200px)]">
                {props.children}
            </div>
        </Main>
    );
};

export default AppLayout;
