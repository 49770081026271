import Button from "@ui/Button.tsx";
import { useTranslation } from "react-i18next";

import useManageCard from "../../hooks/useManageCard.ts";

import AddCardForm from "./AddCardForm.tsx";

interface Props {
    closeModal: () => void;
}

const AddCard = (props: Props) => {
    const { closeModal } = props;

    const { t } = useTranslation();
    const {
        cardName,
        setCardName,
        cardNumber,
        setCardNumber,
        errors,
        adding,
        handleAdd,
        cardAlreadyRegisteredMyAccount,
        cardAlreadyRegisteredOtherAccount,
        cardAlreadyBlocked,
        limitCardsPerMonth
    } = useManageCard();

    return (
        <div>
            <AddCardForm
                cardName={cardName}
                setCardName={setCardName}
                cardNumber={cardNumber}
                setCardNumber={setCardNumber}
                errors={errors}
                cardAlreadyRegisteredMyAccount={cardAlreadyRegisteredMyAccount}
                cardAlreadyRegisteredOtherAccount={
                    cardAlreadyRegisteredOtherAccount
                }
                cardAlreadyBlocked={cardAlreadyBlocked}
                limitCardsPerMonth={limitCardsPerMonth}
            />

            <div className="mt-5 flex items-center justify-center space-x-5">
                <Button
                    type="button"
                    loading={adding}
                    onClick={() => {
                        handleAdd(closeModal);
                    }}
                >
                    {t("Enregistrer")}
                </Button>
            </div>
        </div>
    );
};

export default AddCard;
