import { Modal } from "@ui/Modal.tsx";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useLogin from "../../hooks/useLogin.ts";
import { RootState } from "../../store";
import { submitTransferForm } from "../../store/transferSlice.ts";
import { ModalPropsType } from "../../types";
import LoginForm from "../forms/LoginForm.tsx";

const LoginFormModal = (props: ModalPropsType) => {
    const { t } = useTranslation();
    const { formData: transferFormData } = useSelector(
        (state: RootState) => state.transfer
    );
    const dispatch = useDispatch();
    const {
        loading,
        errors,
        pin,
        setPin,
        phoneWithoutIndicative,
        setPhoneWithoutIndicative,
        onSubmit,
        indicativeState,
        invalidCredential
    } = useLogin(
        {
            phone: {
                indicative: transferFormData.senderPhone.indicative,
                value: transferFormData.senderPhone.phoneNumber
            }
        },
        () => {
            props.closeModal();
            setTimeout(() => {
                dispatch(submitTransferForm());
            }, 300);
        }
    );

    return (
        <Modal isOpen={props.isOpen} closeModal={props.closeModal}>
            <h5 className="my-8 text-center text-sm font-medium text-gray-600">
                {t(
                    "Pour effectuer votre transfert, nous vous invitons à vous connecter"
                )}
            </h5>

            <LoginForm
                showTitle={false}
                showPhoneInput={false}
                loading={loading}
                hiddeForgotLink
                errors={errors}
                pin={pin}
                setPin={setPin}
                phoneWithoutIndicative={phoneWithoutIndicative}
                setPhoneWithoutIndicative={setPhoneWithoutIndicative}
                onSubmit={onSubmit}
                indicative={indicativeState}
                invalidCredential={invalidCredential}
            />
        </Modal>
    );
};

export default LoginFormModal;
