import { ReactNode } from "react";

import ChevronIcon from "../../../components/icons/ChevronIcon.tsx";
import { cn } from "../../../helpers";

interface Props {
    title: string;
    children?: ReactNode;
    onClick?: () => void;
    isActive?: boolean;
    className?: string;
}

const GeneralButton = (props: Props) => {
    const {
        title,
        children,
        isActive = false,
        onClick,
        className = ""
    } = props;
    return (
        <button
            className={cn(
                "mb-4 flex w-full cursor-pointer items-center justify-between border-b px-0 pb-4 focus:outline-none",
                className
            )}
            onClick={onClick}
        >
            <div className="text-start text-[#374151]">
                <h4 className="mb-1 font-bold">{title}</h4>

                <p className="text-sm">{children}</p>
            </div>

            <ChevronIcon
                className={cn({
                    "-rotate-90 transition duration-300": true,
                    "h-4 w-4 text-[#374151]": true,
                    "rotate-0": isActive
                })}
            />
        </button>
    );
};

export default GeneralButton;
