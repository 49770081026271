import { useTranslation } from "react-i18next";

import CGUModal from "../../../components/modals/CGUModal.tsx";
import useModal from "../../../hooks/useModal.ts";

import GeneralButton from "./GeneralButton.tsx";

const CGUButton = () => {
    const { t } = useTranslation();
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <>
            <GeneralButton
                title={t("Conditions générales d’utilisation")}
                onClick={() => openModal()}
            >
                {t(
                    "Lire les conditions générales d’utilisation de l’application"
                )}
            </GeneralButton>

            <CGUModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};

export default CGUButton;
