import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useTranslation } from "react-i18next";

import { ModalPropsType } from "../../types";

type Props = ModalPropsType;

const LIVE_URL = "https://mydunya.com/share";

const TransferInLive = (props: Props) => {
    const { t } = useTranslation();
    return (
        <Modal
            isOpen={props.isOpen}
            closeModal={props.closeModal}
            className="rounded-md"
            hiddeCloseIcon
        >
            <h5 className="text-md mb-2 text-center font-medium text-gray-700">
                {t(
                    "Veuillez effectuer votre transfert depuis notre application"
                )}
            </h5>

            <p className="my-5 text-center">
                <a
                    className="text-sm text-primary hover:underline"
                    href={LIVE_URL}
                >
                    {t("Téléchargé depuis le store")}
                </a>
            </p>

            <Button
                withAuto
                className="mx-auto px-6"
                onClick={props.closeModal}
            >
                {t("Fermer")}
            </Button>
        </Modal>
    );
};

export default TransferInLive;
