import Button from "@ui/Button.tsx";
import Textarea from "@ui/Textarea.tsx";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import InputField from "../../../../components/InputField.tsx";

interface Props {
    show?: boolean;
    handleSubmitForm: () => void;
    pin: string;
    setPin: (value: string) => void;
    error: string[];
    loading?: boolean;
    onClickCancel?: () => void;
    haveReason: boolean;
    reason: string;
    handleReasonChange: (value: string) => void;
    handleSubmitReason: () => void;
}

const MAX_REASON_LENGTH = 200;

const LevelTwo = (props: Props) => {
    const { t } = useTranslation();
    const [error, setError] = useState("");

    const handleValidateReason = useCallback(() => {
        const reason = props.reason;
        if (reason.length === 0 || reason.length > 200) {
            if (reason.length === 0) setError(t("Reason is a required field"));

            if (reason.length > 200)
                setError(
                    t(
                        "Vous avez dépassé le nombre de caractères maximum autorisé (200 maximum)",
                        {
                            max: MAX_REASON_LENGTH
                        }
                    )
                );
        } else {
            props.handleSubmitReason();
        }
    }, [props, t]);

    if (!props.show) return null;

    return (
        <>
            <h5 className="mb-5 mt-12 text-center text-sm font-medium">
                {!props.haveReason
                    ? t(
                          "Veuillez nous indiquer la raison pour laquelle vous souhaitez supprimer votre compte"
                      )
                    : t("Confirmation de code secret")}
            </h5>

            {!props.haveReason ? (
                <div className="relative">
                    <Textarea
                        value={props.reason}
                        onChange={e => props.handleReasonChange(e.target.value)}
                        placeholder={t("200 carcatères maximum")}
                        error={error}
                    />

                    {error.length > 0 && (
                        <p className="absolute text-xs text-red-600">{error}</p>
                    )}
                </div>
            ) : (
                <>
                    <p className="mb-2 text-sm text-gray-600">
                        {t("Code secret")}
                    </p>

                    <InputField
                        isPin
                        id="pin"
                        type="password"
                        inputMode="numeric"
                        value={props.pin}
                        onChange={e => props.setPin(e.target.value)}
                        placeholder={t("Code secret")}
                        error={props.error[0] || ""}
                    />
                </>
            )}

            <div className="mt-8 flex items-center gap-6">
                <Button
                    loading={props.loading}
                    type={"button"}
                    className="w-1/2"
                    onClick={() => {
                        if (!props.haveReason) {
                            handleValidateReason();
                        } else {
                            props.handleSubmitForm();
                        }
                    }}
                >
                    {t("Confirmer")}
                </Button>

                <Button
                    className="w-1/2"
                    onClick={props.onClickCancel}
                    variant="outline"
                >
                    {t("Annuler")}
                </Button>
            </div>
        </>
    );
};

export default LevelTwo;
