import React, { useCallback, useState } from "react";

import { STORAGE } from "../constants";
import { cn, storage } from "../helpers";

import LoCloseIcon from "./icons/LoCloseIcon.tsx";

interface Props {
    children: React.ReactNode;
    showCloseIcon?: boolean;
    persist?: boolean;
    className?: string;
}

const InfoMessage = (props: Props) => {
    const { showCloseIcon = false, persist = false, className } = props;

    const [isOpen, setIsOpen] = useState(true);

    const handleClose = useCallback(() => {
        setIsOpen(!isOpen);
        if (persist) {
            storage().setItem(STORAGE.ALERT_COMPLETE_PROFILE, "true");
        }
    }, [isOpen, persist]);

    if (persist && storage().getItem(STORAGE.ALERT_COMPLETE_PROFILE)) {
        return null;
    }

    if (!isOpen) return null;

    return (
        <div
            className={cn(
                "relative mb-4 flex items-center justify-between rounded-lg bg-[#009FBC5D] p-3 text-sm text-[#42389F]",
                className
            )}
        >
            <p className="w-full text-center lg:text-left">{props.children}</p>

            {showCloseIcon && (
                <LoCloseIcon
                    className="absolute right-4 cursor-pointer"
                    onClick={handleClose}
                />
            )}
        </div>
    );
};

export default InfoMessage;
