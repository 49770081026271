import { cn } from "../helpers";

interface Props {
    src: string;
}

const WalletPicture = (props: Props) => {
    return (
        <div
            className={cn(
                "h-[30px] w-[30px] rounded-full border border-opacity-20 bg-white p-0.5 shadow-sm lg:h-[45px] lg:w-[45px]",
                "flex items-center justify-center"
            )}
        >
            <img
                className="rounded-full object-cover object-center"
                width="35"
                src={props.src}
                alt=""
            />
        </div>
    );
};

export default WalletPicture;
