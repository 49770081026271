import Button from "@ui/Button.tsx";
import CheckboxInput from "@ui/CheckboxInput.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import ENDPOINTS from "../../constants/endpoint.ts";
import PAGES from "../../constants/pages.ts";
import { loadAuthServiceHost } from "../../helpers";
import { updateAuthUser } from "../../store/authSlice.ts";
import { ModalPropsType } from "../../types";

const CGUUpdateModal = (props: ModalPropsType) => {
    const { t } = useTranslation();
    const { isOpen, closeModal } = props;
    const [error, setError] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const acceptCGU = useCallback(() => {
        if (!accepted) {
            setError(true);
            return;
        }
        error && setError(false);

        setLoading(true);
        loadAuthServiceHost();
        window.axios
            .post(ENDPOINTS.ACCEPT_PRIVACY_POLICY)
            .then(() => {
                loadAuthServiceHost();
                window.axios
                    .get(ENDPOINTS.ME)
                    .then(response => {
                        closeModal();
                        const user = response.data.data;
                        const account = user.account;
                        if (user?.account) delete user.account;

                        dispatch(
                            updateAuthUser({
                                user: user || undefined,
                                account: account || undefined
                            })
                        );
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    }, [accepted, closeModal, dispatch, error]);

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} className="rounded-md">
            <h5 className="mb-2 text-center text-lg font-medium text-gray-700">
                {t("Condition général d'utilisation")}
            </h5>

            <p className="mb-5 text-center text-sm">
                {t("Nous avons mis à jour les conditions d’utilisation.")}{" "}
                <br />
                {t("Veuillez accepter et continuer")}
            </p>

            <div className="relative flex justify-center">
                <CheckboxInput
                    checked={accepted}
                    onChange={e => setAccepted(e.target.checked)}
                >
                    {t("J'accepte les")}{" "}
                    <NavLink
                        to={PAGES.PRIVACY_POLICY}
                        onClick={closeModal}
                        className="text-primary hover:underline"
                    >
                        {t("condition général d'utilisation")}
                    </NavLink>
                </CheckboxInput>

                {error && (
                    <p className="absolute top-5 text-xs text-red-500">
                        {t("Veuillez accepter les conditions d'utilisation.")}
                    </p>
                )}
            </div>

            <div className="mt-10 flex items-center justify-center space-x-4">
                <Button variant="outline" withAuto onClick={closeModal}>
                    {t("Fermer")}
                </Button>

                <Button withAuto onClick={acceptCGU} loading={loading}>
                    {t("Accepter")}
                </Button>
            </div>
        </Modal>
    );
};

export default CGUUpdateModal;
