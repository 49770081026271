import Button from "@ui/Button.tsx";
import CheckboxInput from "@ui/CheckboxInput.tsx";
import Input from "@ui/Input.tsx";
import { Modal } from "@ui/Modal.tsx";
import TakePicture from "@ui/TakePicture.tsx";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import * as yup from "yup";

import XMarkIcon from "../../components/icons/XMarkIcon.tsx";
import InputField from "../../components/InputField.tsx";
import { AuthLayout } from "../../components/layouts";
import { CGUModal } from "../../components/modals";
import PhoneInput from "../../components/PhoneInput.tsx";
import {
    DEFAULT_COUNTRY_INDICATIVE,
    RESPONSE_STATUS,
    STORAGE
} from "../../constants";
import ENDPOINTS from "../../constants/endpoint.ts";
import PAGES from "../../constants/pages.ts";
import { loadAuthServiceHost, phoneIsValid, storage } from "../../helpers";
import useInitPhoneIndicative from "../../hooks/useInitPhoneIndicative.ts";
import useManageSchemaValidationError from "../../hooks/useManageSchemaValidationError.ts";
import useModal from "../../hooks/useModal.ts";
import usePageTitle from "../../hooks/usePageTitle.ts";
import useToast from "../../hooks/useToast.ts";
import rules from "../../schemas";

// import Agency from "./components/Agency.tsx";
import ConfirmOtp from "./components/ConfirmOtp.tsx";

const RegisterSchema = yup.object().shape({
    pin: rules.pin,
    confirm_pin: rules.confirmPin("pin"),
    phone_with_indicative: rules.phone,
    full_name: rules.fullName
});

const EmailSchema = yup.object().shape({
    email: rules.email
});

const PHONE_NUMBER_USE_LIKE_SECONDARY_NUMBER =
    "phone_number_with_indicative_has_already_registered_like_secondary_phone";
const KYC_REJECTED_OR_SUSPENDED =
    "phone_number_with_indicative_has_already_registered_but_rejected_or_suspended";

const LONG_DURATION = 10 * 1000;
const INDICATIVES_COUNTRIES_HAVE_AGENCY = ["+228", "+229", "+221", "+225"];

const Register = () => {
    const { t } = useTranslation();
    usePageTitle(t("Inscription"));

    const [loading, setLoading] = useState(false);
    const [indicative, setIndicative] = useState(DEFAULT_COUNTRY_INDICATIVE);
    const [phoneWithIndicative, setPhoneWithIndicative] = useState("");
    const [otherIndicative, setOtherIndicative] = useState("");
    const [photo, setPhoto] = useState<File | null>(null);
    const [selfie, setSelfie] = useState<File | null>(null);
    const [pin, setPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");
    const [fullName, setFullName] = useState("");
    const [condition, setCondition] = useState(false);
    const [step, setStep] = useState<1 | 2 | 3>(1);
    const [validationChoice] = useState<"me" | "you">("me");
    const [email, setEmail] = useState("");
    const [canValidateInAgency, setCanValidateInAgency] = useState(false);

    useEffect(() => {
        const confirmAccountTel = storage().getItem(
            STORAGE.CONFIRM_ACCOUNT_TEL
        );

        if (confirmAccountTel) {
            const [newIndicative, newPhone] = confirmAccountTel.split(" ");
            newIndicative && setIndicative(newIndicative);

            newPhone && setPhoneWithIndicative(newPhone);

            setStep(2);
        }
    }, []);

    useInitPhoneIndicative(setIndicative);
    const navigate = useNavigate();
    const { isOpen, closeModal, openModal } = useModal();
    const {
        isOpen: isCGUModalOpen,
        closeModal: closeCGUModal,
        openModal: openCGUModal
    } = useModal();
    const {
        isOpen: isEmailModalOpen,
        closeModal: closeEmailModal,
        openModal: openEmailModal
    } = useModal();
    const { successToast, errorToast } = useToast();
    const { showErrors, resetErrors, errors, setErrors } =
        useManageSchemaValidationError();

    const onSubmit = useCallback(
        (e?: FormEvent) => {
            e && e.preventDefault();
            const sendData = {
                pin: pin,
                confirm_pin: confirmPin,
                phone_with_indicative: phoneWithIndicative,
                full_name: fullName
            };

            if (indicative === "other" && otherIndicative.length < 2) {
                setErrors({
                    phone_with_indicative: t(
                        "Veuillez saisir un indicatif valide"
                    )
                });
            }

            RegisterSchema.validate(sendData, { abortEarly: false })
                .then(() => {
                    if (condition) {
                        const newIndicative =
                            indicative === "other"
                                ? otherIndicative
                                : indicative;
                        let phoneNumber = `${newIndicative}${phoneWithIndicative}`;

                        if (!phoneIsValid(phoneNumber)) {
                            setErrors({
                                ...errors,
                                phone_with_indicative: t("Invalid phone number")
                            });
                            return;
                        }

                        const formData = new FormData();
                        phoneNumber = `${newIndicative} ${phoneWithIndicative}`;
                        formData.append("phone_with_indicative", phoneNumber);
                        formData.append("full_name", fullName);
                        formData.append("pin", pin);
                        if (indicative === "other") {
                            formData.append("email", email);
                        }
                        setLoading(true);
                        Object.keys(errors).length && setErrors({});
                        loadAuthServiceHost();
                        window.axios
                            .post(ENDPOINTS.REGISTER, formData, {
                                headers: {
                                    "content-type": "multipart/form-data"
                                }
                            })
                            .then(() => {
                                resetErrors();
                                setPin("");
                                setConfirmPin("");
                                successToast(t("Succès de l'inscription"));
                                setStep(2);
                            })
                            .catch(error => {
                                resetErrors();
                                const status = error.response?.status;
                                const key = error.response?.data.data.key;
                                const errorsResponse =
                                    error.response?.data.errors;
                                if (
                                    status ===
                                    RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                                ) {
                                    setErrors(errorsResponse);
                                }

                                if (status === RESPONSE_STATUS.CONFLICT) {
                                    switch (key) {
                                        case PHONE_NUMBER_USE_LIKE_SECONDARY_NUMBER:
                                            errorToast(
                                                t("Echec de l'inscription"),
                                                t(
                                                    "Ce numéro est déjà utilisé comme numéro secondaire sur un autre compte"
                                                ),
                                                LONG_DURATION
                                            );
                                            setErrors(error => ({
                                                ...error,
                                                phone_with_indicative: t(
                                                    "Ce numéro est déjà utilisé comme numéro secondaire sur un autre compte"
                                                )
                                            }));
                                            break;
                                        case KYC_REJECTED_OR_SUSPENDED:
                                            openModal();
                                            break;
                                        default:
                                            errorToast(
                                                t(
                                                    "Cet utilisateur a déjà un compte"
                                                )
                                            );
                                            break;
                                    }
                                } else {
                                    errorToast(t("Echec de l'inscription"));
                                }
                            })
                            .finally(() => setLoading(false));
                    } else {
                        errorToast(
                            t("Echec de soumission du formulaire"),
                            t("Veuillez accepter les condition d'utilisation")
                        );
                    }
                })
                .catch(showErrors());
        },
        [
            condition,
            confirmPin,
            email,
            errorToast,
            errors,
            fullName,
            indicative,
            openModal,
            otherIndicative,
            phoneWithIndicative,
            pin,
            resetErrors,
            setErrors,
            showErrors,
            successToast,
            t
        ]
    );

    const handleSubmitEmail = useCallback(
        (e: FormEvent) => {
            e.preventDefault();

            EmailSchema.validate({ email }, { abortEarly: false })
                .then(() => {
                    onSubmit();
                    closeEmailModal();
                })
                .catch(showErrors());
        },
        [closeEmailModal, email, onSubmit, showErrors]
    );

    const handleCompleteProfile = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            setErrors({});
            if (!photo) {
                setErrors({
                    photo: t("validation.mixed.required", { path: "Photo" })
                });
                return;
            }

            if (!selfie) {
                setErrors({
                    selfie_photo: t("validation.mixed.required", {
                        path: "Selfie photo"
                    })
                });
                return;
            }

            const formData = new FormData();
            formData.append("selfie_photo_device", "web");
            formData.append(
                "identity_confirmation_mode",
                validationChoice === "me" ? "myself" : "in_agency"
            );

            formData.append(
                "phone_with_indicative",
                `${
                    indicative === "other" ? otherIndicative : indicative
                } ${phoneWithIndicative}`
            );

            if (photo) {
                formData.append("photo", photo);
            }

            if (selfie) {
                formData.append("selfie_photo", selfie);
            }

            setLoading(true);
            window.axios
                .post(ENDPOINTS.CONFIRM_IDENTIFY, formData)
                .then(() => {
                    successToast(
                        t("Confirmation réussie de l'identité"),
                        t(
                            "Connectez-vous pour profiter de toutes les fonctionnalités."
                        )
                    );
                    navigate(PAGES.LOGIN);
                })
                .catch(error => {
                    errorToast(t("Echec de confirmation de l'identité"));
                    if (
                        error.response?.status ===
                        RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                    ) {
                        setErrors(error.response?.data.errors);
                    }
                })
                .finally(() => setLoading(false));
        },
        [
            errorToast,
            indicative,
            navigate,
            otherIndicative,
            phoneWithIndicative,
            photo,
            selfie,
            setErrors,
            successToast,
            t,
            validationChoice
        ]
    );

    return (
        <AuthLayout>
            {step === 1 && (
                <>
                    <form className="space-y-5" onSubmit={onSubmit}>
                        <h4 className="mt-12 text-center text-xl font-bold text-gray-700">
                            {t("Créer un compte")}
                        </h4>

                        <InputField
                            id="full-name"
                            type="text"
                            placeholder={t("Nom et Prénom")}
                            value={fullName}
                            onChange={e => setFullName(e.target.value)}
                            error={errors.full_name}
                        />

                        <PhoneInput
                            id="phone-number"
                            placeholder={t("Numéro")}
                            indicative={indicative}
                            onIndicativeChange={value => setIndicative(value)}
                            value={phoneWithIndicative}
                            onChange={e =>
                                setPhoneWithIndicative(e.target.value)
                            }
                            error={errors.phone_with_indicative}
                            otherCountries={true}
                            otherIndicative={otherIndicative}
                            onOtherIndicativeChange={value => {
                                setOtherIndicative(value);
                            }}
                        />

                        <InputField
                            id="secret-code"
                            isPin
                            type="password"
                            inputMode="numeric"
                            placeholder={t("Code secret")}
                            value={pin}
                            onChange={e => setPin(e.target.value)}
                            error={errors.pin}
                        />

                        <InputField
                            id="confirm-secret-code"
                            isPin
                            type="password"
                            inputMode="numeric"
                            placeholder={t("Confirmer le code secret")}
                            value={confirmPin}
                            onChange={e => setConfirmPin(e.target.value)}
                            error={errors.confirm_pin}
                        />

                        {errors.email && (
                            <p className="text-center text-sm text-red-600">
                                {errors.email}
                            </p>
                        )}

                        <CheckboxInput
                            className="mt-4 justify-center"
                            checkboxClassName="cursor-pointer"
                            id="policy-condition"
                            name="check-condition"
                            checked={condition}
                            onChange={e => setCondition(e.target.checked)}
                        >
                            {t("J'accepte les")}{" "}
                            <button
                                className="text-primary hover:underline"
                                onClick={openCGUModal}
                            >
                                {t("conditions générales d’utilisation")}
                            </button>
                            .
                        </CheckboxInput>

                        {indicative === "other" ? (
                            <Button
                                loading={loading}
                                disabled={!condition}
                                type="button"
                                onClick={openEmailModal}
                            >
                                {t("Créer")}
                            </Button>
                        ) : (
                            <Button
                                loading={loading}
                                disabled={!condition}
                                type="submit"
                                data-cy="submit"
                            >
                                {t("Créer")}
                            </Button>
                        )}

                        <p className="text-center text-sm">
                            {t("Vous avez un compte ?")}{" "}
                            <NavLink
                                to={PAGES.LOGIN}
                                className="text-primary hover:underline"
                            >
                                {t("Se connecter")}
                            </NavLink>
                        </p>
                    </form>

                    <Modal
                        isOpen={isEmailModalOpen}
                        closeModal={closeEmailModal}
                    >
                        <form onSubmit={handleSubmitEmail}>
                            <h4 className="mb-4 mt-12 text-center text-xl font-bold text-gray-700">
                                {t("Votre email, s’il vous plaît 😊")}
                            </h4>

                            <p className="text-center text-xs lg:text-lg">
                                {t(
                                    "Veuillez fournir votre email pour recevoir le code de validation."
                                )}
                            </p>

                            <div className="my-7">
                                <Input
                                    placeholder="Email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    error={errors.email}
                                />

                                {errors.email && (
                                    <p className="text-xs text-red-500">
                                        {errors.email}
                                    </p>
                                )}
                            </div>

                            <Button type="submit">{t("Envoyer")}</Button>
                        </form>
                    </Modal>
                </>
            )}

            {step === 2 && (
                <ConfirmOtp
                    phone={
                        indicative === "other"
                            ? `${otherIndicative} ${phoneWithIndicative}`
                            : `${indicative} ${phoneWithIndicative}`
                    }
                    email={indicative === "other" ? email : undefined}
                    onSuccess={() => {
                        if (
                            INDICATIVES_COUNTRIES_HAVE_AGENCY.includes(
                                indicative
                            )
                        ) {
                            setCanValidateInAgency(true);
                        }
                        setStep(3);
                    }}
                />
            )}

            {step === 3 && (
                <form
                    className="mt-16 space-y-5"
                    onSubmit={handleCompleteProfile}
                >
                    <h4 className="mt-12 text-center text-xl font-bold text-gray-700">
                        {t("Plus qu’une étape💪🏾")}
                    </h4>

                    {/*{canValidateInAgency && (
                        <p className="text-xs text-center">
                            {t("Comment souhaitez vous valider votre compte")}
                        </p>
                    )}*/}

                    {/*{canValidateInAgency && (
                        <div className="grid grid-cols-2 gap-5">
                            <div
                                className={cn({
                                    "border rounded-md flex items-center": true,
                                    "space-x-3 cursor-pointer p-3": true,
                                    "border-primary": validationChoice === "me"
                                })}
                                onClick={() => setValidationChoice("me")}
                            >
                                <span className="border-2 rounded-full w-6 h-6 border-gray-200 flex items-center justify-center">
                                    <span
                                        className={cn({
                                            "h-3 w-3 inline-block": true,
                                            "rounded-full": true,
                                            "bg-primary":
                                                validationChoice === "me"
                                        })}
                                    />
                                </span>

                                <p className="text-[#374151] font-medium text-xs lg:text-base">
                                    {t("Par moi même")}
                                </p>
                            </div>

                            <div
                                className={cn({
                                    "border rounded-md flex items-center": true,
                                    "space-x-3 cursor-pointer p-3": true,
                                    "border-primary": validationChoice === "you"
                                })}
                                onClick={() => setValidationChoice("you")}
                            >
                                <span className="border-2 rounded-full w-6 h-6 border-gray-200 flex items-center justify-center">
                                    <span
                                        className={cn({
                                            "h-3 w-3 inline-block": true,
                                            "rounded-full": true,
                                            "bg-primary":
                                                validationChoice === "you"
                                        })}
                                    />
                                </span>

                                <p className="text-[#374151] font-medium text-xs lg:text-base">
                                    {t("Dans une agence")}
                                </p>
                            </div>
                        </div>
                    )}*/}

                    {(validationChoice === "me" || !canValidateInAgency) && (
                        <>
                            <TakePicture
                                required={true}
                                label={t("Photo carte d'identité/passeport")}
                                value={photo}
                                onPictureTake={file => setPhoto(file)}
                                errorMessage={errors.photo}
                            />

                            <TakePicture
                                required={true}
                                label={t(
                                    "Selfie avec carte d'identité/passeport"
                                )}
                                value={selfie}
                                cameraType="user"
                                onPictureTake={file => setSelfie(file)}
                                errorMessage={errors.selfie_photo}
                            />

                            <Button
                                loading={loading}
                                disabled={loading}
                                type="submit"
                            >
                                {t("Soumettre")}
                            </Button>
                        </>
                    )}

                    {/*{validationChoice === "you" && canValidateInAgency && (
                        <Agency indicative={indicative} />
                    )}*/}
                </form>
            )}

            <Modal
                isOpen={isOpen}
                closeModal={closeModal}
                className="custom-class"
                hiddeCloseIcon
            >
                <div className="flex justify-center">
                    <span className="inline-block rounded-full bg-red-500 p-1">
                        <XMarkIcon className="h-16 w-16 text-white" />
                    </span>
                </div>

                <p className="my-5 text-center text-sm">
                    {t(
                        "Ce numéro est associé à un compte qui a été suspendu. Veuillez contacter le service client pour plus d’information ou assistance."
                    )}
                </p>

                <Button withAuto className="mx-auto px-6" onClick={closeModal}>
                    {t("D'accord")}
                </Button>
            </Modal>

            <CGUModal isOpen={isCGUModalOpen} closeModal={closeCGUModal} />
        </AuthLayout>
    );
};

export default Register;
