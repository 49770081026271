import { useTranslation } from "react-i18next";

import useAuth from "../../../hooks/useAuth.ts";

import OtherAccount from "./OtherAccount.tsx";

const MyNumbers = () => {
    const { kycActionData } = useAuth();
    const { t } = useTranslation();

    return (
        <>
            {kycActionData.transferValidate ? (
                <OtherAccount accountType="numbers" />
            ) : (
                <p className="text-sm">
                    {t(
                        "Votre compte n'est pas validé, vous ne pouvez ajouter de numéro secondaire."
                    )}
                </p>
            )}
        </>
    );
};

export default MyNumbers;
