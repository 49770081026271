import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastPropsType } from "@ui/Toast.tsx";

import { STORAGE } from "../constants";
import { storage } from "../helpers";
import { ToastPositionEnum, ToastTypeEnum } from "../types";

interface UiStoreType {
    logoutLoading: boolean;
    redirectReviewKyc: boolean;
    showTransferLimitModal: boolean;
    showTransferKycValidateMessage: boolean;
    showTransferCardKycValidateMessage: boolean;
    toast: ToastPropsType;
    transfer: {
        show: boolean;
    };
    suspendModal: {
        show: boolean;
        reason: string;
    };
    secondaryNumberData: {
        phone: string;
        indicative: string;
    } | null;
}

const initialState: UiStoreType = {
    logoutLoading: false,
    redirectReviewKyc: false,
    showTransferLimitModal: false,
    showTransferKycValidateMessage: true,
    showTransferCardKycValidateMessage: true,
    toast: {
        duration: 4000,
        position: ToastPositionEnum.topRight,
        type: ToastTypeEnum.success,
        title: "",
        description: "",
        show: false
    },
    transfer: {
        show: false
    },
    suspendModal: {
        show: false,
        reason: ""
    },
    secondaryNumberData: null
};

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        toggleToast: (state, action: PayloadAction<ToastPropsType>) => {
            if (action.payload.duration)
                state.toast.duration = action.payload.duration;
            if (action.payload.position)
                state.toast.position = action.payload.position;
            if (action.payload.type) state.toast.type = action.payload.type;
            if (action.payload.description)
                state.toast.description = action.payload.description;
            else state.toast.description = "";
            state.toast.title = action.payload.title;
            state.toast.show = action.payload.show;
        },
        closeToast: state => {
            state.toast.show = false;
        },
        setLogoutLoading: (state, action: PayloadAction<boolean>) => {
            state.logoutLoading = action.payload;
        },
        openTransfer: state => {
            state.transfer.show = true;
        },
        closeTransfer: state => {
            state.transfer.show = false;
        },
        showSuspendModal: (state, action: PayloadAction<string>) => {
            state.suspendModal.reason = action.payload;
            state.suspendModal.show = true;
        },
        closeSuspendModal: state => {
            state.suspendModal.show = false;
        },
        setStoreRedirectReviewKyc: (state, action: PayloadAction<boolean>) => {
            state.redirectReviewKyc = action.payload;
        },
        setStoreShowTransferLimitModal: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.showTransferLimitModal = action.payload;
        },
        setStoreShowTransferKycValidateMessage: (
            state,
            action: PayloadAction<boolean>
        ) => {
            if (!action.payload) {
                storage().setItem(
                    STORAGE.HIDE_TRANSFER_KYC_VALIDATED_MESSAGE,
                    JSON.stringify(true)
                );
            } else {
                storage().removeItem(
                    STORAGE.HIDE_TRANSFER_KYC_VALIDATED_MESSAGE
                );
            }
            state.showTransferKycValidateMessage = action.payload;
        },
        setStoreTransferCardKycValidateMessage: (
            state,
            action: PayloadAction<boolean>
        ) => {
            if (!action.payload) {
                storage().setItem(
                    STORAGE.HIDE_TRANSFER_CARD_KYC_VALIDATED_MESSAGE,
                    JSON.stringify(true)
                );
            } else {
                storage().removeItem(
                    STORAGE.HIDE_TRANSFER_CARD_KYC_VALIDATED_MESSAGE
                );
            }
            state.showTransferCardKycValidateMessage = action.payload;
        },
        setStoreSecondaryNumberData: (
            state,
            action: PayloadAction<null | { phone: string; indicative: string }>
        ) => {
            state.secondaryNumberData = action.payload;
        }
    }
});

export const {
    toggleToast,
    closeToast,
    setLogoutLoading,
    openTransfer,
    closeTransfer,
    showSuspendModal,
    closeSuspendModal,
    setStoreRedirectReviewKyc,
    setStoreShowTransferLimitModal,
    setStoreShowTransferKycValidateMessage,
    setStoreTransferCardKycValidateMessage,
    setStoreSecondaryNumberData
} = uiSlice.actions;

const uiReducer = uiSlice.reducer;

export default uiReducer;
