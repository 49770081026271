import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { cn } from "../../helpers";
import SettingIcon from "../icons/SettingIcon.tsx";

const SettingLink = () => {
    const { t } = useTranslation();
    return (
        <NavLink
            className={() => {
                return cn({
                    "inline px-4 py-4": true,
                    "items-center justify-center lg:flex lg:flex-col lg:px-12 lg:py-3":
                        true
                });
            }}
            to={PAGES.SETTING}
        >
            <SettingIcon size="lg" />
            <span className="hidden text-sm">{t("Paramètres")}</span>
        </NavLink>
    );
};

export default SettingLink;
