import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { NotificationType } from "../components/notifications";
import config from "../config";
import { getWalletFilePath } from "../helpers";
import env from "../helpers/env.ts";
import { updateAuthUser } from "../store/authSlice.ts";
import { addNotification } from "../store/notificationSlice.ts";
import { showSuspendModal } from "../store/uiSlice.ts";
import {
    AccountNotificationType,
    ChannelType,
    KycActionDataType
} from "../types";

import useAuth from "./useAuth.ts";
import useFetchCountryChannels from "./useFetchCountryChannels.ts";
import useFetchNotifications from "./useFetchNotifications.tsx";
import useLogout from "./useLogout.ts";
import useManageTransfer from "./useManageTransfer.ts";
import useManageWalletChannels from "./useManageWalletChannels.ts";
import useTransferModal from "./useTransferModal.ts";

const pusher = new window.Pusher(env("PUSHER_KEY"), {
    cluster: "eu"
});

interface EventParamType {
    defaultChannel: string;
    notification: NotificationType;
}

const KYC_ACTIONS = {
    suspendAccount: "account-suspended"
};

export interface NotificationDataType {
    notification: Notification;
    notificationEvent: string;
}

export interface Notification {
    pay_channel: ChannelType;
    pay_channel_type: "in" | "out";
    operation: "enable" | "disable";
}

const useSubscribeChannels = () => {
    const ref = useRef(false);
    const secondRef = useRef(false);

    const { t } = useTranslation();
    const { isLogin, user, reFetchAuthUser } = useAuth();
    const dispatch = useDispatch();
    const logoutUser = useLogout();
    const { fetchTransfer, filter: transferFilter } = useManageTransfer();
    const { updateTransfer, transfer } = useTransferModal();
    const { fetchNotifications } = useFetchNotifications();
    const { fetchCountryChannel } = useFetchCountryChannels();
    const { getCountryById, payoutChannels, payinChannels } =
        useManageWalletChannels();

    const updateUserBuyAccountNotificationData = useCallback(
        (accountNotificationData: AccountNotificationType) => {
            const account = accountNotificationData
                ? { ...accountNotificationData }
                : undefined;

            const user = accountNotificationData.user;

            if (account) delete account.user;

            dispatch(
                updateAuthUser({
                    user,
                    account
                })
            );
        },
        [dispatch]
    );

    useEffect(() => {
        if (
            !secondRef.current &&
            payinChannels.length &&
            payoutChannels.length
        ) {
            secondRef.current = true;
            const secondChannel = pusher.subscribe("notif.channel.event");

            secondChannel.bind(
                "notif-channel-event",
                ({ notification }: NotificationDataType) => {
                    function ShowNotification() {
                        const slug =
                            notification.pay_channel.slug
                                .toString()
                                .toLowerCase() || "";

                        const walletPath = getWalletFilePath(slug);

                        let message = "";

                        const country = getCountryById(
                            notification.pay_channel_type === "in",
                            notification.pay_channel.country_id
                        );

                        const messageParameter = {
                            wallet: notification.pay_channel.name,
                            pay: t(
                                notification.pay_channel_type === "out"
                                    ? "reception"
                                    : "émission"
                            ),
                            country: country?.label || ""
                        };

                        if (notification.operation === "enable") {
                            message = t(
                                ":wallet :country est à nouveau disponible en :pay",
                                messageParameter
                            );
                        } else {
                            message = t(
                                ":wallet :country est indisponible en :pay",
                                messageParameter
                            );
                        }
                        new Notification(t("Alert"), {
                            body: message,
                            icon: `${config.url}${walletPath}`,
                            image: `${config.url}/icon-512x512.png`
                        });

                        fetchCountryChannel();
                        if (isLogin) fetchNotifications();
                    }

                    if ("Notification" in window) {
                        if (Notification.permission === "granted") {
                            ShowNotification();
                        } else {
                            Notification.requestPermission().then(
                                permissions => {
                                    if (permissions === "granted") {
                                        ShowNotification();
                                    }
                                }
                            );
                        }
                    }
                }
            );
        }
    }, [
        fetchCountryChannel,
        fetchNotifications,
        getCountryById,
        isLogin,
        payinChannels.length,
        payoutChannels.length,
        t
    ]);

    useEffect(() => {
        if (!ref.current && user?.id) {
            ref.current = true;

            const channel = pusher.subscribe(`notif.user.${user.id}`);

            channel.bind("notif-event", (data: EventParamType) => {
                if (isLogin) {
                    updateTransfer(data.notification.data.id);

                    dispatch(addNotification(data.notification));

                    fetchTransfer(
                        transferFilter.pageIndex,
                        transferFilter.pageSize,
                        transferFilter.search
                    );
                }
            });

            channel.bind(
                "action-kyc-notification-event",
                ({
                    notification: data
                }: {
                    notification: KycActionDataType;
                }) => {
                    if (isLogin && data) {
                        if (data.key === KYC_ACTIONS.suspendAccount) {
                            logoutUser(t("Succès de la déconnexion"), () => {
                                dispatch(
                                    showSuspendModal(
                                        data.account.suspension_reason || ""
                                    )
                                );
                            });
                        } else {
                            reFetchAuthUser();
                        }
                    }
                }
            );

            channel.bind("action-transfer-card-enable-event", () => {
                if (isLogin) {
                    fetchNotifications();
                    reFetchAuthUser();
                }
            });

            channel.bind("action-transfer-card-disable-event", () => {
                if (isLogin) {
                    fetchNotifications();
                    reFetchAuthUser();
                }
            });
        }
    }, [
        dispatch,
        fetchCountryChannel,
        fetchNotifications,
        fetchTransfer,
        getCountryById,
        isLogin,
        logoutUser,
        reFetchAuthUser,
        t,
        transfer,
        transferFilter.pageIndex,
        transferFilter.pageSize,
        transferFilter.search,
        updateTransfer,
        updateUserBuyAccountNotificationData,
        user?.id
    ]);
};

export default useSubscribeChannels;
