import config from "../config";
import RemoveAccountButton from "../pages/settings/components/RemoveAccountButton.tsx";

const CloseAccount = () => {
    return (
        <div className="flex flex-col items-center pb-3 text-xs">
            <p className="text-[#374151]">{config.name}</p>

            <p className="text-[#374151]">Version {config.version}</p>

            <RemoveAccountButton />
        </div>
    );
};

export default CloseAccount;
