const ENDPOINTS = {
    REGISTER: "/register",
    CONFIRM_IDENTIFY: "/confirm-identity",
    LOGIN: "/login",
    FORGOT_PIN: "/send-temporary-code",
    VALIDATE_PIN: "/submit-temporary-code",
    RESET_PIN: "/reset-pin",
    LOGOUT: "/logout",
    UPDATE_PIN: "/update-pin",
    VERIFY_PIN: "/verify-pin",
    VERIFY_OTP: "/check-temporary-code",
    DELETE_USER: "/delete-user",
    CHANNELS: "/channel",
    GET_TRANSFER_FEES: "/transfer/fees",
    CREATE_TRANSFER: "/transfer",
    CONFIRM_TRANSFER: "/transfer/confirm",
    LIST_TRANSFER: "/transfer",
    TRANSFER: "/transfer",
    TRANSFER_DETAIL: "/transfer/:id",
    VALIDATE_ACCOUNT: "/validate-account",
    RESEND_CODE: "/send-temporary-code",
    RESEND_CODE_TO_EMAIL: "/send-temporary-code-to-email",
    NOTIFICATIONS: "/notification",
    READ_NOTIFICATION: "/notification/mark-as-read-or-as-unread/:id/READ",
    REWIND_TRANSFER: "/transfer/remote/:id",
    RETRY_TRANSFER: "/transfer/update/:id/retry",
    CORRECT_KYC: "/update-kyc",
    ADD_SECONDARY_NUMBER: "/transfer-phone-number",
    VALIDATE_SECONDARY_NUMBER_OTP: "/transfer-phone-number/validate",
    SEND_TEMPORARY_CODE_TO_SECONDARY_NUMBER:
        "/transfer-phone-number/send-temporary-code",
    REMOVE_SECONDARY_NUMBER: "/transfer-phone-number/:id",
    ME: "/me",
    CHECK_PRIVACY_POLICY: "/check-terms-service",
    ACCEPT_PRIVACY_POLICY: "/accept-terms-service",
    ADD_CARD: "/transfer-card",
    REMOVE_CARD: "/transfer-card/:id"
};

export default ENDPOINTS;
