const TransferISkeletonItem = () => {
    return (
        <>
            <div className="flex animate-pulse cursor-pointer items-end justify-between text-sm text-gray-700 text-transparent">
                <div>
                    <p className="mb-4 h-2 w-[175px] rounded bg-slate-200" />
                    <p className="h-2 w-[125px] rounded bg-slate-200" />
                </div>

                <div className="flex flex-col items-end text-end">
                    <p className="mb-4 h-2 w-[75px] rounded bg-slate-200" />
                    <p className="h-2 w-[130px] rounded bg-slate-200" />
                </div>
            </div>

            <hr className="my-4" />
        </>
    );
};

export default TransferISkeletonItem;
