import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ChevronIcon from "../../../components/icons/ChevronIcon.tsx";
import { cn } from "../../../helpers";
import useAuth from "../../../hooks/useAuth.ts";
import useLogout from "../../../hooks/useLogout.ts";
import { RootState } from "../../../store";

import CGUButton from "./CGUButton.tsx";
// import FoundAgencyButton from "./FoundAgencyButton.tsx";
import InviteFriendButton from "./InviteFriendButton.tsx";
import SupportClientButton from "./SupportClientButton.tsx";
import UpdateSecretCodeButton from "./UpdateSecretCodeButton.tsx";

const General = () => {
    const { t } = useTranslation();
    const logout = useLogout();
    const { logoutLoading } = useSelector((state: RootState) => state.ui);
    const { isLogin } = useAuth();

    return (
        <>
            {/*<FoundAgencyButton />*/}

            <SupportClientButton />

            <UpdateSecretCodeButton />

            <InviteFriendButton />

            <CGUButton />

            {isLogin && (
                <button
                    className={cn({
                        "flex items-center justify-between": true,
                        "cursor-pointer pb-4": true,
                        "cursor-not-allowed": logoutLoading
                    })}
                    onClick={() => {
                        if (logoutLoading) return;
                        logout(t("Succès de la déconnexion"));
                    }}
                >
                    <div className="text-start text-red-500 focus:outline-none">
                        <h4 className="mb-1 font-bold">
                            {t("Se déconnecter")}
                        </h4>

                        <p className="text-sm">{t("Quitter l’application")}</p>
                    </div>

                    <ChevronIcon className="hidden h-4 w-4 -rotate-90 text-[#374151]" />
                </button>
            )}
        </>
    );
};

export default General;
