// import useGpsPosition from "../hooks/useGpsPosition.ts";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BsWhatsapp from "../../../components/icons/BsWhatsapp.tsx";
import LuPhoneCallIcon from "../../../components/icons/LuPhoneCallIcon.tsx";

const SettingServiceClient = () => {
    // const { position } = useGpsPosition();
    const { t } = useTranslation();

    const savContact = useMemo(() => {
        return {
            whatsapp: {
                value: "+221 771902641",
                label: "+221 771902641"
            },
            call: {
                value: "+221 771902641",
                label: "+221 771902641"
            }
        };

        /*if (!position?.countryCode) return value;

        if (["sn", "ml", "bf"].includes(position?.countryCode.toLowerCase())) {
            return value;
        }

        if ("ci" === position?.countryCode) {
            return {
                value: "+2250501667575",
                label: "+225 0501667575"
            };
        }

        if (["bj", "tg"].includes(position?.countryCode.toLowerCase())) {
            return {
                value: "+22991317777",
                label: "+229 91317777"
            };
        }

        return value;*/
    }, []);

    return (
        <div className="space-y-8">
            <p className="text-xs text-gray-600">
                {t("Contactez-nous au +221 77 190 26 41 sur")}
            </p>

            <div className="flex w-full items-center space-x-5 text-sm lg:w-3/5">
                <a
                    href={`https://wa.me/${savContact.whatsapp.value.replaceAll(
                        " ",
                        ""
                    )}`}
                    target="_blank"
                    className="flex items-center justify-center space-x-4 rounded-md border border-[#22C55E] px-4 py-3"
                    rel="noreferrer"
                >
                    <BsWhatsapp className="fill-[#0B7226]" />
                    <span className="text-[#111827]">WhatsApp</span>
                </a>

                <a
                    href={`tel:${savContact.call.value}`}
                    className="flex items-center justify-center space-x-4 rounded-md border px-4 py-3 text-[#374151]"
                >
                    <LuPhoneCallIcon />
                    <span>{t("Appel direct")}</span>
                </a>
            </div>
        </div>
    );
};

export default SettingServiceClient;
