import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownItems
} from "@ui/Dropdown.tsx";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { COUNTRY } from "../constants";
import { cn } from "../helpers";

import MatchCountryIcon from "./MatchCountryIcon.tsx";
import SecondSearchInput from "./SecondSearchInput.tsx";

interface Props {
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    otherCountries?: boolean;
}

const InputIndicativeDropdown = (props: Props) => {
    const [searchInput, setSearchInput] = useState("");
    const { otherCountries = false } = props;
    const { t } = useTranslation();

    const filterBySearchInput = () => {
        if (!searchInput.length) {
            return COUNTRY;
        }
        return COUNTRY.filter(item => {
            const name = item.name.toLowerCase();
            const search = searchInput.toLowerCase();
            return (
                item.indicative.includes(searchInput) || name.includes(search)
            );
        });
    };

    return (
        <>
            <Dropdown as={Fragment}>
                <DropdownButton
                    as="button"
                    type="button"
                    className={cn({
                        "absolute text-sm text-gray-400": true,
                        "left-0 top-0 px-2": true,
                        "ml-[1px] mt-[1px] h-[calc(100%-2px)]": true,
                        "flex items-center space-x-1.5": true,
                        "rounded-l-[5px]": true,
                        "bg-gray-100": !props.disabled
                    })}
                    disabled={props.disabled}
                    data-testid="dropdown-button"
                >
                    {!props.disabled && (
                        <MatchCountryIcon
                            indicative={props.value || ""}
                            className={cn({
                                "h-5 w-5": true,
                                "mx-3.5": props.value === "other"
                            })}
                        />
                    )}

                    <span>{props.value !== "other" && props.value}</span>
                </DropdownButton>

                <DropdownItems className="absolute z-[9] mt-2 w-full rounded-md border border-gray-200 bg-white p-3 text-sm shadow-sm focus:outline-none lg:text-base">
                    <SecondSearchInput
                        value={searchInput}
                        onChange={e => setSearchInput(e.target.value)}
                    />

                    <ul
                        role="options"
                        className="max-h-[14rem] overflow-y-auto"
                    >
                        {filterBySearchInput().map((item, index) => (
                            <DropdownItem key={index}>
                                <li
                                    data-testid="dropdown-item"
                                    className={cn(
                                        "block rounded px-2 py-2 text-gray-600",
                                        "hover:bg-gray-200",
                                        "cursor-pointer select-none truncate",
                                        "transition duration-200",
                                        "flex items-center space-x-2 lg:space-x-4"
                                    )}
                                    onClick={() => {
                                        props.onChange &&
                                            props.onChange(item.indicative);
                                    }}
                                >
                                    <MatchCountryIcon
                                        indicative={item.indicative}
                                        className="h-5 w-5"
                                    />
                                    <span>
                                        {item.name} ({item.indicative})
                                    </span>
                                </li>
                            </DropdownItem>
                        ))}

                        {otherCountries && (
                            <DropdownItem>
                                <li
                                    data-testid="dropdown-item"
                                    className={cn(
                                        "block rounded px-2 py-2 text-gray-600",
                                        "hover:bg-gray-200",
                                        "cursor-pointer select-none truncate",
                                        "transition duration-200",
                                        "flex items-center space-x-2 lg:space-x-4"
                                    )}
                                    onClick={() => {
                                        props.onChange &&
                                            props.onChange("other");
                                    }}
                                >
                                    <span className="flex h-5 w-5 items-center justify-center text-lg">
                                        🌍
                                    </span>

                                    <span>{t("Autres")}</span>
                                </li>
                            </DropdownItem>
                        )}
                    </ul>
                </DropdownItems>
            </Dropdown>
        </>
    );
};

export default InputIndicativeDropdown;
