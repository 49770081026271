import { cn } from "../../helpers";

interface Props {
    className?: string;
}

const SnIcon = (props: Props) => {
    return (
        <svg
            className={cn("h-6 w-6", props.className)}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width="256"
            height="256"
            viewBox="0 0 256 256"
            role="img"
        >
            <g fill="none" strokeMiterlimit="10" strokeWidth="0">
                <path
                    fill="#BC0000"
                    d="M170.007 8.631v238.454c49.08-17.352 84.257-64.119 84.3-119.133v-.19c-.043-55.012-35.22-101.782-84.3-119.13"
                ></path>
                <path
                    fill="#0B7226"
                    d="M85.707 247.082V8.632c-49.108 17.362-84.3 64.171-84.3 119.225s35.192 101.862 84.3 119.225"
                ></path>
                <path
                    fill="#FF0"
                    d="M85.707 247.082a126.2 126.2 0 0 0 42.15 7.225c14.783 0 28.962-2.56 42.15-7.225V8.628a126.2 126.2 0 0 0-42.15-7.221 126.2 126.2 0 0 0-42.15 7.224z"
                ></path>
                <path
                    fill="#0B7226"
                    d="M135.741 119.963h27.24l-21.504 16.54 7.885 26.324-21.505-16.548-21.505 15.792 7.885-25.57-21.505-16.538h26.523l8.602-27.077z"
                ></path>
            </g>
        </svg>
    );
};

export default SnIcon;
