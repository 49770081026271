import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CloseAccount from "../../components/CloseAccount.tsx";
import BackIcon from "../../components/icons/BackIcon.tsx";
import Main from "../../components/layouts/Main.tsx";
import { cn } from "../../helpers";
import useAuth from "../../hooks/useAuth.ts";
import usePageTitle from "../../hooks/usePageTitle.ts";

import General from "./components/General.tsx";
import MyCards from "./components/MyCards.tsx";
import MyCeilings from "./components/MyCeilings.tsx";
import MyNumbers from "./components/MyNumbers.tsx";
import Profile from "./components/Profile.tsx";

interface TabButtonProps {
    onClick: () => void;
    isActive: boolean;
    children: ReactNode;
}

const TabButton = (props: TabButtonProps) => {
    const { isActive, onClick, children } = props;

    return (
        <button
            className={cn({
                "mb-[-1px] border-b-2 border-transparent pb-2 text-[#6B7280]":
                    true,
                "border-primary text-primary": isActive
            })}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

const Setting = () => {
    const [currentTab, setCurrentTab] = useState<1 | 2 | 3 | 4 | 5>(1);

    const { t } = useTranslation();
    usePageTitle(t("Paramètres"));
    const navigate = useNavigate();
    const { isLogin } = useAuth();

    return (
        <Main info={t("headerTitle1")} showAlert={false}>
            <div
                className={cn(
                    "min-h-[calc(100vh-105px)] bg-white lg:min-h-[calc(100vh-130px)]",
                    "flex flex-col justify-between rounded-lg"
                )}
            >
                <div className="max-w-2xl p-4 md:mx-auto md:w-2/3 md:px-10">
                    <div className="mb-4 flex items-center">
                        <button
                            className="border-transparent pr-6 focus:outline-none"
                            onClick={() => navigate(-1)}
                        >
                            <BackIcon />
                        </button>

                        <h3 className="flex items-center text-lg  font-extrabold md:text-2xl">
                            <span>{t("Paramètre du compte")}</span>
                        </h3>
                    </div>

                    <div className="mb-6 space-x-[13px] border-b">
                        <TabButton
                            onClick={() => setCurrentTab(1)}
                            isActive={currentTab === 1}
                        >
                            {t("Général")}
                        </TabButton>

                        {isLogin && (
                            <>
                                <TabButton
                                    onClick={() => setCurrentTab(2)}
                                    isActive={currentTab === 2}
                                >
                                    {t("Numéros")}
                                </TabButton>

                                <TabButton
                                    onClick={() => setCurrentTab(3)}
                                    isActive={currentTab === 3}
                                >
                                    {t("Cartes")}
                                </TabButton>

                                <TabButton
                                    onClick={() => setCurrentTab(4)}
                                    isActive={currentTab === 4}
                                >
                                    {t("Plafonds")}
                                </TabButton>

                                <TabButton
                                    onClick={() => setCurrentTab(5)}
                                    isActive={currentTab === 5}
                                >
                                    {t("Profil")}
                                </TabButton>
                            </>
                        )}
                    </div>

                    {currentTab === 1 && <General />}

                    {currentTab === 2 && <MyNumbers />}

                    {currentTab === 3 && <MyCards />}

                    {currentTab === 4 && <MyCeilings />}

                    {currentTab === 5 && <Profile />}
                </div>

                <CloseAccount />
            </div>
        </Main>
    );
};

export default Setting;
