import Input from "@ui/Input.tsx";
import { ChangeEvent, useCallback, useMemo } from "react";

import { cn, removeThousandSeparator, thousandFormater } from "../helpers";
import { InputProps } from "../types";

interface Props extends InputProps {
    onValueChange?: (value: string) => void;
    currency?: string;
}

const AmountInput = (props: Props) => {
    const {
        error,
        className,
        value,
        onValueChange,
        inputMode = "numeric",
        ...rest
    } = props;

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (onValueChange) {
                const inputValue = e.target.value;
                if (inputValue.length) {
                    const formatInputValue = inputValue.replace(/[^0-9]/g, "");
                    onValueChange(
                        removeThousandSeparator(
                            formatInputValue,
                            ","
                        ).toString()
                    );
                } else {
                    onValueChange("");
                }
            }
        },
        [onValueChange]
    );

    const formatValue = useMemo(() => {
        if (!value) return "";
        if (typeof value !== "string") return "";

        return thousandFormater(parseInt(value), ",");
    }, [value]);

    return (
        <div className={cn("relative", className)}>
            <Input
                type="text"
                className="pr-24"
                inputMode={inputMode}
                {...rest}
                value={formatValue}
                onChange={handleInputChange}
            />

            <div className="absolute right-5 top-4 inline-block text-sm text-gray-400">
                {props.currency || "FCFA"}
            </div>

            {props.error && (
                <p
                    className="mt-0.5 text-xs text-red-500"
                    data-cy={`error-${rest.name || rest.id}`}
                >
                    {Array.isArray(error) ? (
                        <>{error[0]}</>
                    ) : typeof error === "string" ? (
                        error
                    ) : (
                        error?.message
                    )}
                </p>
            )}
        </div>
    );
};

export default AmountInput;
