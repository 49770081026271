import { cn } from "../../helpers";

interface Props {
    className?: string;
}

const MlIcon = (props: Props) => {
    return (
        <svg
            className={cn("h-6 w-6", props.className)}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            id="Layer_1"
            width="200"
            height="200"
            fill="#000"
            version="1.1"
            viewBox="0 0 512 512"
            role="img"
        >
            <g id="SVGRepo_iconCarrier">
                <path
                    fill="#FFDA44"
                    d="M345.043 15.923C317.31 5.633 287.314 0 256 0s-61.31 5.633-89.043 15.923L144.696 256l22.261 240.077C194.69 506.368 224.686 512 256 512s61.31-5.632 89.043-15.923L367.304 256z"
                ></path>
                <path
                    fill="#D80027"
                    d="M512 256c0-110.07-69.472-203.906-166.957-240.076v480.155C442.528 459.906 512 366.072 512 256"
                ></path>
                <path
                    fill="#6DA544"
                    d="M0 256c0 110.072 69.472 203.906 166.957 240.078V15.924C69.472 52.094 0 145.93 0 256"
                ></path>
            </g>
        </svg>
    );
};

export default MlIcon;
