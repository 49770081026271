import Button from "@ui/Button.tsx";
import TakePicture from "@ui/TakePicture.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import InputField from "../components/InputField.tsx";
import { Main } from "../components/layouts";
import { RESPONSE_STATUS } from "../constants";
import ENDPOINTS from "../constants/endpoint.ts";
import PAGES from "../constants/pages.ts";
import { cn, loadAuthServiceHost } from "../helpers";
import useAuth from "../hooks/useAuth.ts";
import usePageTitle from "../hooks/usePageTitle.ts";
import useToast from "../hooks/useToast.ts";
import { updateAuthUser } from "../store/authSlice.ts";

const TRANSFER_KYC_STATUS_NOT_IN_TO_REVIEW =
    "transfer_kyc_status_not_in_to_review";
const CARD_KYC_STATUS_NOT_IN_TO_REVIEW = "card_kyc_status_not_in_to_review";
const TRANSFER_CARD_KYC_STATUS_NOT_IN_TO_REVIEW =
    "transfer_kyc_status_and_card_kyc_status_not_in_to_review";
const CARD_KYC_NOT_IN_TO_REVIEW_OR_TO_VALIDATE =
    "card_kyc_status_not_in_to_review_to_review_or_to_validate";

const CorrectKyc = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(false);
    const [fullName, setFullName] = useState("");
    const [photo, setPhoto] = useState<File | null>(null);
    const [selfie, setSelfie] = useState<File | null>(null);
    const [inputComments, setInputComment] = useState({
        fullName: "",
        photo: "",
        selfiePhoto: ""
    });
    const [errorMessage, setErrorMessage] = useState("");

    const dispatch = useDispatch();
    const { user, kycActionData } = useAuth();
    const { successToast, errorToast } = useToast();
    usePageTitle(t("Correction"));

    useEffect(() => {
        if (user?.account?.comments) {
            const parseData = JSON.parse(user.account.comments);

            setFullName(parseData?.full_name?.value || user.full_name);

            setInputComment({
                fullName: parseData?.full_name?.comment || "",
                photo: parseData?.photo?.comment || "",
                selfiePhoto: parseData?.selfie_photo?.comment || ""
            });
        } else {
            setFullName(user.full_name ?? "");
        }
    }, [user?.account, user?.full_name]);

    const disableFields = useMemo(() => {
        let selfieDisable =
            !inputComments.selfiePhoto || kycActionData.cardValidate;
        if (
            !kycActionData.oneActionIsVerify &&
            !kycActionData.cardSubmitReview
        ) {
            selfieDisable = false;
        }

        let fullNameDisable =
            !inputComments.fullName || kycActionData.transferValidate;

        let photoDisable =
            (!inputComments.photo || kycActionData.transferValidate) &&
            !!user.photo;

        if (kycActionData.transferValidate) {
            fullNameDisable = true;
            photoDisable = true;
        }

        return {
            fullName: fullNameDisable,
            photo: photoDisable,
            selfie: selfieDisable
        };
    }, [
        inputComments.fullName,
        inputComments.photo,
        inputComments.selfiePhoto,
        kycActionData.cardSubmitReview,
        kycActionData.cardValidate,
        kycActionData.oneActionIsVerify,
        kycActionData.transferValidate,
        user.photo
    ]);

    const disableSubmitButton = useMemo(() => {
        return !(
            (!!fullName || disableFields.fullName) &&
            (!!photo || disableFields.photo) &&
            (!!selfie || disableFields.selfie)
        );
    }, [
        disableFields.fullName,
        disableFields.photo,
        disableFields.selfie,
        fullName,
        photo,
        selfie
    ]);

    const handleSubmit = useCallback(() => {
        if (!disableSubmitButton) {
            setLoading(true);
            loadAuthServiceHost();
            const formData = new FormData();
            let kycType = "";

            if (fullName || photo) {
                kycType = "transfer_kyc";
            }

            if (selfie && !fullName && !photo) {
                kycType = "card_kyc";
            }

            if (selfie && (fullName || photo)) {
                kycType = "transfer_and_card_kyc";
            }

            if (kycActionData.transferValidate && selfie) {
                kycType = "card_kyc";
            }

            if (kycActionData.cardValidate && (fullName || photo)) {
                kycType = "transfer_kyc";
            }

            if (
                !kycActionData.transferValidate &&
                !kycActionData.cardValidate
            ) {
                if (selfie && (fullName || photo)) {
                    kycType = "transfer_and_card_kyc";
                }

                if (selfie && disableFields.fullName && disableFields.photo) {
                    kycType = "card_kyc";
                }

                if (disableFields.selfie && (fullName || photo)) {
                    kycType = "transfer_kyc";
                }
            }

            if (!disableFields.fullName) {
                formData.append("full_name", fullName);
            }

            formData.append("selfie_photo_device", "web");
            formData.append("kyc_type", kycType);

            if (photo) {
                formData.append("photo", photo);
            }

            if (selfie) {
                formData.append("selfie_photo", selfie);
            }
            Object.keys(errors).length && setErrors({});
            errorMessage.length && setErrorMessage("");

            window.axios
                .post(ENDPOINTS.CORRECT_KYC, formData, {
                    headers: { "content-type": "multipart/form-data" }
                })
                .then(response => {
                    dispatch(updateAuthUser({ user: response.data.data }));
                    successToast(t("Vos corrections ont été enregistrées"));
                    navigate(PAGES.TRANSFER);
                })
                .catch(errorResponse => {
                    const status = errorResponse.response.status;
                    const errorsData = errorResponse.response.data.errors;
                    const key = errorResponse.response.data?.output?.key || "";
                    if (status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY) {
                        setErrors(errorsData);
                    }

                    if (status === RESPONSE_STATUS.CONFLICT) {
                        if (key === TRANSFER_KYC_STATUS_NOT_IN_TO_REVIEW) {
                            setErrorMessage(
                                t(
                                    "Impossible de soumette les données pour valider le profile transfert."
                                )
                            );
                        }

                        if (key === CARD_KYC_STATUS_NOT_IN_TO_REVIEW) {
                            setErrorMessage(
                                t(
                                    "Impossible de soumette les données pour valider le profile carte."
                                )
                            );
                        }

                        if (key === TRANSFER_CARD_KYC_STATUS_NOT_IN_TO_REVIEW) {
                            setErrorMessage(
                                t(
                                    "Impossible de soumette les données pour valider le profile transfert et carte."
                                )
                            );
                        }

                        if (key === CARD_KYC_NOT_IN_TO_REVIEW_OR_TO_VALIDATE) {
                            setErrorMessage(
                                t("Impossible de completer votre KYC")
                            );
                        }
                    }
                    errorToast(t("Echec de soumission du formulaire"));
                })
                .finally(() => setLoading(false));
        }
    }, [
        disableFields.fullName,
        disableFields.photo,
        disableFields.selfie,
        disableSubmitButton,
        dispatch,
        errorMessage.length,
        errorToast,
        errors,
        fullName,
        kycActionData.cardValidate,
        kycActionData.transferValidate,
        navigate,
        photo,
        selfie,
        successToast,
        t
    ]);

    return (
        <Main showAlert={false}>
            <div className="flex min-h-[calc(100vh-105px)] flex-col justify-between rounded-lg bg-white lg:min-h-[calc(100vh-130px)]">
                <div className="max-w-2xl p-4 md:mx-auto md:w-2/3">
                    <h3 className="mb-6 text-xl font-bold text-gray-700 lg:text-2xl">
                        {t("Veuillez compléter vos informations")}
                    </h3>

                    <div className="relative mb-5">
                        <InputField
                            className={cn({
                                "mb-2": true,
                                "border-red-500":
                                    inputComments.fullName.length > 0
                            })}
                            type="text"
                            placeholder={t("Nom et Prénom")}
                            disabled={disableFields.fullName}
                            label={t("Nom et Prénom")}
                            value={fullName}
                            required={false}
                            onChange={e => setFullName(e.target.value)}
                            error={errors?.full_name}
                        />

                        <p className="text-sm font-medium text-red-500">
                            {inputComments.fullName}
                        </p>
                    </div>

                    <div className="relative mb-5">
                        <TakePicture
                            inputContainerClassName="mb-2"
                            inputClassName={cn({
                                "border-red-500": inputComments.photo.length > 0
                            })}
                            required={false}
                            label={t("Photo carte d'identité/passeport")}
                            disabled={disableFields.photo}
                            value={photo}
                            onPictureTake={file => setPhoto(file)}
                            errorMessage={errors.photo}
                        />

                        <p className="text-sm font-medium text-red-500">
                            {inputComments.photo}
                        </p>
                    </div>

                    <div className="relative mb-5">
                        <TakePicture
                            inputContainerClassName="mb-2"
                            inputClassName={cn({
                                "border-red-500":
                                    inputComments.selfiePhoto.length > 0
                            })}
                            required={false}
                            label={t("Selfie avec carte d'identité/passeport")}
                            disabled={disableFields.selfie}
                            value={selfie}
                            cameraType="user"
                            onPictureTake={file => setSelfie(file)}
                            errorMessage={errors.selfie_photo}
                        />

                        <p className="text-sm font-medium text-red-500">
                            {inputComments.selfiePhoto}
                        </p>
                    </div>

                    {errorMessage && (
                        <p className="my-3 text-center text-xs text-red-500">
                            {errorMessage}
                        </p>
                    )}

                    <Button
                        className="mb-2"
                        onClick={handleSubmit}
                        loading={loading}
                        disabled={disableSubmitButton}
                    >
                        {t("Envoyer")}
                    </Button>

                    <div className="text-center">
                        <button
                            className="mx-auto inline-block text-center text-xs text-[#6B7280]"
                            onClick={() => navigate(-1)}
                        >
                            {t("Retour")}
                        </button>
                    </div>
                </div>
            </div>
        </Main>
    );
};

export default CorrectKyc;
