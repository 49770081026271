import { useTranslation } from "react-i18next";

const TransactionsEmpty = () => {
    const { t } = useTranslation();
    return (
        <div className="flex h-20 items-center justify-center text-gray-400">
            <h5>{t("Pas de transfert effectué")}</h5>
        </div>
    );
};

export default TransactionsEmpty;
