import Select, { SelectProps } from "@ui/Select.tsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getWalletFilePath } from "../helpers";
import useAuth from "../hooks/useAuth.ts";
import useFetchCard from "../hooks/useFetchCard.tsx";
import useManageWalletChannels from "../hooks/useManageWalletChannels.ts";

import WalletPicture from "./WalletPicture.tsx";

interface Props extends SelectProps {
    isPayInChannel?: boolean;
    "data-testid"?: string;
    countries?: string[];
}

const WalletSelect = (props: Props) => {
    const { t } = useTranslation();
    const { isPayInChannel = true } = props;
    const manageWalletChannel = useManageWalletChannels();
    const cardAvailable = useFetchCard();
    const { isLogin } = useAuth();
    const { kycActionData } = useAuth();

    const options = useMemo(() => {
        const channels = isPayInChannel
            ? manageWalletChannel.payinChannels
            : manageWalletChannel.payoutChannels;

        const filterChannels = channels.filter(item => {
            if (!!props.countries && props.countries?.length > 0 && isLogin) {
                const formatCountries = props.countries.map(item =>
                    item.toLowerCase()
                );
                return formatCountries.includes(item.codeCountry.toLowerCase());
            }

            return !(isPayInChannel && !props.countries);
        });

        const formatChannels = filterChannels.map(item => {
            return {
                ...item,
                options: item.options
                    .map(wallet => {
                        return {
                            ...wallet,
                            disable: !wallet.available_at
                        };
                    })
                    .filter(item => {
                        if (isPayInChannel) return !!item.country_id;

                        return true;
                    })
            };
        });

        if (
            isLogin &&
            cardAvailable &&
            kycActionData.cardValidate &&
            isPayInChannel
        ) {
            formatChannels.unshift({
                id: formatChannels.length + 1,
                indicative: "",
                codeCountry: "",
                label: t("International"),
                options: [
                    { ...cardAvailable, disable: !cardAvailable.available_at }
                ]
            });
        }

        return formatChannels.filter(item => item.options.length > 0);
    }, [
        cardAvailable,
        isLogin,
        isPayInChannel,
        kycActionData.cardValidate,
        manageWalletChannel.payinChannels,
        manageWalletChannel.payoutChannels,
        props.countries,
        t
    ]);

    return (
        <div>
            <Select
                disableLabel={t("Indisponible")}
                className="w-[64px] lg:w-[107px]"
                buttonClassName="flex-nowrap border h-[55px] rounded-md py-1 px-2 lg:px-3 flex items-center justify-start"
                chevronContainerClassName="flex-shrink-0"
                value={props.value}
                onChange={props.onChange}
                options={options}
                data-testid={props["data-testid"]}
                isClearable
                disabled={props.disabled}
                onlyChevronOnRight
                formatDefaultValue={() => (
                    <WalletPicture src="/images/wallet/empty-wallet.png" />
                )}
                formatValue={value => {
                    return (
                        <WalletPicture src={getWalletFilePath(value.value)} />
                    );
                }}
            />
        </div>
    );
};

export default WalletSelect;
