import Button from "@ui/Button.tsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PAGES from "../../../constants/pages.ts";
import { cn, thousandFormater } from "../../../helpers";
import useAuth from "../../../hooks/useAuth.ts";

const MyCeilings = () => {
    const { t } = useTranslation();
    const { kycActionData, user } = useAuth();
    const navigate = useNavigate();

    const ceiling = useMemo(() => {
        if (!user?.transactional_ceiling)
            return {
                card: {
                    perDay: "0",
                    perWeek: "0",
                    perMonth: "0"
                },
                wallet: {
                    perDay: "0",
                    perWeek: "0",
                    perMonth: "0"
                }
            };

        return {
            card: {
                perDay: thousandFormater(
                    user.transactional_ceiling.card["per-day"].remains
                ),
                perWeek: thousandFormater(
                    user.transactional_ceiling.card["per-week"].remains
                ),
                perMonth: thousandFormater(
                    user.transactional_ceiling.card["per-month"].remains
                )
            },
            wallet: {
                perDay: thousandFormater(
                    user.transactional_ceiling.wallet["per-day"].remains
                ),
                perWeek: thousandFormater(
                    user.transactional_ceiling.wallet["per-week"].remains
                ),
                perMonth: thousandFormater(
                    user.transactional_ceiling.wallet["per-month"].remains
                )
            }
        };
    }, [user]);

    return (
        <>
            <h6 className="font-bold text-[#374151]">
                {t("Statut compte MyDunya")}
            </h6>

            <div className="mt-4 border-b pb-4">
                <div className="mb-4 flex items-center justify-between text-xs">
                    <p className="text-xs">{t("Transfert par mobile money")}</p>

                    <span
                        className={cn({
                            "font-bold": true,
                            "text-green-700": kycActionData.transferValidate,
                            "text-orange-500":
                                kycActionData.transferToValidate ||
                                kycActionData.transferSubmitReview,
                            "text-red-700":
                                kycActionData.transferRejected ||
                                kycActionData.transferReview
                        })}
                    >
                        {kycActionData.transferValidate && t("Validé")}

                        {(kycActionData.transferToValidate ||
                            kycActionData.transferSubmitReview) &&
                            t("En attente")}

                        {kycActionData.transferReview && t("A corriger")}

                        {kycActionData.transferRejected && t("Non validé")}
                    </span>
                </div>

                {kycActionData.transferReview && (
                    <Button
                        withAuto
                        className="px-3 py-2"
                        variant="outline"
                        onClick={() => navigate(PAGES.CORRECT_KYC)}
                    >
                        {t("Corriger")}
                    </Button>
                )}
            </div>

            <div className="mt-4 border-b pb-4">
                <div className="mb-4 flex items-center justify-between text-xs">
                    <p className="text-xs">
                        {t("Transfert par carte bancaire")}
                    </p>

                    <span
                        className={cn({
                            "font-bold": true,
                            "text-green-700": kycActionData.cardValidate,
                            "text-orange-500":
                                kycActionData.cardToValidate ||
                                kycActionData.cardSubmitReview,
                            "text-red-700":
                                kycActionData.cardRejected ||
                                kycActionData.cardReview
                        })}
                    >
                        {kycActionData.cardValidate && t("Validé")}

                        {(kycActionData.cardToValidate ||
                            kycActionData.cardSubmitReview) &&
                            t("En attente")}

                        {kycActionData.cardReview && t("A corriger")}

                        {kycActionData.cardRejected && t("Non validé")}
                    </span>
                </div>

                {kycActionData.cardReview && (
                    <Button
                        withAuto
                        className="px-3 py-2"
                        variant="outline"
                        onClick={() => navigate(PAGES.CORRECT_KYC)}
                    >
                        {t("Corriger")}
                    </Button>
                )}
            </div>

            <h6 className="mt-4 font-bold text-[#374151]">
                {t("Plafond mobile money MyDunya")}
            </h6>

            <div className="mt-5 space-y-2 border-b pb-4">
                <div className="flex items-center justify-between text-xs">
                    <p className="text-xs text-[#374151]">{t("Journalier")}</p>

                    <span className="font-semibold text-[#374151]">
                        {ceiling.wallet.perDay} FCFA
                    </span>
                </div>

                <div className="flex items-center justify-between text-xs">
                    <p className="text-xs text-[#374151]">
                        {t("Hebdomadaire")}
                    </p>

                    <span className="font-semibold text-[#374151]">
                        {ceiling.wallet.perWeek} FCFA
                    </span>
                </div>

                <div className="flex items-center justify-between text-xs">
                    <p className="text-xs text-[#374151]">{t("Mensuel")}</p>

                    <span className="font-semibold text-[#374151]">
                        {ceiling.wallet.perMonth} FCFA
                    </span>
                </div>
            </div>

            <h6 className="mt-4 font-bold text-[#374151]">
                {t("Plafond carte bancaire MyDunya")}
            </h6>

            <div className="mt-4 space-y-2 border-b pb-4">
                <div className="flex items-center justify-between text-xs">
                    <p className="text-xs text-[#374151]">{t("Journalier")}</p>

                    <span className="font-semibold text-[#374151]">
                        {ceiling.card.perDay} FCFA
                    </span>
                </div>

                <div className="flex items-center justify-between text-xs">
                    <p className="text-xs text-[#374151]">
                        {t("Hebdomadaire")}
                    </p>

                    <span className="font-semibold text-[#374151]">
                        {ceiling.card.perWeek} FCFA
                    </span>
                </div>

                <div className="flex items-center justify-between text-xs">
                    <p className="text-xs text-[#374151]">{t("Mensuel")}</p>

                    <span className="font-semibold text-[#374151]">
                        {ceiling.card.perMonth} FCFA
                    </span>
                </div>
            </div>

            {(kycActionData.transferReview || kycActionData.cardReview) && (
                <Button
                    className="mt-4"
                    onClick={() => navigate(PAGES.CORRECT_KYC)}
                >
                    <span>{t("Augmenter mon plafond")}</span>
                </Button>
            )}
        </>
    );
};

export default MyCeilings;
