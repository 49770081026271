import { SelectItemType } from "@ui/Select.tsx";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { DEFAULT_COUNTRY_CODE } from "../constants";
import { getCountryByIndicative, getPhoneWithIndicative } from "../helpers";
import { RootState } from "../store";

import useAuth from "./useAuth.ts";
import useGpsPosition from "./useGpsPosition.ts";

const useManageWalletChannels = () => {
    const { list: channelCountries } = useSelector(
        (state: RootState) => state.channels
    );
    const { isLogin, user } = useAuth();
    const { position } = useGpsPosition();

    const getChannels = useCallback(
        (isPayInChannel: boolean) => {
            const attribute = isPayInChannel ? "payIn" : "payOut";
            return channelCountries[attribute]?.map(item => {
                return {
                    id: item.id,
                    label: item.name,
                    indicative: item.phone_code,
                    codeCountry: item.code,
                    options:
                        item.channels?.map(channel => ({
                            ...channel,
                            id: channel.id,
                            label: channel.name,
                            value: channel.slug,
                            countryCode: item.code,
                            countryIndicative: item.phone_code
                        })) || []
                };
            });
        },
        [channelCountries]
    );

    const channels = useMemo(() => {
        return {
            payIn: getChannels(true) || [],
            payOut: getChannels(false) || []
        };
    }, [getChannels]);

    const getChannelById = useCallback(
        (isPayIn: boolean, id: number | string) => {
            let channel = null;
            if (isPayIn) {
                for (let i = 0; i < channels.payIn.length; i++) {
                    for (
                        let x = 0;
                        x < channels.payIn[i].options?.length;
                        x++
                    ) {
                        if (channels.payIn[i].options[x].id === id) {
                            channel = channels.payIn[i].options[x];
                            break;
                        }
                    }
                    if (channel) break;
                }
            } else {
                for (let i = 0; i < channels.payOut.length; i++) {
                    for (
                        let x = 0;
                        x < channels.payOut[i].options.length;
                        x++
                    ) {
                        if (channels.payOut[i].options[x].id === id) {
                            channel = channels.payOut[i].options[x];
                            break;
                        }
                    }
                    if (channel) break;
                }
            }
            return channel;
        },
        [channels.payIn, channels.payOut]
    );

    const getWalletByCountryCode = useCallback(
        (countryCode: string, isPayout = false) => {
            const channel = channels[isPayout ? "payIn" : "payOut"].find(
                item => item.codeCountry.toLowerCase() === countryCode
            );

            return channel?.options || [];
        },
        [channels]
    );

    const getWallet = useCallback(
        (countryCode: string, isPayout = false) => {
            const defaultWallets = getWalletByCountryCode(
                countryCode,
                isPayout
            );
            const defaultWallet = defaultWallets.find(item => item.has_default);

            if (defaultWallet?.available_at) return defaultWallet;

            const activeWallet = defaultWallets.find(
                item => !!item.available_at
            );

            return activeWallet?.available_at ? activeWallet : null;
        },
        [getWalletByCountryCode]
    );

    const getDefaultWallet = useCallback(
        (isPayout = false): SelectItemType | null => {
            isPayout = !isPayout;
            const defaultWallet = getWallet(DEFAULT_COUNTRY_CODE, isPayout);

            if (isLogin && user) {
                const phone = getPhoneWithIndicative(
                    user.phone_with_indicative
                );
                const phoneCountryCode = getCountryByIndicative(
                    phone?.indicative || ""
                );

                if (phoneCountryCode) {
                    return getWallet(phoneCountryCode, isPayout);
                } else {
                    return defaultWallet;
                }
            } else {
                if (position) {
                    return (
                        getWallet(
                            position.countryCode.toLowerCase(),
                            isPayout
                        ) || defaultWallet
                    );
                } else {
                    return defaultWallet;
                }
            }
        },
        [getWallet, isLogin, position, user]
    );

    const getCountryById = useCallback(
        (isPayIn: boolean, id: number) => {
            const channelsList = isPayIn
                ? channels["payIn"]
                : channels["payOut"];

            return channelsList.find(item => item.id === id) || null;
        },
        [channels]
    );

    const getDefaultWalletByCountryCode = useCallback(
        (countryCode: string, isPayout = false) => {
            return getWallet(countryCode.toLowerCase(), isPayout);
        },
        [getWallet]
    );

    return {
        payinChannels: channels.payIn,
        payoutChannels: channels.payOut,
        getChannelById,
        getWalletByCountryCode,
        getDefaultWallet,
        getDefaultWalletByCountryCode,
        getCountryById,
        payInCountriesAvailable: useMemo(
            () =>
                channels.payIn
                    .map(item => item.indicative)
                    .filter(item => item !== "+224"),
            [channels.payIn]
        )
    };
};

export default useManageWalletChannels;
