import { Modal } from "@ui/Modal.tsx";
import { useTranslation } from "react-i18next";

import AddCard from "../../../components/SecondaryCardOrNumber/AddCard.tsx";
import { ModalPropsType } from "../../../types";

const CardAddModal = (props: ModalPropsType) => {
    const { isOpen, closeModal } = props;

    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            className="overflow-visible"
        >
            <h5 className="mb-3 text-center font-semibold">
                {t("Ajouter une carte")}
            </h5>

            <p className="mb-3 text-center text-sm">
                {t(
                    "Veuillez ajouter les informations de votre carte. La validation pourrait prendre 48H ouvré."
                )}
            </p>

            <AddCard closeModal={closeModal} />
        </Modal>
    );
};

export default CardAddModal;
