import React, { useCallback, useContext } from "react";

import PinInputContext, {
    PinInputStore
} from "../../../context/PinInputContext.ts";
import { cn } from "../../../helpers";
import useBlurOrFocusInput from "../../../hooks/useBlurOrFocusInput.ts";
import { SizeType } from "../../../types";

interface Props {
    index: number;
    className?: string;
    size?: SizeType;
    type?: "text" | "tel";
}

const PinInputItem = (props: Props) => {
    const { type, length, placeholder, pin, onChangeIndex, focusInputByIndex } =
        useContext(PinInputContext) as PinInputStore;

    const inputId = `pin-item-${props.index}`;

    useBlurOrFocusInput(inputId, {
        focus: e => {
            if (e?.target && "placeholder" in e.target) {
                e.target.placeholder = "";
            }
        },
        blur: e => {
            if (e?.target && "placeholder" in e.target) {
                e.target.placeholder = placeholder;
            }
        }
    });

    const handleValueChange = useCallback(
        (value: string) => {
            onChangeIndex(props.index, value);
            if (props.index + 1 < length && value.length) {
                focusInputByIndex(props.index + 1);
            }
        },
        [focusInputByIndex, length, onChangeIndex, props.index]
    );

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (
                e.key === "Backspace" &&
                props.index - 1 >= 0 &&
                !pin[props.index]
            ) {
                focusInputByIndex(props.index - 1);
                onChangeIndex(props.index - 1, "");
            }
        },
        [focusInputByIndex, onChangeIndex, pin, props.index]
    );

    return (
        <input
            id={inputId}
            autoComplete="off"
            maxLength={1}
            placeholder={placeholder}
            className={cn({
                "rounded-md text-center": true,
                "border-gray-300 text-gray-700 placeholder-gray-400": true,
                "focus:border-primary focus:ring focus:ring-primary/20": true,
                "h-10 w-10 text-xs": props.size === "xs",
                "h-12 w-12 text-sm": props.size === "sm",
                "h-16 w-16 font-medium": props.size === "md",
                "h-16 w-20 text-lg font-medium": props.size === "lg",
                "h-16 w-24 text-xl font-medium": props.size === "xl",
                [props.className || ""]: true
            })}
            type={props.type || "tel"}
            value={pin[props.index] || ""}
            onChange={e => handleValueChange(e.target.value)}
            onKeyDown={handleKeyDown}
            pattern={type === "number" ? "[0-9]*" : "[a-zA-Z0-9]*"}
        />
    );
};

export default PinInputItem;
