import { ChangeEvent, useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import TransferForm from "../components/forms/TransferForm.tsx";
import AppLayout from "../components/layouts/AppLayout.tsx";
import AppLayoutSection from "../components/layouts/AppLayoutSection.tsx";
import Aside from "../components/layouts/Aside.tsx";
import AsideTitle from "../components/layouts/AsideTitle.tsx";
import SearchInput from "../components/SearchInput.tsx";
import TransferHistory from "../components/TransferHistory.tsx";
import useAuth from "../hooks/useAuth.ts";
import useManageTransfer from "../hooks/useManageTransfer.ts";
import usePageTitle from "../hooks/usePageTitle.ts";

const Transfer = () => {
    const { t } = useTranslation();
    usePageTitle(t("Transfert"));
    const { isLogin } = useAuth();
    const inputValueRef = useRef<string | null>(null);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const {
        loading,
        fetchTransfer,
        transfers,
        updateFilter: updateTransferFilter,
        hasMore,
        filter: transferFilter
    } = useManageTransfer();

    const handleSearchInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            inputValueRef.current = value;
            updateTransferFilter({ search: value }, !value);

            function getData() {
                timerRef.current = setTimeout(() => {
                    fetchTransfer(1, transferFilter.pageSize, value);
                    updateTransferFilter(
                        {
                            pageIndex: 1,
                            pageSize: transferFilter.pageSize,
                            search: inputValueRef.current || ""
                        },
                        !inputValueRef.current
                    );
                }, 300);
            }

            if (timerRef.current) {
                clearTimeout(timerRef.current);
                getData();
            } else {
                getData();
            }
        },
        [fetchTransfer, transferFilter.pageSize, updateTransferFilter]
    );

    useEffect(() => {
        fetchTransfer(
            transferFilter.pageIndex,
            transferFilter.pageSize,
            inputValueRef?.current
        );
    }, [fetchTransfer, transferFilter.pageIndex, transferFilter.pageSize]);

    return (
        <AppLayout info={t("headerTitle1")}>
            <AppLayoutSection className={isLogin ? "" : "lg:col-span-3"}>
                <TransferForm
                    onSuccess={() => {
                        if (!transferFilter.search?.length) {
                            fetchTransfer(1, transferFilter.pageSize);
                        }
                    }}
                />
            </AppLayoutSection>

            {isLogin && (
                <Aside className="pt-3" containerClassName="py-2">
                    <AsideTitle className="mb-3 text-left font-bold">
                        {t("Historique")}
                    </AsideTitle>

                    <SearchInput
                        onChange={handleSearchInputChange}
                        value={transferFilter.search || ""}
                        divClassName="mb-12"
                        loading={loading}
                    />

                    <TransferHistory
                        transactions={transfers}
                        loading={loading}
                        setPaginate={value => {
                            updateTransferFilter({
                                pageIndex: value.page,
                                pageSize: value.limit,
                                search: inputValueRef.current || ""
                            });
                        }}
                        paginate={{
                            page: transferFilter.pageIndex,
                            limit: transferFilter.pageSize
                        }}
                        hasMore={hasMore}
                    />
                </Aside>
            )}
        </AppLayout>
    );
};

export default Transfer;
