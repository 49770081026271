import React from "react";
import { useTranslation } from "react-i18next";

import useThemeColor from "../../hooks/useThemeColor.ts";
import { COLOR } from "../../types";
// import GrRefreshIcon from "../icons/GrRefreshIcon.tsx";
// import LangSwitcher from "../LangSwitcher.tsx";
// import GrRefreshIcon from "../icons/GrRefreshIcon.tsx";
// import LangSwitcher from "../LangSwitcher.tsx";
import MyDunyaLogo from "../MyDunyaLogo.tsx";

interface Props {
    children?: React.ReactNode;
    title?: string;
}

interface PictureProps {
    src?: string;
    alt?: string;
}

const WalletPicture = (props: PictureProps) => {
    return (
        <img
            className="h-10 w-10 rounded-full border-2 border-white bg-white transition-all duration-300"
            src={props.src}
            alt={props.alt}
        />
    );
};

const WALLETS_PICTURE = [
    "/images/wallet/orange-money.png",
    "/images/wallet/wave.png",
    "/images/wallet/mtn.png",
    "/images/wallet/moov.png",
    "/images/wallet/t-money.jpeg",
    "/images/wallet/free-money-two.png",
    "/images/wallet/wizall.png",
    "/images/wallet/e-money.png"
];

const AuthLayout = (props: Props) => {
    const { t } = useTranslation();
    useThemeColor(COLOR.WHITE);

    return (
        <div className="relative flex min-h-screen text-gray-500">
            <div className="min-h-screen w-full p-5 lg:w-1/2">
                <div className="flex h-full flex-col items-center justify-center rounded-xl bg-white">
                    <div className="w-full px-6 lg:w-11/12 xl:w-3/4">
                        <MyDunyaLogo
                            src="/images/logo-without-bg.png"
                            className="mx-auto -mb-12 w-20 bg-none shadow-none"
                        />
                        {props.children}
                    </div>
                </div>
            </div>

            <div className="hidden min-h-screen w-1/2 items-center justify-center -space-x-28 p-5 lg:flex">
                <div className="relative h-full w-full overflow-hidden rounded-xl bg-[#103357]">
                    <div className="relative z-10 px-16 pt-12">
                        <MyDunyaLogo />

                        <h3 className="mb-4 text-3xl font-semibold text-white">
                            {t("slogan.part1")} <br />
                            {t("slogan.part2")} <br />
                            {t("slogan.part3")}
                        </h3>

                        <div className="group inline-flex cursor-pointer items-center -space-x-4 hover:space-x-2">
                            {WALLETS_PICTURE.map((item, index) => (
                                <WalletPicture key={index} src={item} />
                            ))}
                        </div>
                    </div>

                    <img
                        src="/images/login-end.png"
                        className="absolute bottom-0 z-0 object-cover object-center opacity-50"
                        alt=""
                    />
                </div>
            </div>

            {/*<div className="absolute top-10 left-12 hidden md:flex items-center">
                <LangSwitcher className="" itemsContainerClassName="-left-1" />

                <button
                    type="button"
                    className="py-5 pl-3 pr-6 mx-3 cursor-pointer"
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        window.location.reload();
                    }}
                >
                    <GrRefreshIcon />
                </button>
            </div>*/}
        </div>
    );
};

export default AuthLayout;
