import { useTranslation } from "react-i18next";

import { cn } from "../../helpers";

interface Props {
    onClick: () => void;
    isActive: boolean;
    type: "maxit" | "otp" | "simple";
}

const OmSnButton = (props: Props) => {
    const { onClick, type, isActive } = props;

    const { t } = useTranslation();

    return (
        <button
            onClick={onClick}
            className={cn({
                "flex items-center justify-center": true,
                "rounded-md border p-0.5 focus:outline-none": true,
                "focus:border-primary focus:ring focus:ring-primary/20": true,
                "border-primary ring ring-primary/20": isActive
            })}
            type="button"
        >
            {type === "maxit" && (
                <>
                    <div className="flex w-full items-center justify-center rounded-md bg-[#EE8131]">
                        <img
                            className="hidden w-full rounded-md object-cover object-center xl:block xl:h-[40px] xl:w-auto"
                            src="/images/maxit-web.png"
                            alt=""
                        />

                        <img
                            className="w-full max-w-[100px] rounded-md object-cover object-center xl:hidden"
                            src="/images/maxit-mobile.png"
                            alt=""
                        />
                    </div>
                </>
            )}

            {type === "otp" && (
                <div className="flex h-full w-full items-center justify-center rounded-md bg-gray-100 text-sm text-gray-600 lg:text-[10px] xl:text-sm xl:font-semibold">
                    {t("Code OTP")}
                </div>
            )}

            {type === "simple" && (
                <img
                    className="h-[20px] rounded-md object-cover object-center lg:h-[10px] xl:h-[20px]"
                    src="/images/wallet/orange-money-two.png"
                    alt=""
                />
            )}
        </button>
    );
};

export default OmSnButton;
