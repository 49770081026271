import { useTranslation } from "react-i18next";

import usePageTitle from "../hooks/usePageTitle.ts";

const ResetPin = () => {
    const { t } = useTranslation();
    usePageTitle(t("Réinitialiser pin"));

    return (
        <div className="flex h-screen items-center justify-center text-4xl text-gray-500">
            {t("Reset pin")}
        </div>
    );
};

export default ResetPin;
