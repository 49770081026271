import { useCallback, useEffect, useState } from "react";

import { STORAGE } from "../constants";
import { storage } from "../helpers";
import { dateAdd, dateIsAfter } from "../libs/date.ts";

const useManageRequestTransfers = (transferId?: number) => {
    const [showAlert, setShowAlert] = useState(false);

    const getTransfersRequest = useCallback(() => {
        const transfersRequested = storage().getItem(
            STORAGE.TRANSFERS_REQUESTED
        );

        return JSON.parse(transfersRequested ?? "null");
    }, []);

    const removeTransfer = useCallback(
        (id: string) => {
            const transfersRequestedParse = getTransfersRequest();

            if (
                transfersRequestedParse &&
                Array.isArray(transfersRequestedParse)
            ) {
                const filterTransferRequested = transfersRequestedParse.filter(
                    item => item.id !== id
                );

                storage().setItem(
                    STORAGE.TRANSFERS_REQUESTED,
                    JSON.stringify(filterTransferRequested)
                );
            }
        },
        [getTransfersRequest]
    );

    const handleAddTransferRequest = useCallback(() => {
        if (transferId) {
            const transferRequested = getTransfersRequest();

            const expireAt = dateAdd(new Date(), 24, "hour").toISOString();

            setShowAlert(true);
            if (
                !transferRequested ||
                (transferRequested &&
                    Array.isArray(transferRequested) &&
                    transferRequested.length === 0)
            ) {
                storage().setItem(
                    STORAGE.TRANSFERS_REQUESTED,
                    JSON.stringify([
                        {
                            id: transferId,
                            expireAt
                        }
                    ])
                );
                return;
            }

            if (transferRequested && Array.isArray(transferRequested)) {
                const findItem = transferRequested.find(
                    item => item.id === transferId
                );

                if (!findItem) {
                    transferRequested.push({
                        id: transferId,
                        expireAt
                    });

                    storage().setItem(
                        STORAGE.TRANSFERS_REQUESTED,
                        JSON.stringify(transferRequested)
                    );
                } else {
                    storage().setItem(
                        STORAGE.TRANSFERS_REQUESTED,
                        JSON.stringify(
                            transferRequested.map(item => ({
                                id: item.id,
                                expireAt:
                                    item.id === findItem.id
                                        ? expireAt
                                        : item.expireAt
                            }))
                        )
                    );
                }
            }
        }
    }, [getTransfersRequest, transferId]);

    useEffect(() => {
        const transfersRequestedParse = getTransfersRequest();

        if (
            transferId &&
            transfersRequestedParse &&
            Array.isArray(transfersRequestedParse)
        ) {
            const findItem = transfersRequestedParse.find(
                item => item.id === transferId
            );

            setShowAlert(
                findItem && dateIsAfter(new Date(), new Date(findItem.expireAt))
            );

            if (
                findItem &&
                !dateIsAfter(new Date(), new Date(findItem.expireAt))
            ) {
                removeTransfer(findItem.id);
            }
        }
    }, [getTransfersRequest, removeTransfer, transferId]);

    return {
        showAlert,
        handleAddTransferRequest
    };
};

export default useManageRequestTransfers;
