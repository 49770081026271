import { Modal } from "@ui/Modal.tsx";
import { useTranslation } from "react-i18next";

import AddNumber from "../../../components/SecondaryCardOrNumber/AddNumber.tsx";
import { ModalPropsType } from "../../../types";

interface Props extends ModalPropsType {
    phoneData?: {
        phone: string;
        indicative: string;
    };
}

const NumberAddModal = (props: Props) => {
    const { isOpen, closeModal, phoneData } = props;

    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            className="overflow-visible"
        >
            <h5 className="mb-3 text-center font-semibold">
                {t("Ajouter un numéro")}
            </h5>

            <AddNumber closeModal={closeModal} phoneData={phoneData} />
        </Modal>
    );
};

export default NumberAddModal;
