import { DropdownButton } from "@ui/Dropdown.tsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { cn } from "../../helpers";
import HiOutlineIcon from "../icons/HiOutlineIcon.tsx";

interface Props {
    children: React.ReactNode;
}

const NotificationBellButton = (props: Props) => {
    const { t } = useTranslation();
    return (
        <DropdownButton
            type="button"
            as="button"
            className={cn(
                "relative px-4 py-4 lg:px-12 lg:py-3",
                "items-center justify-center lg:flex lg:flex-col"
            )}
        >
            <HiOutlineIcon size="lg" />
            <span className="hidden text-sm">{t("Notifications")}</span>
            {props.children !== 0 && (
                <span
                    className={cn(
                        "absolute top-1 ml-3 h-5 w-5 rounded-full bg-red-500 shadow-sm",
                        "text-[10px] font-medium text-white",
                        "flex items-center justify-center"
                    )}
                >
                    {props.children}
                </span>
            )}
        </DropdownButton>
    );
};

export default NotificationBellButton;
