import { Dropdown, DropdownItems } from "@ui/Dropdown.tsx";

import { cn } from "../../helpers";
import useNotification from "../../hooks/useNotification.ts";

import NotificationBellButton from "./NotificationBellButton.tsx";
import NotificationFooter from "./NotificationFooter.tsx";
import NotificationHeader from "./NotificationHeader.tsx";
import NotificationItem from "./NotificationItem.tsx";

const NotificationDropdown = () => {
    const { toggle, setToggle, notifications, unreadNotification } =
        useNotification();

    return (
        <Dropdown as="div">
            <NotificationBellButton>
                {unreadNotification.length < 9
                    ? unreadNotification.length
                    : "+9"}
            </NotificationBellButton>

            <DropdownItems
                className={cn(
                    "w-11/12 rounded-md text-sm focus:outline-none lg:w-[450px]",
                    "border border-gray-100 bg-white text-gray-800 shadow-sm",
                    "absolute right-4 top-16 z-10 md:mt-4 lg:right-40 lg:mt-2"
                )}
            >
                <NotificationHeader toggle={toggle} setToggle={setToggle} />

                <div className="h-[227px] divide-y overflow-y-auto">
                    {notifications.map((item, index) => (
                        <NotificationItem key={index} value={item} />
                    ))}
                </div>

                <NotificationFooter />
            </DropdownItems>
        </Dropdown>
    );
};

export default NotificationDropdown;
