import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LoginForm from "../components/forms/LoginForm.tsx";
import AuthLayout from "../components/layouts/AuthLayout.tsx";
import config from "../config";
import useLogin from "../hooks/useLogin.ts";
import usePageTitle from "../hooks/usePageTitle.ts";

const Login = () => {
    const { t } = useTranslation();
    usePageTitle(t("Connexion"));

    const navigate = useNavigate();

    const {
        loading,
        errors,
        pin,
        setPin,
        phoneWithoutIndicative,
        setPhoneWithoutIndicative,
        onSubmit,
        indicativeState,
        invalidCredential,
        otherIndicativeState
    } = useLogin(undefined, url => {
        navigate(url || config.auth.redirectLoginUrl);
    });

    return (
        <AuthLayout title={t("Bienvenue")}>
            <LoginForm
                loading={loading}
                errors={errors}
                onSubmit={onSubmit}
                indicative={indicativeState}
                otherIndicativeState={otherIndicativeState}
                invalidCredential={invalidCredential}
                pin={pin}
                setPin={setPin}
                phoneWithoutIndicative={phoneWithoutIndicative}
                setPhoneWithoutIndicative={setPhoneWithoutIndicative}
            />
        </AuthLayout>
    );
};

export default Login;
