import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import config from "../config";
import { DEFAULT_COUNTRY_INDICATIVE, RESPONSE_STATUS } from "../constants";
import ENDPOINTS from "../constants/endpoint.ts";
import {
    getIndicativeByCountryCode,
    loadAuthServiceHost,
    phoneIsValid
} from "../helpers";
import rules from "../schemas";
import { RootState } from "../store";
import { updateAuthUser } from "../store/authSlice.ts";
import { resetSenderData } from "../store/transferSlice.ts";
import { setStoreSecondaryNumberData } from "../store/uiSlice.ts";

import useAuth from "./useAuth.ts";
import useManageSchemaValidationError from "./useManageSchemaValidationError.ts";
import useManageWalletChannels from "./useManageWalletChannels.ts";
import useToast from "./useToast.ts";

const OTP_CONFIRM_TOAST_DURATION = 50000;

// eslint-disable-next-line react-refresh/only-export-components
const PhoneSchema = yup.object().shape({
    phone_with_indicative: rules.phone
});

// eslint-disable-next-line react-refresh/only-export-components
const OtpSchema = yup.object().shape({
    otp: rules.pin
});

const NUMBER_HAVE_TAKING_IN_YOUR_PRINCIPAL =
    "phone_number_with_indicative_has_already_registered_for_your_account";

const NUMBER_HAVE_TAKING_IN_PRINCIPAL =
    "phone_number_with_indicative_has_already_registered_for_another_account";

const SECONDARY_NUMBER_HAVE_TAKING_YOUR_ACCOUNT =
    "phone_number_with_indicative_has_already_registered_like_secondary_phone_for_your_account";

const SECONDARY_NUMBER_HAVE_TAKING_IN_OTHER_ACCOUNT =
    "phone_number_with_indicative_has_already_registered_like_secondary_phone_for_another_account";

const KYC_NOT_VALIDATED = "kyc_not_validated";

interface PhoneDataType {
    phone: string;
    indicative: string;
}

const useManageAddNumber = (
    showForm = false,
    phoneData?: PhoneDataType,
    onConfirmSuccess?: () => void
) => {
    const { t } = useTranslation();
    const [showAddForm, setShowAddForm] = useState(showForm);
    const [showOtpForm, setShowOtpForm] = useState(false);
    const [otp, setOtp] = useState("");
    const [phone, setPhone] = useState("");
    const [indicative, setIndicative] = useState(DEFAULT_COUNTRY_INDICATIVE);
    const { showErrors, resetErrors, errors, setErrors } =
        useManageSchemaValidationError();
    const [loading, setLoading] = useState(false);
    const [loadingSendOtp, setLoadingSendOtp] = useState(false);
    const [otherIndicative, setOtherIndicative] = useState("");
    const { payInCountriesAvailable } = useManageWalletChannels();

    const { successToast, errorToast } = useToast();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const { position } = useSelector((state: RootState) => state.position);

    useEffect(() => {
        if (!phoneData) {
            if (position) {
                const positionIndicative = getIndicativeByCountryCode(
                    position.countryCode
                );

                setIndicative(positionIndicative || DEFAULT_COUNTRY_INDICATIVE);
            }
        } else {
            if (payInCountriesAvailable.includes(phoneData.indicative)) {
                setIndicative(phoneData.indicative);
            } else {
                setIndicative("other");
                setOtherIndicative(phoneData.indicative);
            }
            setPhone(phoneData.phone);
            setShowAddForm(false);
            setShowOtpForm(true);
        }
    }, [payInCountriesAvailable, phoneData, position]);

    const refreshUserData = useCallback(
        (action: () => void) => {
            loadAuthServiceHost();
            window.axios
                .get(ENDPOINTS.ME)
                .then(response => {
                    dispatch(updateAuthUser({ user: response.data.data }));
                    dispatch(resetSenderData());
                    action();
                })
                .finally(() => setLoading(false));
        },
        [dispatch]
    );

    const newIndicative = useMemo(
        () => (indicative === "other" ? otherIndicative : indicative),
        [indicative, otherIndicative]
    );

    const addNumber = useCallback(() => {
        PhoneSchema.validate(
            { phone_with_indicative: phone },
            { abortEarly: false }
        )
            .then(() => {
                const phoneNumber = `${newIndicative} ${phone}`;

                if (!phoneIsValid(phoneNumber)) {
                    setErrors({
                        ...errors,
                        phone_with_indicative: t("Invalid phone number")
                    });
                    return;
                }

                resetErrors();
                setLoading(true);
                loadAuthServiceHost();
                window.axios
                    .post(ENDPOINTS.ADD_SECONDARY_NUMBER, {
                        phone_with_indicative: phoneNumber,
                        account_id: user?.account.id
                    })
                    .then(() => {
                        dispatch(
                            setStoreSecondaryNumberData({
                                phone,
                                indicative: newIndicative
                            })
                        );
                        refreshUserData(() => {
                            successToast(
                                t("Veuillez confirmer le code OTP"),
                                "",
                                OTP_CONFIRM_TOAST_DURATION
                            );
                            setShowAddForm(false);
                            setShowOtpForm(true);
                            setLoading(false);
                        });
                    })
                    .catch(errorResponse => {
                        const errors = errorResponse.response.data.errors;
                        const key = errorResponse.response.data.data?.key || "";
                        const status = errorResponse.response.status;
                        if (status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY) {
                            setErrors(errors);
                        }

                        if (
                            status === RESPONSE_STATUS.CONFLICT &&
                            key === NUMBER_HAVE_TAKING_IN_YOUR_PRINCIPAL
                        ) {
                            setErrors({
                                phone_with_indicative: t(
                                    "Ce numéro est déjà utilisé comme numéro principal sur votre compte"
                                )
                            });
                        }

                        if (
                            status === RESPONSE_STATUS.CONFLICT &&
                            key === NUMBER_HAVE_TAKING_IN_PRINCIPAL
                        ) {
                            setErrors({
                                phone_with_indicative: t(
                                    "Ce numéro est déjà utilisé comme numéro principal sur un autre compte"
                                )
                            });
                        }

                        if (
                            status === RESPONSE_STATUS.CONFLICT &&
                            key === SECONDARY_NUMBER_HAVE_TAKING_YOUR_ACCOUNT
                        ) {
                            setErrors({
                                phone_with_indicative: t(
                                    "Ce numéro est déjà utilisé comme numéro secondaire sur votre compte"
                                )
                            });
                        }

                        if (
                            status === RESPONSE_STATUS.CONFLICT &&
                            key ===
                                SECONDARY_NUMBER_HAVE_TAKING_IN_OTHER_ACCOUNT
                        ) {
                            setErrors({
                                phone_with_indicative: t(
                                    "Ce numéro est déjà utilisé comme numéro secondaire sur un autre compte"
                                )
                            });
                        }

                        errorToast(
                            key === KYC_NOT_VALIDATED
                                ? t("KYC non validé")
                                : t("Echec de l'operation"),
                            key === KYC_NOT_VALIDATED
                                ? t(
                                      "Veuillez patienter jusqu'à ce que votre KYC soit validé"
                                  )
                                : undefined,
                            key === KYC_NOT_VALIDATED
                                ? 10000
                                : config.toastTimeout
                        );
                        setLoading(false);
                    });
            })
            .catch(showErrors());
    }, [
        dispatch,
        errorToast,
        errors,
        newIndicative,
        phone,
        refreshUserData,
        resetErrors,
        setErrors,
        showErrors,
        successToast,
        t,
        user?.account.id
    ]);

    const confirmOpt = useCallback(() => {
        OtpSchema.validate({ otp }, { abortEarly: false })
            .then(() => {
                resetErrors();
                setLoading(true);
                loadAuthServiceHost();
                window.axios
                    .post(ENDPOINTS.VALIDATE_SECONDARY_NUMBER_OTP, {
                        phone_with_indicative: `${newIndicative} ${phone}`,
                        code: otp
                    })
                    .then(() => {
                        dispatch(setStoreSecondaryNumberData(null));
                        refreshUserData(() => {
                            successToast(t("Numéro ajouté avec succès"));
                            setShowAddForm(false);
                            setShowOtpForm(false);
                            setLoading(false);
                            setPhone("");
                            onConfirmSuccess && onConfirmSuccess();
                        });
                    })
                    .catch(() => {
                        setLoading(false);
                        errorToast(t("Echec de l'operation"));
                        setErrors({
                            otp: "Otp ou numéro de téléphone incorrect"
                        });
                    });
            })
            .catch(showErrors());
    }, [
        dispatch,
        errorToast,
        newIndicative,
        onConfirmSuccess,
        otp,
        phone,
        refreshUserData,
        resetErrors,
        setErrors,
        showErrors,
        successToast,
        t
    ]);

    const resendOtp = useCallback(() => {
        setLoading(true);
        loadAuthServiceHost();
        window.axios
            .post(ENDPOINTS.SEND_TEMPORARY_CODE_TO_SECONDARY_NUMBER, {
                phone_with_indicative: `${newIndicative} ${phone}`
            })
            .then(() => {
                successToast(t("Code otp envoyé avec succès"));
            })
            .finally(() => setLoading(false));
    }, [newIndicative, phone, successToast, t]);

    const handleClickSubmitButton = useCallback(() => {
        if (showAddForm) {
            addNumber();
        }

        if (showOtpForm) {
            confirmOpt();
        }
    }, [addNumber, confirmOpt, showAddForm, showOtpForm]);

    const handleSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            handleClickSubmitButton();
        },
        [handleClickSubmitButton]
    );

    const handleResendOtp = useCallback(
        (phoneNumber: string) => {
            setLoadingSendOtp(true);
            loadAuthServiceHost();
            window.axios
                .post(ENDPOINTS.SEND_TEMPORARY_CODE_TO_SECONDARY_NUMBER, {
                    phone_with_indicative: phoneNumber
                })
                .then(() => {
                    successToast(t("Code otp envoyé avec succès"));
                    const newIndicative = phoneNumber.split(" ")[0];
                    const newPhone = phoneNumber.split(" ")[1];

                    if (newIndicative.length && newPhone.length) {
                        if (payInCountriesAvailable.includes(newIndicative)) {
                            setIndicative(newIndicative);
                        } else {
                            setIndicative("other");
                            setOtherIndicative(newIndicative);
                        }
                        setPhone(newPhone);
                        setShowOtpForm(true);
                    }
                })
                .finally(() => setLoadingSendOtp(false));
        },
        [payInCountriesAvailable, successToast, t]
    );

    return {
        handleSubmit,
        handleClickSubmitButton,
        resendOtp,
        loading,
        errors,
        indicative,
        phone,
        showAddForm,
        showOtpForm,
        setShowAddForm,
        setShowOtpForm,
        setIndicative,
        setPhone,
        setOtp,
        handleResendOtp,
        loadingSendOtp,
        otherIndicative,
        setOtherIndicative
    };
};

export default useManageAddNumber;
