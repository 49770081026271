import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CheckCircleIcon from "../../../components/icons/CheckCircleIcon.tsx";
import HiOutlineExclamatory from "../../../components/icons/HiOutlineExclamatory.tsx";
import MdTimerIcon from "../../../components/icons/MdTimerIcon.tsx";
import PAGES from "../../../constants/pages.ts";
import { cn } from "../../../helpers";
import useAuth from "../../../hooks/useAuth.ts";

interface ProfileItemProps {
    label: string;
    value: string;
    error: string;
    warning?: boolean;
}

const ProfileItem = (props: ProfileItemProps) => {
    const { label, value, error, warning = false } = props;

    const { t } = useTranslation();

    return (
        <div className="flex items-center space-x-3 border-b py-5">
            {warning ? (
                <MdTimerIcon className="h-5 w-5 text-yellow-500" />
            ) : (
                <>
                    {error.length > 0 ? (
                        <HiOutlineExclamatory className="h-5 w-5 text-red-500" />
                    ) : (
                        <CheckCircleIcon className="h-5 w-5 text-green-500" />
                    )}
                </>
            )}

            <div className="flex flex-1 items-center justify-between">
                <div className="space-y-1 text-xs">
                    <h4 className="font-bold text-[#374151]">{label}</h4>
                    <p className="text-[#374151]">{value}</p>
                    <p
                        className={cn({
                            "text-red-500": !warning,
                            "text-yellow-500": warning
                        })}
                    >
                        {warning ? t("Validation en cours") : error}
                    </p>
                </div>

                {error.length > 0 && (
                    <Link
                        to={PAGES.CORRECT_KYC}
                        className="text-xs font-bold text-red-500 underline"
                    >
                        {t("Corriger")}
                    </Link>
                )}
            </div>
        </div>
    );
};

const Profile = () => {
    const { t } = useTranslation();
    const { user, kycActionData } = useAuth();
    const [inputComments, setInputComments] = useState({
        fullName: "",
        photo: "",
        selfiePhoto: ""
    });

    useEffect(() => {
        if (user?.account?.comments) {
            const parseData = JSON.parse(user.account.comments);
            setInputComments({
                fullName: parseData?.full_name?.comment || "",
                photo: parseData?.photo?.comment || "",
                selfiePhoto: parseData?.selfie_photo?.comment || ""
            });
        }
    }, [user?.account, user?.full_name]);

    return (
        <>
            <h6 className="font-bold text-[#374151]">
                {t("Données personnelles")}
            </h6>

            <ProfileItem
                label={t("Nom et prénom")}
                value={user.full_name ?? "..."}
                error={
                    kycActionData.transferReview ? inputComments.fullName : ""
                }
                warning={
                    kycActionData.transferToValidate ||
                    kycActionData.transferSubmitReview
                }
            />

            <ProfileItem
                label={t("Numéro")}
                value={user.phone_with_indicative ?? "..."}
                error={""}
            />

            {user.email && (
                <ProfileItem
                    label={t("Email")}
                    value={user.email ?? "..."}
                    error={""}
                />
            )}

            <ProfileItem
                label={t("Photo carte d'identité/passeport")}
                value="Photo.jpeg"
                error={kycActionData.transferReview ? inputComments.photo : ""}
                warning={
                    kycActionData.transferToValidate ||
                    kycActionData.transferSubmitReview
                }
            />

            <ProfileItem
                label={t("Selfie avec carte d'identité/passeport")}
                value="Photo.jpeg"
                error={
                    kycActionData.cardReview ? inputComments.selfiePhoto : ""
                }
                warning={
                    kycActionData.cardToValidate ||
                    kycActionData.cardSubmitReview
                }
            />
        </>
    );
};

export default Profile;
