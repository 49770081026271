import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useTranslation } from "react-i18next";

import { ModalPropsType } from "../../types";

const SessionExpireModal = (props: ModalPropsType) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={props.isOpen}
            closeModal={props.closeModal}
            className="rounded-md"
            hiddeCloseIcon
        >
            <h5 className="mb-2 text-center text-lg font-medium text-gray-700">
                {t("Votre session a expiré")}
            </h5>

            <p className="mb-5 text-center text-sm">
                {t(
                    "Veuillez vous connecter pour profiter de toutes les fonctionnalités"
                )}
            </p>

            <Button
                withAuto
                className="mx-auto px-6"
                onClick={props.closeModal}
            >
                {t("OK")}
            </Button>
        </Modal>
    );
};

export default SessionExpireModal;
