import { useTranslation } from "react-i18next";

import useAuth from "../../../hooks/useAuth.ts";

import OtherAccount from "./OtherAccount.tsx";

const MyCards = () => {
    const { kycActionData } = useAuth();
    const { t } = useTranslation();

    return (
        <>
            {kycActionData.cardValidate ? (
                <OtherAccount accountType="cards" />
            ) : (
                <p className="text-sm">
                    {t(
                        "Votre compte n'est pas validé, vous ne pouvez pas ajouter de carte et vous ne pouvez pas faire de transfert avec votre carte."
                    )}
                </p>
            )}
        </>
    );
};

export default MyCards;
