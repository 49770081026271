import PinInputItem from "@ui/PinInput/PinInputItem.tsx";
import { useMemo, useState } from "react";

import PinInputContext from "../../../context/PinInputContext.ts";
import { cn, focusElementById, generateArray } from "../../../helpers";
import { SizeType } from "../../../types";

interface Props {
    type?: "tel" | "text" | "password" | "number";
    length: number;
    onChange: (value: string) => void;
    size?: SizeType;
    placeholder?: string;
    className?: string;
    pinItemClassName?: string;
    errorMessage?: string[] | string;
}

const PinInput = (props: Props) => {
    const array = generateArray(props.length);
    const [pin, setPin] = useState<string[]>(() => array.map(() => ""));

    const valueContext = useMemo(() => {
        return {
            type: props.type || "number",
            placeholder: props.placeholder || "○",
            length: props.length,
            size: props.size || "xs",
            pin,
            onChangeIndex: (index: number, value: string) => {
                const newPin = [...pin];
                newPin[index] = value;
                props.onChange(newPin.join(""));
                setPin(newPin);
            },
            focusInputByIndex: (index: number) => {
                focusElementById(`pin-item-${index}`);
            }
        };
    }, [pin, props]);

    return (
        <PinInputContext.Provider value={valueContext}>
            <div role="pinbox">
                <div
                    className={cn(
                        "flex items-center space-x-4",
                        props.className
                    )}
                >
                    {array.map(item => (
                        <PinInputItem
                            size={props.size || "sm"}
                            key={item}
                            index={item}
                            className={props.pinItemClassName}
                        />
                    ))}
                </div>

                {props.errorMessage && (
                    <p className="mt-1 text-xs text-red-500">
                        {Array.isArray(props.errorMessage) ? (
                            <>{props.errorMessage[0]}</>
                        ) : (
                            props.errorMessage
                        )}
                    </p>
                )}
            </div>
        </PinInputContext.Provider>
    );
};

export default PinInput;
