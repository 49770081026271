import Switcher from "@ui/Switcher.tsx";
import { useTranslation } from "react-i18next";

interface Props {
    toggle: boolean;
    setToggle: (value: boolean) => void;
}

const NotificationHeader = (props: Props) => {
    const { t } = useTranslation();
    const { toggle, setToggle } = props;
    return (
        <div className="flex items-center justify-between p-5">
            <h3 className="text-lg font-semibold">{t("Notifications")}</h3>
            <div className="flex items-center space-x-4">
                <p className="text-xs">{t("Non lues")}</p>

                <Switcher enabled={toggle} onSwitch={setToggle} />
            </div>
        </div>
    );
};

export default NotificationHeader;
