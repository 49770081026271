import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { ModalPropsType } from "../../types";
import LoCloseIcon from "../icons/LoCloseIcon.tsx";

const LimitTransferModal = (props: ModalPropsType) => {
    const { isOpen, closeModal } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        closeModal();
        navigate(PAGES.REGISTER);
    }, [closeModal, navigate]);

    return (
        <Modal
            isOpen={isOpen}
            closeModal={handleClose}
            hiddeCloseIcon
            className="rounded-2xl"
        >
            <span className="mx-auto mb-5 flex h-20 w-20 items-center justify-center rounded-full bg-red-300">
                <LoCloseIcon className="h-12 w-12 text-white" />
            </span>

            <h3 className="mb-3 text-center text-red-500">
                {t("Limite de transfert atteinte !!")}
            </h3>

            <p className="mb-5 text-center text-sm">
                {t(
                    "Vous devez vous inscrire pour continuer à envoyer de l'argent à vos proches"
                )}
            </p>

            <Button withAuto className="mx-auto px-6" onClick={handleClose}>
                {t("S'inscrire")}
            </Button>
        </Modal>
    );
};

export default LimitTransferModal;
