import Button from "@ui/Button.tsx";
import PinInput from "@ui/PinInput";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { hiddeNumber } from "../../helpers";
import useManageAddNumber from "../../hooks/useManageAddNumber.tsx";
import { setStoreSecondaryNumberData } from "../../store/uiSlice.ts";
import PhoneInput from "../PhoneInput.tsx";

interface Props {
    closeModal: () => void;
    phoneData?: {
        phone: string;
        indicative: string;
    };
}

const AddNumber = (props: Props) => {
    const { closeModal, phoneData = undefined } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        handleClickSubmitButton,
        showAddForm,
        indicative,
        phone,
        errors,
        showOtpForm,
        resendOtp,
        setShowAddForm,
        setShowOtpForm,
        setOtp,
        loading,
        setIndicative,
        otherIndicative,
        setOtherIndicative,
        setPhone
    } = useManageAddNumber(true, phoneData, () => {
        closeModal();
        setShowOtpForm(false);
        setShowAddForm(true);
    });
    const navigate = useNavigate();

    const handleCloseModal = useCallback(() => {
        closeModal();
        dispatch(setStoreSecondaryNumberData(null));
    }, [closeModal, dispatch]);

    return (
        <>
            {showAddForm && (
                <PhoneInput
                    placeholder={t("Numéro")}
                    indicative={indicative}
                    otherCountries={true}
                    otherIndicative={otherIndicative}
                    onOtherIndicativeChange={value => setOtherIndicative(value)}
                    onIndicativeChange={value => setIndicative(value)}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    error={errors?.phone_with_indicative}
                />
            )}

            {showOtpForm && (
                <>
                    <p className="mb-2 text-center text-sm">
                        {t(
                            "Veuillez saisir le code OTP que nous vous avons envoyé au"
                        )}{" "}
                        <span className="text-primary">
                            {indicative} {hiddeNumber(phone)}
                        </span>
                        .
                    </p>

                    <PinInput
                        pinItemClassName="w-1/4"
                        length={4}
                        onChange={value => setOtp(value)}
                        errorMessage={errors?.otp}
                    />

                    <p className="mt-1 mt-3 text-center text-xs">
                        {t("Vous n’avez pas reçu le code ?")}{" "}
                        <button
                            type="button"
                            className="text-primary underline"
                            onClick={resendOtp}
                        >
                            {t("Renvoyer")}
                        </button>
                    </p>
                </>
            )}

            <div className="mt-5 flex items-center justify-center space-x-5">
                <Button
                    type="button"
                    loading={loading}
                    onClick={() => {
                        if (!showAddForm && !showOtpForm) {
                            setShowAddForm(true);
                            handleCloseModal();
                        } else {
                            handleClickSubmitButton();
                        }
                    }}
                >
                    {showAddForm && !showOtpForm && t("Enregistrer")}

                    {!showAddForm && showOtpForm && t("Confirmer")}

                    {!showAddForm && !showOtpForm && t("Continuer")}
                </Button>

                {!showOtpForm && !showAddForm && (
                    <Button
                        type="button"
                        variant="outline"
                        onClick={() => {
                            if (!showAddForm && !showOtpForm) {
                                navigate(PAGES.SETTING_LOCATION);
                            } else {
                                setShowAddForm(true);
                                setShowOtpForm(false);
                                setPhone("");
                            }
                            !loading && handleCloseModal();
                        }}
                    >
                        {t("Gérer mes numéros")}
                    </Button>
                )}
            </div>
        </>
    );
};

export default AddNumber;
