import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import PAGES from "../constants/pages.ts";

const HaveAccount = () => {
    const { t } = useTranslation();

    return (
        <p className="text-center text-sm">
            {t("Vous avez un compte ?")}{" "}
            <NavLink to={PAGES.LOGIN} className="text-primary hover:underline">
                {t("Connectez-vous")}
            </NavLink>
        </p>
    );
};

export default HaveAccount;
