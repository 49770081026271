import { useState } from "react";
import { useTranslation } from "react-i18next";

import ToggleTransition from "../../../components/ToggleTransition.tsx";
import { cn } from "../../../helpers";

import GeneralButton from "./GeneralButton.tsx";
import SettingServiceClient from "./SettingServiceClient.tsx";

const SupportClientButton = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <GeneralButton
                title={t("Contacter le service client")}
                className="mb-0 border-transparent"
                onClick={() => setIsOpen(!isOpen)}
                isActive={isOpen}
            >
                {t("Disponible du Lundi au Vendredi de 8h30 à 17h30")}
            </GeneralButton>

            <ToggleTransition show={isOpen}>
                <SettingServiceClient />
            </ToggleTransition>

            <hr
                className={cn({
                    "mb-4": true,
                    "my-4": isOpen
                })}
            />
        </>
    );
};

export default SupportClientButton;
