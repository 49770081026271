import Toast from "@ui/Toast.tsx";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import AllAlertModals from "./components/AllAlertModals.tsx";
import DetailTransferModal from "./components/modals/DetailTransferModal.tsx";
import InfoModal from "./components/modals/InfoModal.tsx";
import LimitTransferModalWithoutAccount from "./components/modals/LimitTransferModalWithoutAccount.tsx";
import SessionExpireModal from "./components/modals/SessionExpireModal.tsx";
import SuspendModal from "./components/modals/SuspendModal.tsx";
import { RESPONSE_STATUS } from "./constants";
import PAGES from "./constants/pages.ts";
import useAutomaticDisconnect from "./hooks/useAutomaticDisconnect.ts";
import useFetchCountryChannels from "./hooks/useFetchCountryChannels.ts";
import useFetchLocalisation from "./hooks/useFetchLocalisation.ts";
import useFetchNotifications from "./hooks/useFetchNotifications.tsx";
import useGetCameraPermission from "./hooks/useGetCameraPermission.ts";
import useInitPayInNumber from "./hooks/useInitPayInNumber.ts";
import useInitTransferFormData from "./hooks/useInitTransferFormData.ts";
import useInitValidatedBannerMessage from "./hooks/useInitValidatedBannerMessage.ts";
import useRefreshSession from "./hooks/useRefreshSession.ts";
import useRequestNotificationPermission from "./hooks/useRequestNotificationPermission.ts";
import useScrollTop from "./hooks/useScrollTop.ts";
import useSetupAxiosInterceptors from "./hooks/useSetupAxiosInterceptors.ts";
import useSubscribeChannels from "./hooks/useSubscribeChannels.ts";
import useTransferModal from "./hooks/useTransferModal.ts";
import { Auth, ConfirmAccountM, Guest, ToReviewKyc } from "./middlewares";
import Collect from "./pages/Collect.tsx";
import ConfirmAccount from "./pages/ConfirmAccount.tsx";
import CorrectKyc from "./pages/CorrectKyc.tsx";
import ErrorPage from "./pages/Error.tsx";
import ForgotPin from "./pages/ForgotPin.tsx";
import Login from "./pages/Login.tsx";
import Notification from "./pages/Notification.tsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.tsx";
import Register from "./pages/register";
import ResetPin from "./pages/ResetPin.tsx";
import Setting from "./pages/settings";
import Share from "./pages/Share.tsx";
import Transfer from "./pages/Transfer.tsx";
import TransferDetailPage from "./pages/TransferDetailPage.tsx";
import { RootState } from "./store";
import { closeToast } from "./store/uiSlice.ts";

const App = () => {
    // Custom Hooks
    useScrollTop();
    const { showError, closeError, errorTitle, errorMessage } =
        useSetupAxiosInterceptors();
    const { toast } = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();
    useFetchCountryChannels();
    useFetchLocalisation();
    const { isOpen, closeModal } = useAutomaticDisconnect();
    const {
        isOpen: transIsOpen,
        closeTransferModal,
        transfer
    } = useTransferModal();
    useInitTransferFormData();
    useFetchNotifications();
    useSubscribeChannels();
    useGetCameraPermission();
    useRequestNotificationPermission();
    useInitPayInNumber();
    useRefreshSession();
    useInitValidatedBannerMessage();

    return (
        <>
            <Routes>
                {Object.values(RESPONSE_STATUS).map(item => (
                    <Route
                        key={item}
                        path={`/${item}`}
                        element={<ErrorPage code={item} />}
                    />
                ))}

                <Route
                    path={PAGES.HOME}
                    element={
                        <Auth>
                            <ToReviewKyc>
                                <Transfer />
                            </ToReviewKyc>
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.LOGIN}
                    element={
                        <Guest>
                            <Login />
                        </Guest>
                    }
                />

                <Route
                    path={PAGES.REGISTER}
                    element={
                        <Guest>
                            <Register />
                        </Guest>
                    }
                />

                <Route
                    path={PAGES.FORGOT_PIN}
                    element={
                        <Guest>
                            <ForgotPin />
                        </Guest>
                    }
                />

                <Route
                    path={PAGES.CONFIRM_ACCOUNT}
                    element={
                        <ConfirmAccountM>
                            <ConfirmAccount />
                        </ConfirmAccountM>
                    }
                />

                <Route
                    path={PAGES.RESET_PIN}
                    element={
                        <Guest>
                            <ResetPin />
                        </Guest>
                    }
                />

                <Route
                    path={PAGES.SETTING}
                    element={
                        <Auth>
                            <ToReviewKyc>
                                <Setting />
                            </ToReviewKyc>
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.TRANSFER}
                    element={
                        <ToReviewKyc>
                            <Transfer />
                        </ToReviewKyc>
                    }
                />

                <Route path={PAGES.SHARE} element={<Share />} />

                <Route
                    path={PAGES.TRANSFER_DETAIL}
                    element={<TransferDetailPage />}
                />

                <Route
                    path={PAGES.COLLECT}
                    element={
                        <Auth>
                            <ToReviewKyc>
                                <Collect />
                            </ToReviewKyc>
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.NOTIFICATION}
                    element={
                        <Auth>
                            <ToReviewKyc>
                                <Notification />
                            </ToReviewKyc>
                        </Auth>
                    }
                />

                <Route
                    path={PAGES.PRIVACY_POLICY}
                    element={<PrivacyPolicy />}
                />

                <Route
                    path={PAGES.CORRECT_KYC}
                    element={
                        <Auth>
                            <CorrectKyc />
                        </Auth>
                    }
                />

                <Route path="/*" element={<ErrorPage />} />

                <Route path="/*" element={<ErrorPage />} />
            </Routes>

            <DetailTransferModal
                transfer={transfer}
                isOpen={transIsOpen}
                closeModal={closeTransferModal}
            />

            <Toast
                title={toast.title}
                description={toast.description}
                show={toast.show}
                closeToast={() => {
                    dispatch(closeToast());
                }}
                type={toast.type}
                duration={toast.duration}
                position={toast.position}
            />

            <SessionExpireModal isOpen={isOpen} closeModal={closeModal} />

            <InfoModal
                isOpen={showError}
                closeModal={closeError}
                title={errorTitle}
                message={errorMessage}
            />

            <SuspendModal />

            <AllAlertModals />

            <LimitTransferModalWithoutAccount />
        </>
    );
};

export default App;
