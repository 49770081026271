import { useMemo } from "react";

import useManageWalletChannels from "./useManageWalletChannels.ts";

const useFetchCard = () => {
    const { payinChannels } = useManageWalletChannels();

    return useMemo(() => {
        const firstCountries = payinChannels[0];

        if (!firstCountries) return undefined;

        return firstCountries.options.find(
            item => item.type === "card" && !item.country_id
        );
    }, [payinChannels]);
};

export default useFetchCard;
