import { useSelector } from "react-redux";

import { RootState } from "../../store";
import GrRefreshIcon from "../icons/GrRefreshIcon.tsx";
import SettingLink from "../links/SettingLink.tsx";
import NotificationDropdown from "../notifications/NotificationDropdown.tsx";

const Nav = () => {
    const { isLogin } = useSelector((state: RootState) => state.auth);

    return (
        <nav className="relative z-[999px] flex items-center justify-between rounded-b-[14px] bg-primary text-white md:py-2 lg:shadow-sm">
            <img
                src="/images/logo-mydunya.png"
                className="ml-4 w-[100px] object-cover object-center"
                alt=""
            />

            <div className="flex items-center space-x-2">
                <div
                    onClick={() => window.location.reload()}
                    className="px-4 py-4 inline items-center justify-center lg:flex lg:flex-col lg:px-12 lg:py-3"
                >
                    <GrRefreshIcon size="lg" />
                </div>

                {isLogin && <NotificationDropdown />}

                <SettingLink />
            </div>
        </nav>
    );
};

export default Nav;
