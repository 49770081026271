import React from "react";

import { cn } from "../helpers";

interface Props {
    children: React.ReactNode;
    className?: string;
}

const Container = ({ children, className = "" }: Props) => {
    return (
        <div className={cn("mx-6 lg:mx-auto lg:w-4/6", className)}>
            {children}
        </div>
    );
};

export default Container;
