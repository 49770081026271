import Button from "@ui/Button.tsx";
import PinInput from "@ui/PinInput";
import { FormEvent, useCallback, useMemo, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import HaveAccount from "../components/HaveAccount.tsx";
import AuthLayout from "../components/layouts/AuthLayout.tsx";
import { RESPONSE_STATUS, STORAGE } from "../constants";
import ENDPOINTS from "../constants/endpoint.ts";
import PAGES from "../constants/pages.ts";
import { loadAuthServiceHost, storage } from "../helpers";
import usePageTitle from "../hooks/usePageTitle.ts";
import useRedirectConfirmAccount from "../hooks/useRedirectConfirmAccount.ts";
import useToast from "../hooks/useToast.ts";
import rules from "../schemas";

const CodeInputSchema = yup.object().shape({
    code: rules.pin
});

const ConfirmAccount = () => {
    const { t } = useTranslation();
    usePageTitle(t("confirmation de compte"));
    const [loading, setLoading] = useState(false);
    const confirmAccountTel = useMemo(() => {
        return storage().getItem(STORAGE.CONFIRM_ACCOUNT_TEL);
    }, []);

    const { successToast } = useToast();
    const { removePhoneNumber } = useRedirectConfirmAccount();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [code, setCode] = useState("");

    const resendCode = useCallback(
        (e: MouseEvent<HTMLAnchorElement>) => {
            e.stopPropagation();
            loadAuthServiceHost();
            window.axios
                .post(ENDPOINTS.RESEND_CODE, {
                    phone_with_indicative: confirmAccountTel
                })
                .then(() => {
                    successToast(
                        t("Code envoyer avec succès"),
                        t("Le code de confirmartion vous à été envoyer par sms")
                    );
                });
        },
        [confirmAccountTel, successToast, t]
    );

    const onSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            CodeInputSchema.validate({ code }, { abortEarly: false })
                .then(() => {
                    setLoading(true);
                    loadAuthServiceHost();
                    window.axios
                        .post(ENDPOINTS.VALIDATE_ACCOUNT, {
                            phone_with_indicative: confirmAccountTel,
                            code: code
                        })
                        .then(() => {
                            successToast(
                                t("Succès de la confirmation de compte"),
                                t("Vous pouvez desormain vous conncter")
                            );

                            navigate(PAGES.LOGIN);
                            removePhoneNumber();
                        })
                        .catch(errors => {
                            if (
                                errors.response.status ===
                                RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                            ) {
                                setErrors(errors.response.data);
                            }
                        })
                        .finally(() => setLoading(false));
                })
                .catch((validationErrors: yup.ValidationError) => {
                    const validationErrorsMap: { [key: string]: string } = {};
                    validationErrors.inner.forEach(error => {
                        if (error.path) {
                            validationErrorsMap[error.path] = error.message;
                        }
                    });
                    setErrors(validationErrorsMap);
                });
        },
        [code, confirmAccountTel, navigate, removePhoneNumber, successToast, t]
    );

    return (
        <AuthLayout title={t("Confirmation de compte")}>
            <form className="mt-16 w-full space-y-5" onSubmit={onSubmit}>
                <div className="space-y-5 text-right">
                    <div className="space-y-2 text-left">
                        <label className="text-left">
                            {t("Code reçu par sms")}
                        </label>

                        <PinInput
                            pinItemClassName="w-1/4"
                            type="number"
                            length={4}
                            onChange={value => setCode(value)}
                            errorMessage={errors.code}
                        />
                    </div>

                    <a
                        href="#resend"
                        className="pt-2 text-right text-sm text-primary hover:underline"
                        onClick={resendCode}
                    >
                        {t("Renvoyer")}
                    </a>
                </div>

                <Button type="submit" loading={loading}>
                    {t("Confirmer")}
                </Button>

                <HaveAccount />
            </form>
        </AuthLayout>
    );
};

export default ConfirmAccount;
