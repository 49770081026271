import { Transition } from "@headlessui/react";
import {
    ButtonHTMLAttributes,
    Fragment,
    MouseEvent,
    useCallback,
    useState
} from "react";
import { useTranslation } from "react-i18next";

import HeClipboardIcon from "./icons/HeClipboardIcon.tsx";

const ALERT_TIMEOUT = 3000;

type Props = ButtonHTMLAttributes<HTMLButtonElement>;

const CopyClipboardButton = (props: Props) => {
    const { t } = useTranslation();
    const [copied, setCopied] = useState(false);

    const handleClick = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            props.onClick && props.onClick(e);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, ALERT_TIMEOUT);
        },
        [props]
    );

    return (
        <button
            type="button"
            onClick={handleClick}
            className="relative inline-block focus:outline-none"
        >
            <HeClipboardIcon className="text-gray-500" />

            <Transition
                as={Fragment}
                show={copied}
                enter="transform transition"
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100"
                leave="transform duration-200 transition ease-in-out"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-50"
            >
                <div className="absolute -top-1 right-6 z-10 rounded-md border bg-black p-2 text-xs text-white shadow-sm">
                    {t("Copié")}
                </div>
            </Transition>
        </button>
    );
};

export default CopyClipboardButton;
