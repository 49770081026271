import { useTranslation } from "react-i18next";

import ShareWithSocials from "../../../components/modals/ShareWithSocials.tsx";
import config from "../../../config";
import useModal from "../../../hooks/useModal.ts";

import GeneralButton from "./GeneralButton.tsx";

const InviteFriendButton = () => {
    const { t } = useTranslation();
    const { openModal, isOpen, closeModal } = useModal();

    return (
        <>
            <GeneralButton
                title={t("Inviter un ami")}
                onClick={() => openModal()}
            >
                {t("Partager le lien de l’application avec vos proches")}
            </GeneralButton>

            <ShareWithSocials
                title={t("Envoyer par")}
                description={t(
                    "Sélectionner une application puis partager le lien avec vos amis."
                )}
                share={{
                    link: `${config.url}/share`,
                    message: encodeURI(
                        t(
                            "Je t'invite à t'inscrire sur MyDunya pour envoyer de l'argent partout dans l'UEMOA!"
                        )
                    )
                }}
                isOpen={isOpen}
                closeModal={closeModal}
            />
        </>
    );
};

export default InviteFriendButton;
