import Button from "@ui/Button.tsx";
import { FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import InputField from "../../../components/InputField.tsx";
import { RESPONSE_STATUS } from "../../../constants";
import ENDPOINTS from "../../../constants/endpoint.ts";
import { loadAuthServiceHost } from "../../../helpers";
import useManageSchemaValidationError from "../../../hooks/useManageSchemaValidationError.ts";
import useToast from "../../../hooks/useToast.ts";
import rules from "../../../schemas";

const UpdatePasswordSchema = yup.object().shape({
    pin: rules.pin,
    new_pin: rules.pin,
    confirm_pin: rules.confirmPin("new_pin")
});

const UpdatePinForm = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [pin, setPin] = useState("");
    const [newPin, setNewPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");

    const toast = useToast();
    const { errors, setErrors, resetErrors, showErrors } =
        useManageSchemaValidationError();

    const onSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            const sendData = {
                pin: pin,
                new_pin: newPin,
                confirm_pin: confirmPin
            };

            UpdatePasswordSchema.validate(sendData, { abortEarly: false })
                .then(() => {
                    setLoading(true);
                    loadAuthServiceHost();
                    window.axios
                        .put(ENDPOINTS.UPDATE_PIN, sendData)
                        .then(() => {
                            resetErrors();
                            setPin("");
                            setNewPin("");
                            setConfirmPin("");
                            toast.successToast(
                                t("Succès de la modification du code secret")
                            );
                        })
                        .catch(error => {
                            resetErrors();
                            if (
                                error.response.status ===
                                RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                            ) {
                                typeof error.response.data.errors ===
                                    "object" &&
                                    setErrors(error.response.data.errors);
                            }
                            toast.errorToast(
                                t("Échec de la modification du code secret")
                            );
                        })
                        .finally(() => setLoading(false));
                })
                .catch(showErrors());
        },
        [confirmPin, newPin, pin, resetErrors, setErrors, showErrors, t, toast]
    );

    return (
        <form className="space-y-6" onSubmit={onSubmit}>
            <InputField
                isPin
                type="password"
                inputMode="numeric"
                id="pin"
                name="pin"
                placeholder={t("Ancien code secret")}
                value={pin}
                onChange={e => setPin(e.target.value)}
                error={errors.pin}
            />

            <InputField
                isPin
                type="password"
                inputMode="numeric"
                id="new_pin"
                name="new_pin"
                placeholder={t("Nouveau code secret")}
                value={newPin}
                onChange={e => setNewPin(e.target.value)}
                error={errors.new_pin}
            />

            <InputField
                isPin
                type="password"
                inputMode="numeric"
                id="confirm_pin"
                name="confirm_pin"
                placeholder={t("Confirmer code secret")}
                value={confirmPin}
                onChange={e => setConfirmPin(e.target.value)}
                error={errors.confirm_pin}
            />

            <Button type="submit" loading={loading}>
                {t("Changer le code")}
            </Button>
        </form>
    );
};

export default UpdatePinForm;
