import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ModalPropsType } from "../../types";

interface Props extends ModalPropsType {
    message: string;
    title?: string;
    onOk?: () => void;
}

const SessionExpireModal = (props: Props) => {
    const { message, title, closeModal, onOk } = props;
    const { t } = useTranslation();

    const handleCloseModal = useCallback(() => {
        closeModal();
        onOk && onOk();
    }, [closeModal, onOk]);

    return (
        <Modal
            isOpen={props.isOpen}
            closeModal={handleCloseModal}
            className="rounded-md"
            hiddeCloseIcon
        >
            {title && (
                <h5 className="mb-2 text-center text-lg font-medium text-gray-700">
                    {title}
                </h5>
            )}

            <p className="mb-5 text-center text-sm">{message}</p>

            <Button
                withAuto
                className="mx-auto px-6"
                onClick={handleCloseModal}
            >
                {t("OK")}
            </Button>
        </Modal>
    );
};

export default SessionExpireModal;
